
import axiosServices from '@/services/axiosServices.js'

export default {
  data() {
    return {
      tituloGrids: '',
      oPermisos: {
        priv_access: "1",
        priv_insert: "0",
        priv_delete: "0",
        priv_update: "0"
      }
    }
  },

  methods: {
    onValidarTokenSinLogin () {
      if(this.$route.query.t != undefined && this.$route.query.ip != undefined){
        //console.log("query","ingreso validacion exitosa grid")
        let sToken = this.$route.query.t
        let sIP = this.$route.query.ip
        this.$store.dispatch('setLogin', true);
        this.$store.dispatch('saveToken', sToken);
        sessionStorage.ip = sIP
        //console.log("entro")
      }
    },
    async getTitulo (sTableName, bSubdominioTemporal = false) {
      let sParams = `sTable=${sTableName}&nPerfil=${this.$store.state.idPerfil}`
      if(bSubdominioTemporal){
        sParams = sParams + `&subdominiot=${this.$store.state.sSubdominioTemporal}`
      }
      const aTitulo = await axiosServices.getConfiguration('pGrids_titulo', false, sParams )
      if(typeof aTitulo.aData[0].priv_access !== 'undefined') this.oPermisos.priv_access = aTitulo.aData[0].priv_access
      if(typeof aTitulo.aData[0].priv_insert !== 'undefined') this.oPermisos.priv_insert = aTitulo.aData[0].priv_insert
      if(typeof aTitulo.aData[0].priv_delete !== 'undefined') this.oPermisos.priv_delete = aTitulo.aData[0].priv_delete
      if(typeof aTitulo.aData[0].priv_update !== 'undefined') this.oPermisos.priv_update = aTitulo.aData[0].priv_update
      return await aTitulo.aData[0].tituloGrid
    },
    onExtraerNombreCampoGridAnidada(oItem){
      return Object.keys(oItem)[0];
    },
    onExtraerIdSelectGridAnidada(oItem){
      if (oItem.hasOwnProperty(`id${this.tabla}`)){
        return isNaN(parseInt(oItem[`id${this.tabla}`]))? 0 : parseInt(oItem[`id${this.tabla}`])
      }else{
        return isNaN(parseInt(oItem[Object.keys(oItem)[0]]))? 0 : parseInt(oItem[Object.keys(oItem)[0]])
      }
    },
    async onSubirAnexos(aArchivos){
      aArchivos.forEach(Anexos => {
        // valido que no sea un file list con mas de un elemento 
        if(Anexos.length > 1){
          // Lo recorro de nuevo
          Anexos.forEach( Anexo => {
            //console.log(Anexo)
            const nTipoAnexo = (Anexo.type.includes('image')) ? 1 : 2
            const formData = new FormData()
            formData.append('file', Anexo)
            axiosServices.postFile('/subirarchivo', formData, true, nTipoAnexo)
          })
        }  
        if(Anexos.length == 1){
          //console.log(Anexos[0])
          const nTipoAnexo = (Anexos[0].type.includes('image')) ? 1 : 2
          const formData = new FormData()
          formData.append('file', Anexos[0])
          axiosServices.postFile('/subirarchivo', formData, true,nTipoAnexo)
        }
      })
    }
  },
  computed: {
    onValidarBotonDeshabilitado(){
      return this.$bCargando
    }
  }
}