import { render, staticRenderFns } from "./CompanySVG.vue?vue&type=template&id=64bb0c62&scoped=true&"
import script from "./CompanySVG.vue?vue&type=script&lang=js&"
export * from "./CompanySVG.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64bb0c62",
  null
  
)

export default component.exports