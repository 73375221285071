/* eslint-disable no-console */

// En este archivo gurado toda la configuracion del firebase para luego importalo en el main.js.

import Vue from "vue"
// import store from "@/store/index.js"

// manejo de firebase 
import firebase from "firebase/app"
import "firebase/analytics"
import "firebase/performance"
import "firebase/messaging"

// firebase metricas -- la configuracion debe ser cambiada segun el proyecto
var firebaseConfig = {
  apiKey: "AIzaSyAgjjDM-JdunHHv5CClcGhMkSAnAF35Q8U",
  authDomain: "safetytrack-crm-recover.firebaseapp.com",
  databaseURL: "https://safetytrack-crm-recover.firebaseio.com",
  projectId: "safetytrack-crm-recover",
  storageBucket: "safetytrack-crm-recover.appspot.com",
  messagingSenderId: "29782282155",
  appId: "1:29782282155:web:a476d23793c2f137320cee",
  measurementId: "G-658NVXTVM1"
}

// Initialize Firebase
const defaultProject = firebase.initializeApp(firebaseConfig)
console.log(defaultProject.name);  // "[DEFAULT]"

// // configuro FCM
// const messaging = firebase.messaging()
// messaging.usePublicVapidKey("BCt5fGbrq02lkCjaT9vdAP7DfF7af3oJHymvUFajTOHhVO38AeVAWq6m7u1T0EixYhwZv0_dAYF3nFXHdFSpEw8")

// messaging.requestPermission().then(() => {
//   // console.log('Notification permission granted.')
//   messaging.getToken().then((token) => {
//     // guardo en storage el token.
//     console.log(token)
//     store.dispatch('settokenFirebase', token)
//   })
// }).catch((err) => {
//   console.log('Unable to get permission to notify.', err)
// })

// messaging.onMessage( payload => {
 
//   /*  Estructura mensaje FCM
//     {data: { code: "9"
//                 message: "Delogueo"
//                 remitente: "ALB"
//                 title: "Deslogueo"
//               }, 
//     from: "315828580733", 
//     priority: "normal", 
//     notification: {title: "Title notification"}, 
//     collapse_key: "type_a"} */

//   // visualizacion mensaje
//   store.dispatch('firebasenotificaciones/SetMensaje',{ title : payload.data.title, message : payload.data.message  } );
//   store.dispatch('firebasenotificaciones/ShowNotificacion',true); 
  
// })

// Herramientas de monitoreo
const analytics =firebase.analytics()
firebase.performance()


Vue.use(firebase)
Vue.prototype.$firebase = firebase
Vue.prototype.$analytics = analytics