<template>
	<div>
		<v-sheet v-for="(oInfo, i) in aCardData" 
		:key="i" 
		class="mx-auto py-3 px-3  under-sec-flat" 
		:hidden="hiddenElement"
		:dark="oInfo.value == 'Compromisos'"
		:class="{'back-dark' : (oInfo.value == 'Compromisos') } ">

			<h4 class="text-medium ">{{ oInfo.value }}</h4>
			<v-slide-group class="pa-4" show-arrows>
			<v-slide-item v-for="(oCardDataItem, j) in oInfo.aData" :key="j">
				<v-card  class="ma-2 pa-3 border-card" width="320" light>
					<v-card-text v-for="(oItem, k) in oCardDataItem" :key="k" class="ma-0 pa-0">
						<div v-if="oItem.Tipo=='DataCard_Titulo'" class="text-center">
							<span class="text-medium">{{oItem.Campo}} {{oItem.Valor}}</span>
						</div>
						<div v-else-if="oItem.Tipo=='DataCard_CampoTexto'">
							<span class="text-medium">{{oItem.Campo}}:</span>
							<span>{{oItem.Valor}}</span>
						</div>
						<div v-else-if="oItem.Tipo=='DataCard_Texto'">
							<span>{{oItem.Valor}}</span>
						</div>
						<div v-else-if="oItem.Tipo=='DataCard_Imagen'" class="text-center">
							<v-card  class="ma-1 card-img text-center mx-auto" height="170" width="170" light
								@click="cardAction(oItem.Valor)">
								<v-img class="align-end" height="170" :src="oItem.Valor" contain>
									<template v-slot:placeholder>
										<v-row
											class="fill-height ma-0"
											align="center"
											justify="center">
											<v-progress-circular
												indeterminate
												color="blue lighten-3">
											</v-progress-circular>
										</v-row>
									</template>
								</v-img>
							</v-card>
						</div>
					</v-card-text>
				</v-card>
			</v-slide-item>
			<v-slide-item v-if="oInfo.aData.length==0" >
				<v-card  class="ma-2 pa-3" light>
					<v-card-text class="ma-0 pa-0">
						Sin información encontrada
					</v-card-text>
				</v-card>
			</v-slide-item>
			</v-slide-group>
		</v-sheet>
		<div class="imprimir under-sec-flat">
			<div v-for="(oInfo, i) in aCardData" :key="`expor`+i"  :hidden="!hiddenElement" >
				<div width="100%" class="px-4 my-3">
					<h4 class="text-medium">{{oInfo.value}}</h4>
				</div>
				<div width="50%">
					<div style="display: flex;">
						<v-container>
							<v-row class="border-card px-4">
								<v-col v-for="(oCardDataItem, j) in oInfo.aData" :key="j" cols="12" sm="6">
									<v-card  class="ma-2 pa-3 border-card" width="320" light>
										<v-card-text  v-for="(oItem, k) in oCardDataItem" :key="k" class="ma-0 pa-0">
											<div v-if="oItem.Tipo=='DataCard_Titulo'" class="text-center">
												<span class="text-medium">{{oItem.Campo}} {{oItem.Valor}}</span>
											</div>
											<div v-else-if="oItem.Tipo=='DataCard_CampoTexto'">
												<span class="text-medium">{{oItem.Campo}}:</span>
												<span>  {{oItem.Valor}}</span>
											</div>
											<div v-else-if="oItem.Tipo=='DataCard_Texto'">
												<span>  {{oItem.Valor}}</span>
											</div>
											<div id="sImgReference" v-else-if="oItem.Tipo=='DataCard_Imagen'" >
												<img class="aFotos" height="170" :src="oItem.Valor" contain />
											</div>
										</v-card-text>
									</v-card>
								</v-col>
								<v-col v-if="oInfo.aData.length==0" cols="12" sm="6">
									<v-card-text class="ma-0 pa-0">
										Sin información encontrada
									</v-card-text>
								</v-col>
							</v-row>
						</v-container>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import axiosServices from '@/services/axiosServices.js'
export default {
	name:"DataCard",
	components:{},
	props:{
		aInfo: {},
		nIdParent: {
			type: String,
			default: ''
		},
		sNameDetails: {
			type: String,
			default: ''
		},
		hiddenElement:{
			type: Boolean,
			default: false
		},
		sParametros: {
			type: String,
			default: ''// Listado de string que continene parametros adicionales que se usan para realizar filtrado, este listado son campos existentes.
		},
		bSubdominioTemporal:{ //Indica que se activa puente con subdominio temporal
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			ampliar: false,
			ftampliada: null,
			aCardData: [],
			fotos: 1,
		}
	},
	mounted() {
		this.getData()
	},
	methods: {
		async getData() {
			let sParams = ''
			this.aCardData = []
			//console.log(this.aCardData);
			Object.values(this.aInfo).forEach(async oInfo => {
				oInfo.aData = []
				sParams = `sTablePrin=${oInfo.index}&nIdParent=${this.nIdParent}&sDetails=${this.sNameDetails}` + this.sParametros
				if(this.bSubdominioTemporal){
					sParams = sParams + `&subdominiot=${this.$store.state.sSubdominioTemporal}`
				}
				const aResponse = await axiosServices.getConfiguration('/drawerDetails_getDataCard', true, sParams)
				oInfo.aData = aResponse.aData
				this.aCardData.push(oInfo)
			})
			
		},
		cardAction(val){
			this.ampliar = true
			this.ftampliada = val
		}
	}
}
</script>
<style lang="scss" scoped>
.card-img{
	border: solid gray 1px!important;
	border-radius: 10px;
	box-shadow: none!important;
}
</style>