<template>
	<div>
		<div v-for="(oInfo, i) in aInfo" :key="i" >
			<div class="imprimir pt-4 pb-1 px-4">
				<h4 class="text-medium">{{oInfo.value}}</h4>
			</div>
			<div class="px-4 py-2 imprimir" v-for="(oData, j) in aData" :key="j">
				<v-card class="border-card" light >
					<v-card-text>
						<p class="descrip-container">{{oData[`${oInfo.value}`]}}</p>
					</v-card-text>
				</v-card>
			</div>
		</div>
	</div>
</template>
<script>
import axiosServices from '@/services/axiosServices.js'
export default {
	name:"NotasVista",
	props:{
		aInfo: {},
		nIdParent: {
			type: String,
			default: ''
		},
		sNameDetails: {
			type: String,
			default: ''
		},
		bSubdominioTemporal:{ //Indica que se activa puente con subdominio temporal
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			aData: []
		}
	},
	mounted() {
		this.getInfo()
	},
	methods: {
		getInfo() {
			let sParams = ''
			Object.values(this.aInfo).forEach(oInfo => {
				sParams = `sTablePrin=${oInfo.index}&nIdParent=${this.nIdParent}&sDetails=${this.sNameDetails}`
				if(this.bSubdominioTemporal){
					sParams = sParams + `&subdominiot=${this.$store.state.sSubdominioTemporal}`
				}
				axiosServices.getConfiguration('/drawerDetails_getDataAdicional', true, sParams).then(aInfo => {
					this.aData = aInfo.aData
				})
			})
			
		}
	}
}
</script>

<style lang="scss" >
.descrip-container{
	margin-bottom: 0px!important;
}
</style>
