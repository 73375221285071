const state = () => ({
  ItemSeleccionado : {},
})

const getters = {
  gettItemSeleccionado: ( state ) => {
    return state.ItemSeleccionado;
  },
} 

const mutations = {
  SALVARITEMSELECCIONADO : ( state, Item ) => {
    state.ItemSeleccionado = Item;

  },
}

const actions = {
  SetItemSeleccionado( {commit}, Item ) {
    commit( "SALVARITEMSELECCIONADO", Item);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}