// import { uuid } from './utils.js'

export default {
  name: 'builDinamic',
  columns: [
    {
      name: 'Tipos de Campos',
      ancho: 300,
      campos: [
        // {
        //   description: '',
        //   name: 'TOGGLE',
        //   id: uuid(),
        //   userAssigned: null
        // },
        // {
        //   id: uuid(),
        //   userAssigned: null,
        //   idTipoCampo: '1',
        //   TipoCampo: 'TEXTO',
        //   name: 'TEXTO',
        //   idPregunta: 1,
        //   pregunta:"",
        //   valor: {} ,
        // },
      ]
    },
    {
      name: 'Formulario',
      ancho: 750,
      campos: [
       // {
        //   id: uuid(),
        //   userAssigned: null,
        //   idTipoCampo: '1',
        //   TipoCampo: 'TEXTO',
        //   name: 'TEXTO',
        //   idPregunta: 1,
        //   pregunta:"",
        //   valor: {} ,
        // },
      ]
    },
  ]
}
