<template>
<div >
  <span class="titleslight--text">{{titulo}}</span>
  <span v-if="obligatorio" class="titleslight--text font-weight-bold ml-1">*</span>
  <v-dialog
    ref="dialog"
    v-model="modalCalendar"
    :return-value.sync="valor"
    persistent
    width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="valor"
        prepend-icon="fa-calendar-alt"
        outlined
        v-on="on"
        dense
        :placeholder="sPlaceHolder"
        :disabled="bEditable"
        :error="esError"
        :rules="noVacio">
      </v-text-field>
    </template>
    <v-date-picker v-model="FechaSelect" :min="sFechaMinima" scrollable locale="es-419" color="primarydark">
    </v-date-picker>
    <v-row class="white">
      <v-col cols="12" class="text-center">
        <v-btn class="btn-rounded mx-2" depressed light outlined color="buttondark" @click="modalCalendar = false">Cancelar</v-btn>
        <v-btn class="btn-rounded mx-2" depressed light outlined color="buttondark" @click="$refs.dialog.save(valor)">OK</v-btn>
      </v-col>
    </v-row>
  </v-dialog>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

  export default {
    name: 'FormDate',
    props: {
      value: {
      },
      titulo:{
        type:String,
        default:''
      },
      obligatorio:{
        type:Boolean,
        default: false
      },
      campo:{
        type:String,
        default : ''
      },
      editable: {
        type: String,
        default: ''
      },
      sPlaceHolder: {
        type: String,
        default: ''
      },
      bDesdeHoy:{
        type:Boolean,
        default: false
      }
    },
    data() {
      return {
        valor   : '',  //this.value == '' || this.value == null ? new Date().toISOString().substr(0, 10): this.value,
        modalCalendar : false,
        esError : false,
        noVacio : [v => !!v],
        bEditable : (this.editable == "0") ? true : false,
        sFechaMinima: ''
      }
    },
    mounted(){
      this.valor  = this.value == '' || this.value == null ? new Date().toISOString().substr(0, 10): this.value
      if(this.bDesdeHoy) this.onCargarFechaActual()
    },
    methods: {
      onCargarFechaActual(){
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        this.sFechaMinima = yyyy + '-' + mm + '-' + dd;
      }
    },
    computed: {
      ...mapGetters(['getoFormError'])
    },
    watch: {
      valor(NewValor){
        this.$emit('input',NewValor)
      },
      value(NewValue){
        this.valor = NewValue
        if(this.esError && this.valor != ''){
          this.esError = false
        }
      },
      getoFormError(){
       this.esError = this.getoFormError[this.campo]
      }
    },
    
  }
</script>

<style scoped>
