<template>
  <v-dialog v-model="bShowModal" persistent max-width="960px">
    <v-card>
      <v-toolbar
        color="headformsdark"
        height="45"
        elevation="0"
        dense dark>
        <v-spacer></v-spacer>
        <span class="mdlHeadForms font-weight-medium">{{ sTitle }}</span>
        <v-spacer></v-spacer>
        <v-btn 
          color="btncolor-dark"
          dark
          @click="CloseDialog()"
          icon
          small
          outlined>
          <v-icon size="14px">fa fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container>
        <grid
          :tabla="sTable"
          :bActiveFilter="false"
          :bActiveExport="false"
          :bSingleSelect="false"
          :bShowSelect="false">
        </grid>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import grid from '@/components/Grid/Grid.vue'
export default {
  name:'Form',
  components: {
    grid,
  },
  props: {
    bShowModal: {
      type: Boolean,
      default: false
    },
    sTable: {
      type: String,
    },
    sTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    }
  },
  methods: {
    CloseDialog() {
      this.$emit('input', false)
    },
  }
}
</script>