<!-- URL https://github.com/ueberdosis/tiptap#readme-->
<template>
  <div>
    <v-icon color="primary" @click="modal = true">fas fa-code</v-icon>
    <v-dialog
      v-model="modal"
      width="600px"
    >
      <v-card>
        <v-toolbar
            flat
            dark
            color="primary"
          >
            <v-btn
              icon
              dark
              @click="modal = false"
            >
              <v-icon>fas fa-times</v-icon>
            </v-btn>
            <v-toolbar-title>{{titulo}}</v-toolbar-title>
            <v-spacer></v-spacer>

          </v-toolbar>

        <v-card-text>
          <div v-html="texto"></div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import mixinGridComponenteCelda from "@/mixins/GridComponenteCelda.js"

export default {
  name: "CeldaHTMLEditor",
  mixins: [mixinGridComponenteCelda],

  methods: {
    onClickModal() {
      this.modal = true;
    },
  },
};
</script>

<style scoped></style>
