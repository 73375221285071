<template>
  <div>
    <span class="titleslight--text font-weight-medium">{{titulo}}</span>
    <span v-if="obligatorio" class="titleslight--text font-weight-bold ml-1">*</span>
    <v-radio-group  class="mt-n4 pl-1"
      v-model="valor" 
      row 
      dense
      :disabled="bEditable"
      :rules="noVacio"
      :error="esError" >
      <v-switch  :label="valor ? 'SI' : 'NO'" v-model="valor"></v-switch>
    </v-radio-group>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
  export default {
    name: 'FormSwitch',
    props: {
      value: {
      },
      titulo:{
        type:String,
        default:''
      },
      obligatorio:{
        type:Boolean,
        default: false
      },
     campo:{
        type:String,
        default : ''
      },
      editable: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        valor   : this.value,
        esError : false,
        noVacio : [v => !!v],
        bEditable       : (this.editable == "0") ? true : false,
      }
    },
    mounted(){
    },
    methods: {

    },
    computed: {
      ...mapGetters(['getoFormError'])
    },
    watch: {
      valor(NewValor){
        this.$emit('input',NewValor)
        if(this.esError && this.valor != ''){
          this.esError = false
        }      
      },
      value(NewValue){
        this.valor = NewValue
      },
      getoFormError(){
        this.esError = this.getoFormError[this.campo]
      }
    },
    
  }
</script>

<style scoped>


</style>