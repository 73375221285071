<template>
  <v-tooltip v-if="oPermisos.priv_update=='1'" bottom>
    <template v-slot:activator="{ on }" >
      <v-btn color="primary" class="mx-1" outlined small icon v-on="on" @click="emitEvento()">
        <v-icon size="13px" class="btncolor--text mx-1">
          fa-pencil-alt
        </v-icon>
      </v-btn>
      <h3></h3>
    </template>
    <span>{{HoverMensaje}}</span>
  </v-tooltip>

</template>

<script>

export default {
  name:"BtnBuildCorreos",
  props: {
    item: {
      type:Object,
    },
    HoverMensaje:{
      type: String,
      default: 'Sin Mensaje'
    },
    oPermisos: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {

    }//fin return
  }, // Fin data
  methods: {
    emitEvento(){
      this.$emit('btnActionEvento',{btnName: 'btnBuildCorreos', item: this.item })
    },  
  },

}

</script>

<style scoped>

</style>