<template>
  <div>
    <span class="titleslight--text font-weight-medium">{{ titulo }}</span>
    <span v-if="obligatorio" class="titleslight--text font-weight-bold ml-1">*</span>
    <v-text-field
      v-model="txtColor"
      :error="esError"
      :rules="noVacio"
      :disabled="bEditable"
      outlined
      dense
      required>
      <template v-slot:append-outer>
        <v-btn text icon color="btncolor" class="mt-n2 pa-0" @click="bMdlColor=true">
          <v-icon>fa-palette</v-icon>
        </v-btn>
      </template>
    </v-text-field>
    <v-dialog v-model="bMdlColor" persistent max-width="300px">
      <v-card>
        <v-container>
          <v-row align="center" justify="center">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
              <span class="titleslight--text font-weight-medium">Seleccionar color</span>
              <span class="titleslight--text font-weight-bold ml-1">*</span>
              <v-color-picker
                v-model="color">
              </v-color-picker>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark small class="font-weight-bold" color="btncolor" @click="closePiker()">Cancelar</v-btn>
          <v-btn dark small class="font-weight-bold" color="btncolor" @click="setColor()">Guardar</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'FormColor',
  props: {
    value: {
    },
    titulo:{
      type:String,
      default:''
    },
    obligatorio:{
      type:Boolean,
      default: false
    },
    campo:{
      type:String,
      default : ''
    },
    editable: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      valor     : this.value,
      txtColor  : '',
      bMdlColor : false,
      type      : 'hex',
      esError   : false,
      noVacio   : [v => !!v],
      bEditable : (this.editable == "0") ? true : false,
    }
  },
  methods: {
    setColor() {
      this.txtColor = this.valor;
      this.bMdlColor = false;
    },
    closePiker() {
      this.bMdlColor = false;
    },
  },
  computed: {
    color: {
      get () {
        return this[this.type]
      },
      set (v) {
        this.valor = v[this.type];
      },
    },
    ...mapGetters(['getoFormError'])
  },
  watch: {
    valor(NewValor){
      this.$emit('input',NewValor.toLowerCase())
      this.txtColor = NewValor.toLowerCase();
      if(this.esError && this.valor != '') {
        this.esError = false;
      }
    },
    value(NewValue){
      if(NewValue != undefined){
        this.valor = NewValue
      }
    },
    getoFormError(){
      this.esError = this.getoFormError[this.campo]
    }
  },
}
</script>