<template>
  <v-dialog v-model="value" persistent max-width="600px">
    <v-card>
      <v-toolbar
        color="headformsdark"
        height="45"
        elevation="0"
        dense
        dark>
        <v-spacer></v-spacer>
        <span class="mdlHeadForms">Perfil</span>
        <v-spacer></v-spacer>
        <v-btn 
          color="btncolor-dark"
          @click="CloseDialog()"
          icon
          small
          dark
          outlined>
          <v-icon size="14px">fa fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-progress-linear
        :active="progressbar"
        indeterminate
        color="btncolor">
      </v-progress-linear>
      <v-container>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-avatar
              v-on="on"
              slot="offset"
              v-ripple
              class="mx-auto d-block elevation-6"
              size="130"
              @click="getInput()">
              <img :src="imageURL">
            </v-avatar>
            <input
              type="file"
              ref="file"
              accept="image/*"
              :name="uploadFieldName"
              @change="onFileChange($event.target.name, $event.target.files)"
              style="display:none"
            />
          </template>
          <span>Cambiar Imagen</span>
        </v-tooltip>
        <v-card-text>
          <v-form>
            <v-row align="center">
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span class="titleslight--text font-weight-medium">Nombre</span>
                <span class="titleslight--text font-weight-bold ml-1">*</span>
                <v-text-field
                  v-model="nombre"
                  :rules="nombreValidacion"
                  outlined
                  dense
                  required>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span class="titleslight--text font-weight-medium">Correo</span>
                <span class="titleslight--text font-weight-bold ml-1">*</span>
                <v-text-field
                  v-model="correo"
                  :rules="emailValidacion"
                  outlined
                  dense
                  required>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span class="titleslight--text font-weight-medium">Número Documento</span>
                <v-text-field
                  v-model="NoDoc"
                  v-on:keypress="clearInputs"
                  outlined
                  dense>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span class="titleslight--text font-weight-medium">Teléfono</span>
                <v-text-field
                  v-model="telefono"
                  outlined
                  dense>
                </v-text-field>
              </v-col>
            </v-row>
            <small class="d-flex justify-end"><span class="titleslight--text font-weight-medium">* Campo Obligatorio</span></small>
          </v-form>
        </v-card-text>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="btncolor"
          dark
          outlined
          :disabled="btnEnable"  
          @click="updateInfo()"
          >
          Guardar
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import axiosServices from '@/services/axiosServices.js';
export default {
  name:"perfiluser",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      oAfter: {},
      dialog: false,
      bValidate: false,
      progressbar: true,
      btnEnable: false,
      nombre: '',
      correo: '',
      NoDoc: '',
      telefono: '',
      imageURL: "http://innovacion.uanl.mx/wp-content/uploads/2017/06/sin-perfil.jpg",
      uploadFieldName: 'file',
      oFormData: '',
      nombreValidacion: [
        v => !!v || 'El nombre es requerido'
      ],
      emailValidacion: [
        v => !!v || 'Correo es requerido',
        v => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/.test(v) || 'Dirección de correo no valida',
      ],
    }
  },
  methods: {
    validateInputs() {
      this.bValidate = false;
      if (this.nombre !== "" && this.correo !== "" &&
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/.test(this.correo)) {
        this.bValidate = true;
      }
      return this.bValidate;
    },
    clearInputs: function(event) {
      if (/^([0-9])*$/.test(event.key) === false) {
        this.NoDoc = '';
      }
    },
    getUserDetails() {
      axiosServices.getConfiguration('/details_user', true, `nIdUser=${this.getIdUser}`)
      .then(aGetUserDetails => {
        this.oAfter = aGetUserDetails.aData;
        this.nombre = aGetUserDetails.aData["NombreUsuario"];
        this.correo = aGetUserDetails.aData["email"];
        this.NoDoc = aGetUserDetails.aData["cedula"];
        this.telefono = aGetUserDetails.aData["Telefono"];
        this.progressbar = false;
        this.$emit("progress", this.progressbar);
      })
    },
    getInput() {
      this.$refs.file.click();
    },
    getImgUser() {
      axiosServices.getConfiguration("/saveimg", true, `nIdUser=${this.getIdUser}`)
      .then(aGetImg => {
        if (typeof aGetImg !== "undefined") {
          this.imageURL = aGetImg.aData.path;
        }
        this.avatar = false;
      });
    },
    onFileChange(fieldName, file) {
      const maxSize = 1099999;
      let imageFile = file[0];
      if (file.length > 0) {
        if (!imageFile.type.match('image.*')) {
          // check whether the upload is an image
          this.$store.dispatch('notificaciones/SetMensaje', "Por favor seleccione un archivo de tipo imagen.");
          this.$store.dispatch('notificaciones/SetColor','error')
          this.$store.dispatch('notificaciones/ShowNotificacion', true);
        } else if (imageFile.size > maxSize) {
          // check whether the size is greater than the size limit
          this.$store.dispatch('notificaciones/SetMensaje', "Su archivo es demasiado grande! Por favor seleccione uno menor a 1MB.");
          this.$store.dispatch('notificaciones/SetColor','error')
          this.$store.dispatch('notificaciones/ShowNotificacion', true);
        } else {
          let formData = new FormData();
          let nIdUser = this.getIdUser;
          formData.append(fieldName, imageFile);
          formData.append("nIdUser", nIdUser);
          this.imageURL = URL.createObjectURL(imageFile);
          axiosServices.postConfiguration('/saveimg', formData, true);
        }
      }
    },
    updateInfo() {
      if (this.validateInputs()) {
        let oBody = {
          "nIdUser": this.getIdUser,
          "oAfter": this.oAfter,
          "oNew": {
            "name": this.nombre,
            "email": this.correo,
            "cedula": this.NoDoc,
            "Telefono": this.telefono
          }
        };
        this.btnEnable = true;
        axiosServices.putConfiguration('/details_user', oBody, true)
        .then(aUpdateInfo => {
          if (aUpdateInfo.nStatusCode == 214) {
            this.$swal({
              timer: 1500, 
              icon:`${aUpdateInfo.sStatus}`, 
              title:`${aUpdateInfo.sMessage}`, 
              showConfirmButton: false
            });
            this.btnEnable = false;
            this.CloseDialog();
          } else {
            this.$store.dispatch('notificaciones/SetMensaje', 'Presentamos inconvenientes, por favor intete mas tarade');
            this.$store.dispatch('notificaciones/SetColor','error')
            this.$store.dispatch('notificaciones/ShowNotificacion', true);
            this.btnEnable = false;
          }
        });
      }
    },
    CloseDialog() {
      this.dialog = false;
      this.$emit("input", this.dialog);
    },
  },
  watch: {
    value(bNewValue) {
      if (bNewValue) {
        this.getUserDetails();
        this.getImgUser();
      }
    }
  },
  computed: {
    ...mapGetters(['getIdUser'])
  },
}
</script>