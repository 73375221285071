<template>
  <div>
    <v-checkbox  class="mt-n4 mb-n5 pl-1 titleslight--text"
      v-model="valor" >
      <template v-slot:label>
        <span class="titleslight--text">{{titulo}}</span>
        <span v-if="obligatorio" class="titleslight--text ml-1">*</span>
      </template>
    </v-checkbox>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
  export default {
    name: 'FormCheckBox',
    props: {
      value: {
      },
      titulo:{
        type:String,
        default:''
      },
      obligatorio:{
        type:Boolean,
        default: false
      },
     campo:{
        type:String,
        default : ''
      },
      editable: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        valor   : this.value,
        esError : false,
        noVacio : [v => !!v],
        bEditable       : (this.editable == "0") ? true : false,
      }
    },
    mounted(){
    },
    methods: {

    },
    computed: {
      ...mapGetters(['getoFormError'])
    },
    watch: {
      valor(NewValor){
        this.$emit('input',NewValor)
        if(this.esError && this.valor != ''){
          this.esError = false
        }      
      },
      value(NewValue){
        this.valor = NewValue
      },
      getoFormError(){
        this.esError = this.getoFormError[this.campo]
      }
    },
    
  }
</script>

<style scoped>


</style>