<template>
  <v-row justify="center">
    <v-dialog v-model="bShowAddUser" persistent max-width="655px">
      <v-progress-linear v-if="cargando"
        indeterminate
        color="titlesdark darken-2">
      </v-progress-linear>
      <v-card>
        <v-toolbar
          color="headformsdark"
          height="45"
          elevation="0"
          dense
          dark>
          <v-spacer></v-spacer>
          <span class="mdlHeadForms">{{ sTitle }}</span>
          <v-spacer></v-spacer>
          <v-btn 
            color="btncolor-dark"
            @click="CloseDialog()"
            icon
            small
            dark
            outlined>
            <v-icon size="14px">fa fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row justify="center">
              <v-col sm="12" md="12" lg="12" xl="12">
                <span class="titleslight--text font-weight-medium">Cliente</span>
                <span class="titleslight--text font-weight-bold ml-1">*</span>
                <br>
                <span class="font-weight-bold">{{ sNameParent }}</span>
              </v-col>
              <v-col sm="12" md="12" lg="12" xl="12" class="py-0 ma-0 ColumnaResumida">
                <span class="titleslight--text font-weight-medium">Seleccionar departamento</span>
                <span class="titleslight--text font-weight-bold ml-1">*</span>
                <br>
                <v-autocomplete
                  v-model="department"
                  :items="itemsDataSelect"
                  item-text="texto"
                  item-value="id"
                  :placeholder="Mensaje"
                  :loading="loadSelect"
                  height="30px"
                  clearable
                  outlined
                  dense
                  :error="esError"
                  :rules="noVacio">
                </v-autocomplete>
              </v-col>
              <v-col v-if="showDobleSelect" sm="12" md="12" lg="12" xl="12" class="py-0 ma-0 ColumnaResumida">
                <span class="titleslight--text font-weight-medium">Seleccionar usuarios</span>
                <span class="titleslight--text font-weight-bold ml-1">*</span>
                <br>
                <double-select
                  :items="itemsDataDouble"
                  :selectedItems="aItemsSelected"
                  :searchable="true"
                  text-field="texto"
                  value-field="id"
                  :searchablePlaceholder="Searchstates">
                </double-select>
              </v-col>
            </v-row>
            <small class="d-flex justify-end"><span class="titleslight--text font-weight-medium">* Campo Obligatorio</span></small>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark outlined color="btncolor" @click="limpiarCampos()" :disabled="btnEnable">Limpiar</v-btn>
          <v-btn dark outlined color="btncolor" @click="salvar()" :disabled="btnEnable">Guardar</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row> 
</template>
<script>
import axiosServices from '@/services/axiosServices.js'
export default {
  name:'adduser',
  props: {
    bShowAddUser: {
      type: Boolean,
      default: false
    },
    oParent: {
      type: Object,
    },
    sTable: {
      type: String,
    },
    sTitle: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      department      : [],
      nIdParent       : '',
      cargando        : false,                            // empleada para indicar que el form esta cargando
      btnEnable       : false,
      showDobleSelect : false,
      itemsDataSelect : [],
      itemsDataDouble : [],
      Mensaje         : 'Cargando..',
      esError         : false,
      noVacio         : [v => !!v],
      loadSelect      : false,
      Searchstates    : `Buscar`,
      sNameParent: '',
      aItemsSelected: [],
    }
  },
  mounted() {
    this.getItemsSelect()
  },
  methods: {
    validateInputs() {
      let bEnable = false;
      if (this.aItemsSelected.length !== 0) {
        bEnable = true;
      }
      return bEnable;
    },
    getItemsSelect(){
      this.cargando = true;
      this.loadSelect = true
      this.Mensaje = 'Cargando ..'
      axiosServices.getConfiguration('/select/Departamentos/NombreDepartamento', true ).then(aItems =>{
        this.itemsDataSelect = aItems.aData
        this.loadSelect = false
        this.Mensaje = 'Seleccione'
        this.cargando = false
      });
      this.nIdParent = this.oParent.idClientes;
      this.sNameParent = this.oParent.Nombre_Cliente;
    },
    getItemsDouble(nIdDepartment) {
      this.cargando = true;
      let sParams = `sTable=Usuarios&sTableSec=DetallesUsuarios&nIdDependence=${nIdDepartment}&sField=NombreUsuario&swhere=idDepartamentos`
      axiosServices.getConfiguration('/doubleselect_dep', true, sParams)
      .then(aItemData => {
        this.itemsDataDouble = aItemData.aData
        this.cargando = false
        this.showDobleSelect = true;
      });
    },
    loadDataEdit(nIdDepartment) {
      this.cargando = true;
      let sParams = `sTable=${this.sTable}&nIdParentC=${this.nIdParent}&nIdParentD=${nIdDepartment}&sTableWhere=Usuarios,Departamentos,Clientes`
      axiosServices.getConfiguration('/adduser', true, sParams)
      .then(aDataEdit => {
        this.aItemsSelected = aDataEdit.aData;
        this.cargando = false
      })
    },
    salvar() {
      if (this.validateInputs()) {
        let sParams = `sTable=${this.sTable}`;
        let oBody = {
          idClientes: this.nIdParent,
          idUsuarios: this.aItemsSelected,
          idDepartamentos: this.department
        };
        this.cargando = true;
        this.btnEnable = true;
        axiosServices.postConfiguration('/adduser', oBody, true, sParams)
        .then(aResponse => {
          // console.log(aResponse);
          if (aResponse.nStatusCode == 201) {
            this.btnEnable = true;
            this.$swal({
              timer: 1500, 
              icon:`${aResponse.sStatus}`, 
              title:`${aResponse.sMessage}`, 
              showConfirmButton: false
            });
            this.CloseDialog();
          } else {
            this.btnEnable = false;
            this.$store.dispatch('notificaciones/SetMensaje', "Estamos presentando problemas, por favor intente mas tarde.");
            this.$store.dispatch('notificaciones/SetColor','error')
            this.$store.dispatch('notificaciones/ShowNotificacion', true);
          }
        });
      } else {
        this.btnEnable = false;
        this.$store.dispatch('notificaciones/SetMensaje', "Campos vacios.");
        this.$store.dispatch('notificaciones/SetColor','error')
        this.$store.dispatch('notificaciones/ShowNotificacion', true);
      }
    },
    CloseDialog() {
      this.oFormCampos = {}
      this.$emit('input', false)
    },
    limpiarCampos(){
      this.showDobleSelect = false;
      this.department = '';
    },
  },
  watch: {
    department(sNewvalue){
      this.getItemsDouble(sNewvalue)
      this.loadDataEdit(sNewvalue)
    },
    bShowAddUser(bNewValue) {
      // console.log("asd", bNewValue);
      if (bNewValue) {
        this.nIdParent = this.oParent.idClientes;
        this.sNameParent = this.oParent.Nombre_Cliente;
      }
    },
  }
}
</script>
<style>
.searchable[data-v-93b02c86] {
    border: 1px solid #757575 !important;
    color: #353535 !important;
    border-radius: 5px !important;
}
ul[data-v-93b02c86] {
  border: 1px solid #757575 !important;
  color: inherit !important;
  border-radius: 5px !important;
}
ul li[data-v-93b02c86] {
  border-bottom: 1px solid #757575 !important;
  color: #353535 !important;
}
</style>