<template>
  <div>
    <span class="titleslight--text font-weight-medium">{{titulo}}</span>
    <span v-if="obligatorio" class="titleslight--text font-weight-bold ml-1">*</span>
    <v-text-field
      v-model="valor"
      single-line
      outlined
      clearable
      :disabled="bEditable"
      :error="esError"
      :rules="noVacio"
      dense>
      <template v-slot:append-outer>
        <v-btn v-if="!cargandoGeoCodec" text icon color="btncolor" class="mt-n2 pa-0" @click="vermapa">
          <v-icon>fa-map-marked-alt</v-icon>
        </v-btn>
        <v-progress-circular v-if="cargandoGeoCodec"
        indeterminate
        color="primary"
        ></v-progress-circular>
      </template>
    </v-text-field>
    <v-dialog
      v-model="shwoMap"
      width="600">
      <v-card>
        <v-toolbar
          color="headforms"
          height="30"
          elevation="0"
          dense>
          <v-spacer></v-spacer>
          <span class="mdlHeadForms">Localización</span>
          <v-spacer></v-spacer>
          <v-btn 
            color="btncolor"
            @click="shwoMap = false"
            icon
            small>
            <v-icon>fa fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="ma-0 pa-0">
          <l-map :center="center" :zoom="14" style="width:100%; height: 400px; margin: 0px;">
            <l-tilelayer-googlemutant :apikey="apikeyGoogle" :options="optionsMap"></l-tilelayer-googlemutant>
            <l-marker :lat-lng.sync="center" :draggable="true" :title="value"></l-marker>
          </l-map>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axiosServices from '@/services/axiosServices.js'
import {mapGetters} from 'vuex'
import params from '@/params/params.js'
export default {
  name: 'FormDireccion',
  props: {
    value: {
    },
    titulo:{
      type:String,
      default:''
    },
    obligatorio:{
      type:Boolean,
      default: false
    },
    nCiudad :{
      type:String,
      default:''
    },
    campo:{
      type:String,
      default : ''
    },
    editable: {
      type: String,
      default: ''
    }
  },
  data() {
    let optionsMap = {
      type: "roadmap"
    };
    return {
      optionsMap,
      apikeyGoogle: params.sApiKeyMap,
      center  : { lat: 0, lng: 0 },
      valor   : this.value || '',
      shwoMap : false,
      lat     : '',
      lng     : '',
      cargandoGeoCodec : false,
      esError : false,
      noVacio : [v => !!v],
      bEditable : (this.editable == "0") ? true : false,
    }
  },
  mounted(){
  },
  methods: {
    vermapa(){
      this.shwoMap = !this.shwoMap
    },
     async geoCodecDireccion() {
        
        this.cargandoGeoCodec = true
        // lo primero que hago es poner el pais Colombia al final de la ubicación
        // saco las coordendas consultando
        let sDireccion_aux = ''

        let nombreDpto= await axiosServices.getConfiguration('/select_filro/Ciudades/NombreDepartamento/'+this.nCiudad , true )
        let nombreCiudad= await axiosServices.getConfiguration('/select_filro/Ciudades/NombreCiudad/'+this.nCiudad , true )
        

        sDireccion_aux = this.valor + "," + nombreCiudad.aData[0].texto + "," + nombreDpto.aData[0].texto +",Colombia"
        // console.log(`valor: ${this.valor}`, `Ciudad: ${nombreCiudad.aData[0].texto}`, `Departamento: ${nombreDpto.aData[0].texto}`, 'Colombia');
        this.$geocoder.setDefaultMode("address") // this is default
        let addressObj = {
          // address_line_1: '1600 Amphitheatre Parkway',
          address_line_1: sDireccion_aux
          // address_line_2: '',
          // city:           'Mountain View',
          // state:          'CA',               // province also valid
          // zip_code:       '94043',            // postal_code also valid
          // country:        'United States'
        };
        this.$geocoder.send(addressObj, response => {
          if (response.status === "OK") {
            this.center.lat = response.results[0].geometry["location"].lat
            this.center.lng = response.results[0].geometry["location"].lng
            this.$store.dispatch('savelatlng',response.results[0].geometry["location"])
          }else{
            this.$store.dispatch('savelatlng',{lat:'',lng:''})
            
          }
          this.cargandoGeoCodec = false
        })
        

        
    },
  },
  computed: {
    ...mapGetters(['getlatlng','getoFormError'])
  },
  watch: {
    valor(NewValor){
      if(NewValor != null || NewValor != undefined){
        this.$emit('input',NewValor)
        if(NewValor.length > 9){
          this.geoCodecDireccion()
        }  
      }
      if(this.esError && this.valor != ''){
        this.esError = false
      }
    },
    value(NewValue){
      this.valor = NewValue
    },
    nCiudad(NewCiudad){
      if(NewCiudad != undefined){
        if(this.valor.length > 9  && NewCiudad != undefined){
          this.geoCodecDireccion()
        }
      }
    },
    center(){
      this.$store.dispatch('savelatlng',this.center) 
    },
    getoFormError(){
      this.esError = this.getoFormError[this.campo]
    }

  },
  
}
</script>

<style scoped>


</style>