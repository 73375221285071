<template>
  <v-container grid-list-xs class="pa-0 ma-0" fluid>
    <v-row class="pa-0 ma-0 titlesdark--text">
      <v-col md="8" sm="12" class="pa-0 ma-0 mt-1">
        <span class="pl-1 text-light font-size-2">{{tituloGrids}}</span>
      </v-col>
       <v-col md="12" sm="12" cols="12" class="pa-0 ma-0">
        <v-breadcrumbs
          class="py-2 px-0"
          :items="aItemsBreadcrumbs"
          divider=">"
        >
         <template v-slot:item="{ item }">
          <v-breadcrumbs-item class="titlesdark--text">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row v-if="!mostrarFormularioNuevo" class="pa-0 ma-0">
      <v-col md="12" sm="12" class="pa-0 ma-0">
        <grid
          v-if="this.$store.state.token!=''"
          :tabla="TablaName"
          :Detalles="'DetalleReporteRiesgosSinGestion'"
          :bActiveFilter="true"
          :bActiveExport="true"
          :bSingleSelect="true"
          :bShowSelect="true"
          :sIdTable="'ID_RiesgoNovedad'"
          :bShowMapDetails="false"
          :bActiveNew="false"
          :aInfoTBDetalleExp="aDetallesExport"
          :aParametros="aParametrosAdicionales"
          :bSubdominioTemporal="true"
          @saveOk="createDetaill"
          @idSelect="getIdSelect"
          @nameField="getNameFieldSelect"
          @oItemSeleccionado="getItemSeleccionado">
        </grid>  
      </v-col>
        <v-col md="12" sm="12" cols="12" class="pa-0 ma-0 mt-2">
           <grid-detalle
            v-if="parseInt(nIdSeleccionado) != 0"
            :tabla="'RiesgoRecomendacion$1'"
            :sIdTable="'idRiesgoRecomendacion'"
            :bActiveFilter="false"
            :bActiveExport="false"
            :bSingleSelect="false"
            :bShowSelect="false"
            :bShowMapDetails="false"
            :bActiveSearch="false"
            :bActiveNew="false"
            :bGridDetalle="true"
            :nIdValor="parseInt(nIdSeleccionado)"
            :sCampoFiltro="'idRiesgoRecomendacion'"
            :bSubdominioTemporal="true"
          />
        </v-col>
    </v-row>

    <nueva-acta-reunion-pqr 
      v-else
      v-model="mostrarFormularioNuevo" :key="nKeyReset"
    />
  </v-container>
</template>

<script>
import grid from '@/components/Grid/Grid.vue'
import mxFunciones from '@/mixins/funciones.js'
import NuevaActaReunionPqr from '@/components/Seif/operaciones/NuevaActaReunionPqr.vue'
import GridDetalle from '@/components/Grid/Grid.vue'
// import DialogFirmas from '@/components/DialogFirmas.vue'

export default {
  name: 'ReportesSinGestion',
  components: {
    grid,
    NuevaActaReunionPqr,
    GridDetalle,
    // DialogFirmas
  },
  mixins: [mxFunciones],
  data() {
    return {
      TablaName : 'RiesgoNovedades$1',    // Nombre de la tabla a trabajar
      nIdSeleccionado  : 0,                 // Id seleccionado
      sNombreCampoFiltro : '',                 // Nombre del campo foreignKey
      oItemSeleccionado: {},
      mostrarFormularioNuevo : false,
      nKeyReset : 0,
      aParametrosAdicionales:[
        "idTipo",
        "ID_punto"
      ],
      aItemsBreadcrumbs: [
        {
          text: 'Inicio',
          disabled: true,
          href: 'breadcrumbs_dashboard',
        },
        {
          text: 'Riesgos',
          disabled: true,
          href: 'breadcrumbs_dashboard',
        },
        {
          text: '',
          disabled: true,
          href: 'breadcrumbs_dashboard',
        },
      ],
      aDetallesExport : [],
    }
  },
  async mounted() {
    this.onValidarTokenSinLogin()
    this.tituloGrids = await this.getTitulo(this.TablaName, true)
    this.aItemsBreadcrumbs[2].text = this.tituloGrids
  },
  methods: {
    getIdSelect(sIdSelected) {
      this.nIdSeleccionado = sIdSelected;
    },
    getNameFieldSelect(sNameFiled) {
      this.sNombreCampoFiltro = sNameFiled;
    },
    getItemSeleccionado(oItemSeleccionado) {
      this.oItemSeleccionado = oItemSeleccionado;
    },
    createDetaill() {
  
    },
    onMostarModal() {
      this.mostrarFormularioNuevo = true
      this.nKeyReset++
      this.aItemsBreadcrumbs.push({
        text: 'Crear ' + this.tituloGrids,
        disabled: true,
        href: 'breadcrumbs_dashboard',
      })
    }
  },
  watch: {
    mostrarFormularioNuevo(bNuevoValor) {
      if(!bNuevoValor){
        this.aItemsBreadcrumbs.splice(3,1)
      }
    },
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>

</style>