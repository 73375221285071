<template>
  <div>
     <!-- color="error" -->
      <v-snackbar
        :color="getmensajeColor"
        outlined
        text
        elevation="12"
        :multi-line="multiLine"
        v-model="getsnackbar"
        :timeout="timeout ">
        <div class="mx-auto text-center my-2">
          {{ getmensajeNotificacion }}
        </div>
      
        <div class="text-center mt-2">
          <v-btn
            :color="getmensajeColor"
            margin-auto
            @click="$store.dispatch('notificaciones/ShowNotificacion',false)">
            Cerrar
          </v-btn>
        </div>
      </v-snackbar>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props:{
    colorNoti: {
      type:  String,
      default:'error'
    }
  },
  data() {
    return {
      text    : '',
      timeout : -1,
      multiLine : false
    }
  },
  computed: {
    ...mapGetters('notificaciones',['getsnackbar','getmensajeNotificacion','getmensajeColor'])
  },
}
</script>