import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import guardIcon from '@/components/Iconos/GuardSVG.vue';
import customerIcon from '@/components/Iconos/CustomerSVG.vue';
import companyIcon from '@/components/Iconos/CompanySVG.vue';
import countryIcon from '@/components/Iconos/CountrySVG.vue';
import avatarIcon from '@/components/Iconos/AvatarSVG.vue';
import logoIcon from '@/components/Iconos/LogoSVG.vue';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary       : '#0E69B0',   // indigo
        secondary     : '#424242',   // gris oscuro 
        accent        : '#82B1FF',   // azul clarito
        error         : '#FF5252',   // rojo
        info          : '#2196F3',   // azul oscurito
        success       : '#4CAF50',   // verde
        warning       : '#FFC107',   // amrillo
        btncolor      : '#0E69B0',   // Azul de los botones
        headforms     : '#FFFFFF',    // Gris cabecera formularios modal
        titlesdark    : '#0E3143',   // Azul oscuro titulos y barra de progreso
        titleslight   : '#4075D9',  //Azul claro titulos formularios
        backgrey      : '#F8F8F8',   // gris clarito fondo
        ntitlesdark   : '#012362',  // Nuevo color de titlulos oscuro
        buttondark    : '#02316C',   // Nuevo color de titlulos oscuro
        st_enable     : '#FFDD67',    // Color state Enable Insertado
        st_t_enable   : '#9c863a',  // Text Color state Enable  Insertado
        st_success    : '#CFE4D4',    // Color state  Completado
        st_t_success  : '#2D392E',  // Text Color state Completado
        st_disable    : '#F0C5C3',   // Color state Disable 
        st_t_disable  : '#796463',  // Text Color state Disable 
        st_default    : '#fbbd88',   // Color state Default 
        st_t_default  : '#a9805c',  // Text Color state Default 
        btndest       : '#4075D9',  //Color de boton destacado
        btnwhite      : '#FFFFFF',  // Color de boton regular
        colortextinfo : '#7E84A3',  //Color para textos informativos
        primarydark   : '#161E33',  // Color oscuro para los fondos alto contraste
        backwhite     : '#FFF',
        backlogin     : '#FFF',
        white         : '#FFF',
        headformsdark : '#161E33',
        blue_light    : '#D4F1F2' //
       
      },
      dark:{
        backwhite : "#161E33",
        colortextinfo : "#FFFFFF",
        backlogin : "#040b1d",
        btncolor:"#FFFFFF"
      }
    },
  },
  icons: {
    icons: {
      iconfont: 'fa' || 'mdi',
    },
    values: {
      // los iconos personalizados se llaman asi <v-icon>$vuetify.icons.Policia</v-icon>
      guardIcon :{
        component: guardIcon
      },
      customerIcon: {
        component: customerIcon
      },
      companyIcon: {
        component: companyIcon
      },
      countryIcon: {
        component: countryIcon
      },
      avatarIcon: {
        component: avatarIcon
      },
      logoIcon: {
        component: logoIcon
      }
    }
  },
});
