export default {
   COPSECANCOL_Actas_reunion: {
        oFirmas: {
            nFiltro: 'actareunionid',
            sAsistente: 'Nombre_Asistente',
            sFirma: 'Firma_Asistente',
            sTablaAnexos: 'COPSECANCOL_Asistentes_Acta_Reunion'
        },
        oFotos: {
            nFiltro: 'actareunionid',
            sFoto: 'nombre_anexo',
            sTipoAnexo: 'tipo_anexo',
            sTablaAnexos: 'COPSECANCOL_Anexos_Acta_Reunion'
        }
    },
    ELEMENTOS_ElementosEntrada: {
        oFotos: {
            nFiltro: 'idActivo',
            sAnexo: 'NombreAnexo',
            sTipoAnexo: '',
            sTablaAnexos: 'ELEMENTOS_ActivosAnexos'
        }
    }
}