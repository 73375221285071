<template>
  <div class="cont-upfiles my-2 pa-2">
    <div class="dropbox my-0 pa-2">
      <div class="icon-upload mb-2"><v-icon large>mdi-file-upload</v-icon> </div>
      <p> Arrastra tus archivos o clik para abrir (Maximo 1MB) </p>
      <p> Formatos permitidos {{extenAccept}}</p>
      <p> Tamaño Maximo por Archivo: {{MaxTamanoFile/1024/1024}} MB</p>
      <p v-if="isMaximoFile" class="text--red"> Maximo de files exedido: {{NoAnexos}}</p>
      <input type="file" :multiple="NoAnexos > 1 ? true : false" @change="filesChange($event.target.files); fileCount = $event.target.files.length"
        :accept="extenAccept" class="input-file pa-0 ma-0">
    </div>
    <v-snackbar  v-model="isSnackBar" :timeout="3000">
      <p v-html="sMensaje"/>
    </v-snackbar>
    <div class="cont-listfiles">
      <ul class="list-unstyled">
        <li v-for="(archivos, key) in aFiles" :key="key" class="my-2">
          <p v-for="(archivo, key2) in archivos" :key="key2"><v-icon>mdi-file-check-outline</v-icon>{{archivo.name}}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  const  isOK = 0, NoFileMaximo = 1

  export default {
    name: 'DragUpdatFile',
    props: {
      NoAnexos: {
        type: Number,
        default: 1
      },
      extenAccept: {
        type: String, 
        default: "image/*, .pdf, .xls, .xlsx, .doc, .docx "
      },
      MaxTamanoFile: {
        type: Number,
        default: 1048576,  // 1 MB se mete en brytes
      }
    },
    data() {
      return {
        aFiles: [],
        uploadError: null,
        currentStatus: null,
        estatus : 0,
        sMensaje: '',
        isSnackBar: false
      }
    },
    methods: {
      reset() {
        // reset form to initial state
        this.aFiles = [];
        this.uploadError = null;
      },
      filesChange(fileList) {
        //console.log(fileList, typeof fileList)
        if((this.CountArchivos + fileList.length) > this.NoAnexos ){
          this.estatus = NoFileMaximo
        } else {
          // antes de guardar valido el tamaño de cada file
          this.isSnackBar = false
          let aDataGuardar = {}
          let nItem=0;
          this.sMensaje = `Archivo(s) removido(s) por Tamaño superior a  ${this.MaxTamanoFile/1024/1024}MB: <br>`

          if(fileList.length == 1){
            if(this.ValidarErrores(fileList[0])) {
              aDataGuardar = fileList
            } 
            else {
              this.sMensaje += "* " + fileList[0].name + "<br>"
              this.isSnackBar = true
            }

          } else {
            fileList.forEach((item) =>{
              if(this.ValidarErrores(item)){
                aDataGuardar[nItem] = item
                nItem++
              }else{
                this.sMensaje += "* " + item.name + "<br>"
                this.isSnackBar = true
              }
            })
          }
          this.aFiles.push(aDataGuardar)
          this.estatus = isOK
        }

        this.$emit('files', this.aFiles)
      },
      ValidarErrores(oitem) {
        if (oitem.size > this.MaxTamanoFile){
          return false
        }else {
          return true
        }
      },
    },
    computed: {
      CountArchivos() {
        let aux_count = 0
        this.aFiles.forEach(file =>  aux_count += file.length)
        return aux_count
      },
      isMaximoFile() {
        return this.estatus === NoFileMaximo
      }
    },
    mounted() {
      this.reset();
    },
  }
</script>

<style lang="scss" >
  .cont-upfiles {
    border: 2px solid var(--v-blue_light-base);
    background: #F5FFFF;
    border-radius: 10px;
  }
  .dropbox {
    // outline: 2px dashed grey; /* the dash box */
    border: 1px solid var(--v-blue_light-base);
    border-radius: 10px;
    color: dimgray;
    min-height: 60px; /* minimum height */
    position: relative;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
    background: var(--v-blue_light-base);
    width: 100%;
    .icon-upload{
      margin: 0 auto;
      text-align: center;
    }
    p {
      text-align: center;
      margin: 0;
      padding: 0;
      font-size: 0.9em;
      line-height: 1.2em;
      transition: all ease-in-out 0.3s;
      // color:var(--v-blue_light-base);
    }
  }
  .dropbox:hover {
    background: var(--v-primary-base); /* when mouse over to the drop zone, change color */
    .icon-upload{
      i{
      color: var(--v-blue_light-base);
      }
    }
    p{
      color:var(--v-blue_light-base);
    }
  }
  
  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 100px;
    top: 0px;
    left: 0px;
    position: absolute;
    cursor: pointer;
  }
  
  
  .list-unstyled {
    font-size: 0.8em;
    line-height: 1.1em;
    color: #424242;
    list-style: none;
    margin: 0!important;
    padding: 0!important;
    p{
      margin: 0px;
      padding: 0px;
    }
  }

.cont-listfiles{
  // border: solid blue;
  width: 100%;
}

</style>
