<template>
	<div class="imprimir">
		<v-list-item one-line class="back-dark" v-for="(oInfo, i) in aInfo" :key="i">
			<h4 class="text-white text-medium">{{oInfo.index}}:&nbsp;</h4>&nbsp;<span class="text-white">&nbsp;{{oInfo.value}}</span>
		</v-list-item>
	</div>
</template>
<script>
export default {
	name:"AsuntoVista",
	props:{
		aInfo: {},
		nIdParent: {
			type: String,
			default: ''
		},
		sNameDetails: {
			type: String,
			default: ''
		},
		bSubdominioTemporal:{ //Indica que se activa puente con subdominio temporal
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
		}
	},
}
</script>
<style lang="sass" scoped>
</style>