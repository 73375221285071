<template>
	<div>
		<v-sheet v-for="(oInfo, i) in aCardData" :key="i" class="mx-auto py-3 px-3 under-sec-flat" 
		:hidden="hiddenElement">
			<h4 class="text-medium ">{{ oInfo.value }}</h4>
			<v-slide-group class="pa-1" show-arrows>
			<v-slide-item v-for="(oCardDataItem, j) in oInfo.aData.aFotos" :key="j">
				<v-card class="ma-2 border-card" width="300">
					<v-card-text >
						<div>
							<v-card class="ma-1 mx-auto card-img" height="170" width="170"
								@click="cardAction(oCardDataItem.Anexo)">
								<v-img class="align-end " height="170" :src="oCardDataItem.Anexo" contain>
									<template v-slot:placeholder>
										<v-row
											class="fill-height ma-0"
											align="center"
											justify="center">
											<v-progress-circular
												indeterminate
												color="blue lighten-3">
											</v-progress-circular>
										</v-row>
									</template>
								</v-img>
							</v-card>
						</div>
						
					</v-card-text>
				</v-card>
			</v-slide-item>
			</v-slide-group>
			<v-slide-group class="pa-1" show-arrows>
			<v-slide-item v-for="(oCardDataItem, j) in oInfo.aData.aDocs" :key="j">
				<v-card class="ma-1 border-card under-sec-flat" width="300" @click="onDescargarArchivo(oCardDataItem.Anexo)">
					<v-card-text class="item-anexo-archivo">
							<span>	{{onObtenerNombreArchivo(oCardDataItem.Anexo)}}</span>
					</v-card-text>
				</v-card>
			</v-slide-item>
			</v-slide-group>
		</v-sheet>
		<AmpliarFoto
			v-model="ampliar" 
			:fotoampliada="ftampliada">
		</AmpliarFoto>
		<div v-for="(oInfo, i) in aCardData" :key="`expor`+i" class="imprimir under-sec-flat" :hidden="!hiddenElement">
			<div width="100%" class="px-4 my-3">
				<h4 class="text-medium">{{oInfo.value}}</h4>
			</div>
			<div width="50%">
				<div style="display: flex;">
					<v-container>
						<v-row class="border-card px-4">
							<v-col v-for="(oCardDataItem, j) in oInfo.aData.aFotos" :key="j" sm="3" md="3" lg="3" xl="3">
								<div id="sImgReference" >
									<img class="aFotos" style="width: 180px; height: 180px;" :src="oCardDataItem.Anexo" contain />
								</div>
							</v-col>
						</v-row>
						<v-row class="border-card px-4 mt-2">
							<v-col v-for="(oCardDataItem, j) in oInfo.aData.aDocs" :key="j" cols="12" sm="12" class="pa-1 ma-0">
								<div id="sImgReference" >
									<span class="aFotos aFiles">{{onObtenerNombreArchivo(oCardDataItem.Anexo)}}</span>
								</div>
							</v-col>
						</v-row>
					</v-container>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import axiosServices from '@/services/axiosServices.js'
import AmpliarFoto from '@/components/AmpliarFoto'

export default {
	name:"AnexosVista",
	components:{
		AmpliarFoto
	},
	props:{
		aInfo: {},
		nIdParent: {
			type: String,
			default: ''
		},
		sNameDetails: {
			type: String,
			default: ''
		},
		hiddenElement:{
			type: Boolean,
			default: false
		},
		bSubdominioTemporal:{ //Indica que se activa puente con subdominio temporal
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			ampliar: false,
			ftampliada: null,
			aCardData: [],
			fotos: 1,
		}
	},
	mounted() {
		this.getData()
	},
	methods: {
		async getData() {
			let sParams = ''
			this.aCardData = []
			Object.values(this.aInfo).forEach(async oInfo => {
				oInfo.aData = {
					aFotos: [],
					aDocs: []
				}
				sParams = `sTablePrin=${oInfo.index}&nIdParent=${this.nIdParent}&sDetails=${this.sNameDetails}`
				if(this.bSubdominioTemporal){
					sParams = sParams + `&subdominiot=${this.$store.state.sSubdominioTemporal}`
				}
				const aResponse = await axiosServices.getConfiguration('/drawerDetails_getDataAnexos', true, sParams)
				aResponse.aData.forEach(oItem => {
					if(oItem.Tipo=="1"){
						oInfo.aData.aFotos.push(oItem)
					}else{
						oInfo.aData.aDocs.push(oItem)
					}
				})
				this.aCardData.push(oInfo)
			})
			
			
		},
		cardAction(val){
			this.ampliar = true
			this.ftampliada = val
		},
		onObtenerNombreArchivo(sUrl){
			return sUrl.substring(sUrl.lastIndexOf('/')+1)
		},
		onDescargarArchivo(sUrl){
			let link = document.createElement("a")
			link.target = "_blank"
			link.download = this.onObtenerNombreArchivo(sUrl)
			link.href = sUrl
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		}
	}
}
</script>
<style lang="scss" scoped>
.card-img{
	border: solid gray 1px!important;
	border-radius: 10px;
	box-shadow: none!important;
}
.item-anexo-archivo{
	font-size: 0.8em;
	line-height: 1.1em;
	span{
		text-decoration: underline;
	}
}
.aFiles{
	font-size: 0.8em;
	line-height: 1.1em;
}
</style>