<template>
  <div>
    <span class="titleslight--text">{{titulo}}</span>
    <span v-if="obligatorio" class="titleslight--text font-weight-bold ml-1">*</span>
    <v-textarea
      class="mt-1"
      v-model="valor"
      :disabled="bEditable"
      :error="esError"
      :rules="noVacio"
      full-width
      :rows="nRow"
      outlined
      :auto-grow="bAutoGrow"
      :placeholder="sPlaceHolder"
      clearable
      counter
      dense>
    </v-textarea>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FormTextArea',
  props: {
    value: {
    },
    titulo:{
      type:String,
      default:''
    },
    obligatorio:{
      type:Boolean,
      default: false
    },
    campo:{
      type:String,
      default : ''
    },
    editable: {
      type: String,
      default: ''
    },
    sPlaceHolder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      valor   : this.value,
      esError : false,
      noVacio : [v => !!v],
      bEditable : (this.editable == "0") ? true : false,
      bAutoGrow : true,
      nRow: 3,
    }
  },
  mounted(){
  },
  methods: {

  },
  computed: {
    ...mapGetters(['getoFormError']),
  },
  watch: {
    valor(NewValor){
      this.$emit('input',NewValor)
      if(this.esError && this.valor != ''){
        this.esError = false
      }

    },
    value(NewValue){
      this.valor = NewValue
      if(this.valor.length >=500){
        this.bAutoGrow = false
      }else{
        this.bAutoGrow = true
      }
    },
    getoFormError(){
      this.esError = this.getoFormError[this.campo]
    }

  },
  
}
</script>

<style scoped>


</style>