// const fs = require('fs')

export default {
  Base64toFile(url, filename, mimeType){
    return (fetch(url)
        .then(res => {return res.arrayBuffer();})
        .then(buf => {return new File([buf], filename,{type:mimeType});})
    );
  },

//Usage example:
// Base64toFile('data:text/plain;base64,aGVsbG8gd29ybGQ=', 'hello.txt','text/plain')
// .then((file) => { console.log(file);});

//  const file = await Base64ToFile.Base64toFile(this.$refs.canvas.toDataURL("image/png"), `${Hora_Fecha.getNowFile()}.png`,'image/png')


}