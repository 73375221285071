<template>
  <div>
    <span :class="[color, color !='' ? 'white--text': '']">{{ConvertirValor}}</span>
  </div>
</template>
<script>
import mixinGridComponenteCelda from "@/mixins/GridComponenteCelda.js"

export default {
  name: "CeldaSiNo",
  mixins: [mixinGridComponenteCelda],
  computed: {
    ConvertirValor() {
      let respuesta = ""
      switch (this.valor) {
        case "1":
          respuesta = "BUENO"
        break;
        case "0":
          respuesta = "REGULAR"
        break;
        case "2":
          respuesta = "N/A"
        break;
      }

      return respuesta 
    }
  },
};

</script>

<style scoped></style>