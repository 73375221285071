<template>
  <div v-if="aConfiguracion.permisos.priv_access == '1'">
    <v-data-table
      v-model="selected"
      :headers="aFormatHeaders"
      :items="dataItems"
      :single-select="bSingleSelect"
      :search="quitSearch"
      :items-per-page.sync="itemsPerPagina"
      :page.sync="paginaselect"
      :item-key="sIdTable"
      :loading="cargando"
      :show-select="bShowSelect"
      :show-expand="bGridAnidada"
      :single-expand="true"
      :expanded.sync="aDataParaAnidada"
      hide-default-footer
      dense
      class="rounded-basic table-app"
      fixed-header
      :height=" itemsPerPagina >= 10 ? '65vh' : 'auto'"
      >
  
      <template v-slot:top >
        <v-container class="px-3 pt-2 pb-0 mb-1 container">
          <v-row align="center" justify="start">
            <v-col cols="12" lg="4" md="4" sm="12" xs="12" class="py-0">
              <v-text-field
                v-model="quitSearch"
                label=" Busqueda Rápida"
                append-icon="fa-search"
                background-color="white"
                flat
                hide-details
                rounded
                dense
                outlined
                class="container-search"
                >
              </v-text-field>  
            </v-col>  
            <v-col cols="12" lg="8" md="8" sm="12" xs="12" class="pt-4 d-flex row wrap justify-end d-flex cont-acciones">
              <v-btn v-if="bActiveFilter" color="btndest" class="no-uppercase btn-rounded my-1" small depressed dark @click="filtrar">Filtrar<v-icon size="13px" right>fa-filter</v-icon></v-btn>
              <slot name="slotbtnNew"></slot> 
                <v-btn v-if="bActiveNew && aConfiguracion.permisos.priv_insert == '1'" color="btndest" class="no-uppercase btn-rounded my-1" small depressed dark @click="nuevoItem">Nuevo<v-icon size="13px" right>fa-file-alt</v-icon></v-btn>
              <v-menu
                v-if="bActiveExport"
                class="d-flex mx-2"
                offset-y
                :close-on-content-click="false"
                transition="slide-y-transition">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" @click="exportinfo()" color="primary" outlined class="no-uppercase btn-rounded my-1" small depressed> Exportar <v-icon size="13px" right>fa-file-export</v-icon>
                  </v-btn>
                </template>
                <v-list
                  dense>
                  <v-list-item @click="exportar('xlsx', true)">
                    <v-list-item-content>
                      <v-list-item-title><v-icon left>fa-file-excel</v-icon>Exportar todo a Excel</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- <v-list-item @click="exportar('csv', true)">
                    <v-list-item-icon><v-icon>fa-file-csv</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content><v-list-item-title>Exportar todo a CSV</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item> -->
                  <v-list-item @click="exportar('xlsx')">
                    <v-list-item-content>
                      <v-list-item-title> <v-icon left>fa-file-excel</v-icon>Exportar información actual a Excel</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- <v-list-item @click="exportar('csv')">
                    <v-list-item-icon><v-icon>fa-file-csv</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content> <v-list-item-title>Exportar información actual a CSV</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item> -->
                </v-list>
              </v-menu>
            </v-col>
          </v-row>  
        </v-container>
        <!-- CONTAINER DEL FILTRO -->
        <v-container v-show="filtrarActive">
          <v-card style="overflow:auto">
            <v-toolbar
              height="40"
              elevation="0"
              dense
              class="pt-2">
              <v-spacer></v-spacer>
              <span class="mdlHeadForms">Filtros Detallados</span>
              <v-spacer></v-spacer>
              <v-tooltip bottom v-if="filtradoEstate">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    color="btncolor"
                    @click="LimpiarFiltros()"
                    outlined
                    dark>
                    Limpiar Filtros
                    <v-icon small right>fa-trash-alt</v-icon>
                  </v-btn>
                </template>
                <span>Limpiar Filtros</span>
              </v-tooltip>
            </v-toolbar>
            <v-card-text>
              <FilterSecction
                :Headers="headers"
                @ejecutarFiltro="getFiltrarInicio"
                :bSubdominioTemporal="bSubdominioTemporal">
              </FilterSecction>
            </v-card-text>
          </v-card>
        </v-container>
      </template>
      <!-- CELDAS DEL GRID item tienen el dato a mostrar -- formatHeader tienen la info de la cabecera -- field.value tienen el nombre del campo-->
      <template v-slot:[`item.${field.value}`]="{ item }" v-for="(field, indexEncabezado) in aFormatHeaders" >
        <div :key="indexEncabezado" :class="`rounded ${getAlineacion(field.alineacion)}`" :style="'backgroundColor:'+getColor(item, field.value)+';'">
          <component :is="field.tipo" 
                  :texto="item[`${field.value}`]"
                  :titulo="field.text"
                  :class="{'text-inverted':(getColor(item, field.value))}"
                  >
                    <!-- :color="getColor(item, field.value)" -->
          </component>
        </div>
      </template>

      <!-- GRID ANIDADA -->
      <template v-slot:expanded-item="{ item }">
        <td :colspan="countHeadersDetaills" class="pa-3 under-sec-flat">
         <slot name="slot-grid-anidada" :item="item"></slot>
        </td>
      </template>
      <!-- COLUMNA DE ACCIONES -->
      <template v-slot:[`item.actions`]="{ item }">
        <div class="container__comp--btn">
          <BtnGrid
            v-for="(boton,index) in BtnAcciones" :key="'dos'+index"
            :typebtn="boton.componente"
            :item="item"
            :HoverMensaje="boton.mensaje"
            :tabla="tabla"
            :oPermisos="aConfiguracion.permisos"
            @btnActionEvento="btnActionEvento">
          </BtnGrid>
        </div>
      </template>
      <!-- FOOTER DE GRID GENERAL -->
      <template v-slot:footer>
        <v-divider ></v-divider>
        <v-row class="pa-0 ma-0 justify-start align-center mt-2">
            <v-col cols="12" sm="12" md="6" lg="4" class="col-nopadding cont-shadown-up col-registros">
            <v-row justify-center class="">
                <v-col sm="6" lg="6" cols="6" class="d-flex justify-center align-center col-nopadding">
                <v-chip class="ma-2 " text-color="black" color="white">
                    <span class="text-min text-primary">Registros Totales</span>  
                    <div class="resaltado"><span> &nbsp;{{totalRegistros}} </span>  </div>
                </v-chip>
                </v-col>
                <v-col sm="5" lg="6" cols="5" class="d-flex justify-center align-center py-2 px-2">
                    <p class="mr-1 text-min text-primary text-registros">No. Registro</p>
                    <v-select style="max-width: 80px;" class="text-min text-primary select-registros" 
                    v-model="itemsPerPagina" :items="itemsPagina" 
                    dense outlined hide-details height="30px" >
                    </v-select>
                </v-col>
            </v-row>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="12" md="5" lg="6" xs="12" class="d-flex justify-center align-center cont-shadown-up col-pagination">
            <v-pagination
                v-model="paginaselect"
                :circle="false"
                :length="pageLength"
                :total-visible="pageTotalVisible"
                :page="paginaselect"
                class=""
                >
            </v-pagination>
            </v-col>
        </v-row>
      </template>
    </v-data-table>
   
    <formulario 
      v-if="bActiveNew"
      :key="keyForm"
      v-model="showNew"
      :tableName="tabla"
      @saveOK="refrescarItems"
      :modNuevo="formNew"
      :oItem.sync="itemEdit"
      :titulo="tabla">
    </formulario>

    <!-- COMPONENTE PARA VISUALIZAR REPORTE -->
    <VistaDetalle
      v-if="detalleVer"
      v-model="detalleVer"
      :oParent.sync="oProps"
      :sNameDetails="Detalles"
      :sNameTablePrin="tabla"
      :sIdCampo="sIdTable"
      :aParametros="aParametros"
      :bSubdominioTemporal="bSubdominioTemporal">
    </VistaDetalle>
    <!-- BOTONES DE GRID -->
    <ModalAddUser
      v-if="bShowAdd"
      :key="keyForm + 1"
      :bShowAddUser="bShowAdd"
      :sTable="ClientesXUsuarios"
      :oParent.sync="oProps"
      :sTitle="'Añadir Usuarios'"
      @input="setModalFalse()">
    </ModalAddUser>
    <FormColor
      v-if="bShowColor"
      :bShowMdlColor="bShowColor"
      :titulo="'Configura el color de tu cliente'"
      :oDataParent.sync="oProps"
      :sTable="tabla"
      @input="setModalFalse()">
    </FormColor>
    <FormMapa
      v-if="bShowMapa"
      :bShowMap="bShowMapa"
      :oDataParent.sync="oProps"
      @input="setModalFalse()">
    </FormMapa>
     <!--slot name="slot-dialog-firmas" :oItem="oProps" :isModal="bMostrarFirmas" :onCerrarDialog="setModalFalse()"></slot!-->
    <DialogFirmas
      v-if="bMostrarFirmas"
      :bMostrarModal="bMostrarFirmas"
      :oDataParent.sync="oProps"
      :sNombreTabla="tabla"
      :sIdCampo="sIdTable"
      @input="setModalFalse()"
    />

    <DialogImagenes
      v-if="bMostrarImagenes"
      :bMostrarModal="bMostrarImagenes"
      :oDataParent.sync="oProps"
      :sNombreTabla="tabla"
      :sIdCampo="sIdTable"
      @input="setModalFalse()"
    />

    
    
    
    <!-- Esta esla forma en que funcionaba el 
    componenete anterio pasando la informacion 
    necesaria para obtener la informacion de reporte -->
    <!-- <SectDetails
      v-if="bShowMdlDet"
      :bShowDetails="bShowMdlDet"
      :oParent.sync="oProps"
      :sNameDetails="Detalles"
      :sNameTablePrin="tabla"
      :bShowMap="bShowMapDetails"
      @input="setModalFalse()">
    </SectDetails> -->
    <ExportModal
       v-if="bActiveExport"
      :bShowExport="bShowEx"
      :sTable="tabla"
      :TypeExport="sPropsType"
      :oFiltro="getoParamsFiltro"
      :nPage="nPropPageSel"
      :nPerPage="nPropsItemPerPage"
      :bAllExport="bExportAll"
      :aInfoTBDetalleExp="aInfoTBDetalleExp"
      :bSubdominioTemporal="bSubdominioTemporal"
      @input="setModalFalse()">
    </ExportModal>
  </div>
  <div class="no-acceso" v-else-if="!cargando && aConfiguracion.permisos.priv_access == '0'">
    <v-sheet color="dark" class="cont-tabla">
    <v-skeleton-loader
      :loading="false"
      class="mx-auto"
      max-width="100%"
      type="table-heading, table-thead, table-tbody, table-tfoot"
    >
    </v-skeleton-loader>
    <div class="mensaje">
      <div class="cont-icon">
        <v-icon size="40px" class="icon">mdi-lock-off-outline</v-icon>
      </div>
      <div class="texto-mensaje pa-3">
        <span class="text-primary font-size-1">
          No cuentas con permisos para ver esta aplicación.
        </span>
      </div>
    </div>
  </v-sheet>
  </div>
</template>
<script>
import FilterSecction from '@/components/Grid/Filter_Component/FilterSecction.vue'
import axiosServices from '@/services/axiosServices.js'
import BtnEdit  from '@/components/Grid/Btn_Component/BtnEdit.vue'
import BtnColor  from '@/components/Grid/Btn_Component/BtnColor.vue'
import BtnDetalleCliente  from '@/components/Grid/Btn_Component/BtnDetalleCliente.vue'
import BtnAddUser  from '@/components/Grid/Btn_Component/BtnAddUser.vue'
import BtnMapa  from '@/components/Grid/Btn_Component/BtnMapa.vue'
import BtnFirmas  from '@/components/Grid/Btn_Component/BtnFirmas.vue'
import BtnImagenes  from '@/components/Grid/Btn_Component/BtnImagenes.vue'
import formulario from '@/components/Form/Form.vue'
import ModalAddUser from '@/components/Grid/Btn_Component/FormAddUser.vue'
import ExportModal from '@/components/ExportExcel.vue'
import FormColor from '@/components/Grid/Btn_Component/FormColorCliente.vue'
import FormMapa from '@/components/Grid/Btn_Component/FormMapa.vue'
import editorhtml from "@/components/Grid/ComponentesCelda/CeldaHtmlEditor.vue"
import campoSwitch from '@/components/Grid/ComponentesCelda/CeldaSwitch.vue'
import campoSiNo from '@/components/Grid/ComponentesCelda/CeldaSiNo.vue'
import texto from '@/components/Grid/ComponentesCelda/CeldaTexto.vue'
import datetext from '@/components/Grid/ComponentesCelda/CeldaDateText.vue'
import numero from '@/components/Grid/ComponentesCelda/CeldaNumero.vue'
import selector from '@/components/Grid/ComponentesCelda/CeldaSelect.vue'
import dateRango from '@/components/Grid/ComponentesCelda/CeldaDateRango.vue'
import campoBuenoRegularNA from '@/components/Grid/ComponentesCelda/CeldaBuenoRegularNA.vue'
import correo from '@/components/Grid/ComponentesCelda/CeldaCorreo.vue'
import campoQR from '@/components/Grid/ComponentesCelda/CeldaQR.vue'
import selectorMultiple from '@/components/Grid/ComponentesCelda/CeldaSelectorMultiple.vue'
import archivo from '@/components/Grid/ComponentesCelda/CeldaArchivo.vue'
import activoInactivo from '@/components/Grid/ComponentesCelda/CeldaActivoInactivo.vue'
import VistaDetalle from '@/components/Grid/Detalle/VistaDetalle.vue'
import BtnGrid from '@/components/Grid/Btn_Component/BtnGrid.vue'
import DialogFirmas from '@/components/Grid/Btn_Component/DialogFirmas.vue'
import DialogImagenes from '@/components/Grid/Btn_Component/DialogImagenes.vue'
import BtnBuildCorreos  from '@/components/Grid/Btn_Component/BtnBuildCorreos.vue'

export default {
  name:"GridComponent",
  components: {
    FilterSecction,
    BtnEdit,
    BtnColor,
    BtnDetalleCliente,
    BtnAddUser,
    BtnMapa,
    formulario,
    ModalAddUser,
    ExportModal,
    FormColor,
    FormMapa,
    editorhtml,
    campoSwitch,
    campoSiNo,
    texto,
    datetext,
    numero,
    selector,
    dateRango,
    correo,
    selectorMultiple,
    archivo,
    activoInactivo,
    VistaDetalle,
    BtnGrid,
    BtnFirmas,
    DialogFirmas,
    campoBuenoRegularNA, 
    campoQR,
    BtnImagenes,
    DialogImagenes,
    BtnBuildCorreos
  },
  props: {
    tabla: {                  // Nombre de la tabla a trabajar 
      type:String,
      default:''
    },
    Detalles: {               //  Visualiza el detalle de la informacion se hace con el icono ojito
      type: String,
      default: ''
    },
    bActiveFilter: {          // Activa el Boton de Filtro
      type: Boolean,
      default: false
    },
    bActiveExport: {          // Activa el Boton de exportar
      type: Boolean,
      default: false
    },
    bActiveSearch: {          // Activa el buscar rapido - para buscar dentro de la infor visualizada
      type: Boolean,
      default: true
    },
    bActiveNew: {             // Actia el boton para de Nuevo en base a la tabla de FormularioTablas
      type: Boolean,
      default: true
    },
    bSingleSelect: {          
      type: Boolean,
      default: false
    },
    bShowSelect: {
      type: Boolean,
      default: false
    },
    bShowMapDetails: {
      type: Boolean,
      default: false
    },    
    sIdTable: {                // nombre del pimary key de la tabla detalle
      type: String,
      default: ''
    },
    bGridAnidada: {           // indica si se habilita la grid anidada
      type: Boolean,
      default: false
    },
    bGridDetalle: {           // Indica si se comportará como grid detalle o no
      type: Boolean,
      default: false
    },
    nIdValor: {               // Es el valor que se pasa al campo para filtrar, po lo general es un variable que modifica una grid en su select
      type: Number,           // la cual emite el evento idSelect 
      default: 0
    },
    sCampoFiltro: {           // indica el Nombre del campo para filtrar si es una tabla detall 
      type: String,
      default: ''
    },
    aInfoTBDetalleExp: {
      type: Array,
      default: () => []
    },
    aParametros: {
      type: Array,
      default: () => [] // Listado de string que continene parametros adicionales que se usan para realizar filtrado, este listado son campos existentes.
    },
    bSubdominioTemporal:{ //Indica que se activa puente con subdominio temporal
      type: Boolean,
      default: false
    },
    nItemsPerPagina: {
      type: Number,
      default: 5
    },

  },
  data() {
    return {
      selected          : [],                   // v-model de la tabla
      pageTotalVisible  : 10,                   // Indica el numero de indicadores visibles en el paginados 
      paginaselect      : 1,                    // indica la pagina seleccionada
      itemsPagina       : [5,10, 20, 50],       // indica los item de numero de registros por pagina 
      itemsPerPagina    : this.nItemsPerPagina, // indica el item seleccionado en el control de registros por pagina 
      quitSearch        : '',                   // valor de la busqueda rapida  
      filtrarActive     : false,                // si esta activo la zona de filtros dinamicos
      cargando          : false,                // indica si se esta cargando algo para mostrar el progres en la grid
      totalRegistros    : 0,                    // indica el total de registros que se cargan en la grid
      headers           : [],                   // Array de objetos que se cargan en la cabecera de la grid
      dataItems         : [],                   // Data que se carga en la grid
      filtradoEstate    : false,                // estado de que si esta filtrando o no la grid    
      BtnAcciones       : [],                   // los botones que se muestran en la seccion (columna) de acciones  
      showNew           : false,                // empleado para mostrar el form de new
      formNew           : true,                 // indica en que modo se abre el formulario
      itemEdit          : {},                   // Item a ser editado
      keyForm           : 0,                    // indice para re dibujar el form por cada apertura
      bShowAdd          : false,                // Habilita el modal de asignacion de usuarios
      oProps            : {},                   // Objeto donde se regresa el item de cada elemento donde se preciona un boton
      bShowEx           : false,                // Habilita el modal de exportación
      sPropsType        : '',                   // Tipo archvio de exportación
      oParamsFiltro     : '',                   // Parametros de filtro para exportación
      nPropPageSel      : 1,                    // Parametro de pagina seleccionada para exportar
      nPropsItemPerPage : 5,                    // Parametro de item por pagina que se exportara
      bExportAll        : false,                // Parametro para exportar toda la informacion o solo lo que se esta visualizando
      bShowColor        : false,                // Habilita el modal de asignación de color
      bShowMdlDet       : false,                // Id de la tabla que se esta presentando
      detalleVer        : false,                // Model para abrir detalle
      bShowMapa         : false,                // Habilita el modal del mapa con la ubicacion de lat y long 
      bMostrarFirmas    : false,                // Habilita el dialog de firmas
      bMostrarImagenes    : false,                // Habilita el dialog de Imagenes
      countHeadersDetaills: 0,               // Cuenta la cantidad de headers de la grid detalle
      aDataParaAnidada : [], // Data almacenada cuandose habilite la gridanidada,
      aFormatHeaders: [],
      aConfiguracion:  {
        permisos: {
          priv_access: '2',
          priv_insert: '2',
          priv_delete: '2',
          priv_update: '2'
        }
      } // Contiene la configuracion de la tabla y permisos
    } // fin return data
  },  // fin data
  async mounted() {
    this.cargando = true
    await this.refrescarItems()
    await this.getHeaders()
    this.cargando = false
  },
  methods: {
    async refrescarItems(){
      this.cargando = true
      if(this.filtradoEstate){
        await this.getConsultaFiltro()
      }else{
        await this.getItems()
        await this.getTotalRegistros()
        await this.getBtnAcciones()
      }
      this.cargando= false
    },
    async getBtnAcciones(){
      this.cargando = true
      let sParams = `sTable=${this.tabla}`
      if(this.bSubdominioTemporal){
        sParams = sParams + `&subdominiot=${this.$store.state.sSubdominioTemporal}`
      }
      const aAccionesBtn = await axiosServices.getConfiguration('/pGrids_acciones',true, sParams)
      this.BtnAcciones = aAccionesBtn.aData
      this.formatHeader()
    },
    async getTotalRegistros(){
      this.cargando = true
      var sParams = ''
      var sPeticion = ''
      if(this.bGridDetalle){
        sParams = `sTable=${this.tabla}&sIdForeignKey=${this.sCampoFiltro}&nIdValue=${this.nIdValor}`
        sPeticion = '/dGrids_totalregistros'
      }else{
        sParams = `sTable=${this.tabla}`
        sPeticion = '/pGrids_totalregistros'
      }
      if(this.bSubdominioTemporal){
        sParams = sParams + `&subdominiot=${this.$store.state.sSubdominioTemporal}`
      }
      const aTotalRegistro = await axiosServices.getConfiguration(sPeticion, true, sParams)
      this.totalRegistros = aTotalRegistro.aData[0]['Cantidad']
      // this.cargando = false;
    },
    async getHeaders(){
      this.cargando = true
      let sParams = `sTable=${this.tabla}&nPerfil=${this.$store.state.idPerfil}`

      if(this.bSubdominioTemporal){
        sParams = sParams + `&subdominiot=${this.$store.state.sSubdominioTemporal}`
      }
      const aConf = await axiosServices.getConfiguration('/pGrids_permisos', true, sParams)
      this.aConfiguracion = aConf.aData
      const aHeaders = await axiosServices.getConfiguration('/pGrids_headers', true, sParams)
      this.headers = aHeaders.aData
      this.cargando = false

      this.formatHeader()
    },
    async getItems(){
        this.cargando = true
         var sParams = ''
        var sPeticion = ''
        this.dataItems = []
        if(this.bGridDetalle){
          sParams = 'nopagina='+ this.paginaselect + '&' + 'noregistros=' + this.itemsPerPagina + `&sTable=${this.tabla}` + `&sIdForeignKey=${this.sCampoFiltro}` + `&nIdValue=${this.nIdValor}`
          sPeticion = '/dGrids'
        }else{
          sParams = 'nopagina='+ this.paginaselect + '&' + 'noregistros=' + this.itemsPerPagina + `&sTable=${this.tabla}`
          sPeticion = '/pGrids'
        }
        if(this.bSubdominioTemporal){
          sParams = sParams + `&subdominiot=${this.$store.state.sSubdominioTemporal}`
        }
        axiosServices.getConfiguration(sPeticion, true ,sParams).then(adataItems =>{
          this.cargando = false;
          this.dataItems = adataItems.aData
          if(this.bGridDetalle && this.dataItems.length > 0){
            let entriData =  Object.entries(this.dataItems[0])
            let entriNum = entriData.length
            if(entriNum == 0){
              this.countHeadersDetaills = 8
            }else{
              this.countHeadersDetaills = entriNum + 1
            } 
          }
        })
    },
    exportar(sTypeFile, bAll = false){
      this.bShowEx = true;
      this.sPropsType = sTypeFile;
      this.bExportAll = bAll;
    },
    exportinfo(){
      this.$analytics.logEvent('Grid-'+this.tabla+'', { exportarGrid:1})
    },
    nuevoItem(){
      this.keyForm += 1 
      this.itemEdit = {}
      this.formNew  = true
      this.showNew = !this.showNew
    },
    filtrar(){
      this.filtrarActive = !this.filtrarActive
      this.$analytics.logEvent('Grid-'+this.tabla+'', { filtrarGrid:1})
    },
    getFiltrarInicio(aFilro){
      // como se dio click en ejecutar del filtro reinicio la pagina el nopagina
      this.paginaselect = 1
      this.getConsultaFiltro(aFilro)
    },
    async getConsultaFiltro(aFiltro){
      this.cargando = true
      let sParams  = ''
      let endPoint = ''
      this.oParamsFiltro = (!this.filtradoEstate) ? aFiltro : this.oParamsFiltro ;
      if(this.bGridDetalle) {
        sParams = 'nopagina='+ this.paginaselect + '&' + 'noregistros=' + this.itemsPerPagina +  '&' + 'filtros=' + JSON.stringify(this.oParamsFiltro) + `&sTable=${this.tabla}` + 
                  `&sIdForeignKey=${this.sCampoFiltro}&nIdValue=${this.nIdValor}`
        endPoint = '/dGrids_filtrar'
      } else {
        sParams = 'nopagina='+ this.paginaselect + '&' + 'noregistros=' + this.itemsPerPagina +  '&' + 'filtros=' + JSON.stringify(this.oParamsFiltro) + `&sTable=${this.tabla}`
        endPoint = '/pGrids_filtrar'
      }
      if(this.bSubdominioTemporal){
        sParams = sParams + `&subdominiot=${this.$store.state.sSubdominioTemporal}`
      }
      const aFilter = await axiosServices.getConfiguration(endPoint, true, sParams)
      //console.log(aFilter.aData)
      this.totalRegistros = aFilter.aData['TotalRegistros'][0].Cantidad
      this.dataItems = aFilter.aData['Data']
      this.cargando = false;
      this.filtradoEstate = true
    },
    LimpiarFiltros(){
      this.oParamsFiltro = "";
      this.filtradoEstate = false
      this.refrescarItems()
    },
    btnActionEvento(oEvento){
      // manejo de evento de los tonones de action 
      // lleva un objeto con {btnname: '' , item: ''
      // console.log('oEvento.btnName',oEvento.btnName )
      switch (oEvento.btnName) {
        case 'btnEdit': {
          this.keyForm += 1
          this.formNew = false
          this.itemEdit = {...oEvento.item}
          this.showNew = !this.showNew
          break;
        }
        case 'btnAddUser': {
          this.keyForm += 1
          this.bShowAdd = true;
          this.oProps = oEvento.item;
          break;
        }
        case 'btnColor': {
          this.keyForm += 1;
          this.bShowColor = true;
          this.oProps = oEvento.item;
          break;
        }
        case 'btnDetails': {
          this.keyForm += 1;
          // this.bShowMdlDet = true;
          this.oProps = oEvento.item;
          this.detalleVer = true //Muestra el componente lateral de la vista de detalle
          this.$analytics.logEvent('Grid-'+this.tabla+'', { verDetalle:1}) //Analitics
          break;
        }
        case 'btnMapa': {
          this.keyForm += 1;
          this.oProps = oEvento.item;
          this.bShowMapa = true  //Muestra el componente lateral de la vista de detalle
          break;
        }
        case 'btnFirmas': {
          this.oProps = oEvento.item;
          this.bMostrarFirmas = true 
          break;
        }
        case 'btnImagenes': {
          this.oProps = oEvento.item;
          this.bMostrarImagenes = true 
          break;
        }
        case 'btnBuildCorreos': {
          this.keyForm += 1
          this.itemEdit = {...oEvento.item}
          this.$router.replace({name: 'buildCorreos', params: { idCorreos: this.itemEdit.id_correos, oCorreo: this.itemEdit}})
          break;
        }
        default: {
          break;
        }
      }
    },
    setModalFalse(bNewValue) {
      this.bShowAdd = bNewValue;
      this.bShowEx = bNewValue;
      this.bShowColor = bNewValue;
      this.bShowMdlDet = bNewValue;
      this.bShowMapa =  bNewValue;
      this.bMostrarFirmas =  bNewValue;
      this.bMostrarImagenes = bNewValue;
    },
    getColor( item ,NombreCampo) {
      return item[`Color_${NombreCampo}`]
    },
    getAlineacion(alineacion){
      switch(alineacion){
        case '1':
            return 'text-center'
        case '2':
            return 'text-right'
        case '3':
            return 'text-left'
        case '4':
            return 'text-justify'
        default:
            return 'text-center'
      }
    },
    formatHeader(){
      this.aFormatHeaders =  []
      // le doy formato al header añadiendo align: 'center', sortable: true OJO todo es un texto en value
      // valido si el ultimo elemento es value -- actinos para no meterlo  qque sea mayor que 0
      // if(this.BtnAcciones.length > 0 && this.headers.length > 0 && Headers[Headers.length -1].value !== 'actions'){
      if(this.BtnAcciones.length > 0 && this.headers.length > 0 && (this.aConfiguracion.info.pos_botones=='1' || this.aConfiguracion.info.pos_botones=='2')) {
        this.aFormatHeaders.push({value:'actions', text:'ACCIONES',filtro:'0',visible: '1', width:'200', tipo:'',InnerJoin:'',align:'center',divider:true, class:'titlesdark--text font-weight-bold' })
      }

       this.headers.forEach(oValor => {
        this.aFormatHeaders.push(oValor)
       })
      let aBorrar = []
      // const sEXP_Color = '/^Color_*/'
      this.aFormatHeaders.forEach((value, Key) => {
        if(value.visible == '0'){
          aBorrar.push(Key)
        } 
          this.aFormatHeaders[Key].align = 'center'
          this.aFormatHeaders[Key].sortable = true
          this.aFormatHeaders[Key].divider = true
          this.aFormatHeaders[Key].class = 'titlesdark--text font-titulo' 
      })

      if(this.BtnAcciones.length > 0 && this.headers.length > 0 && (this.aConfiguracion.info.pos_botones=='0' || this.aConfiguracion.info.pos_botones=='2')) {
        this.aFormatHeaders.push({value:'actions', text:'ACCIONES',filtro:'0',visible: '1', width:'200', tipo:'',InnerJoin:'',align:'center',divider:true, class:'titlesdark--text font-weight-bold' })
      }

      aBorrar.forEach( (value, key) =>  { 
        // pora cada borrado toca restar el key del value
        this.aFormatHeaders.splice(value - key,1)
      })
    },
  },
  computed: {
    getoParamsFiltro(){
      if (this.oParamsFiltro != ""){
        return  this.oParamsFiltro
      } else {
        return {}
      }
    },
    
    pageLength(){
      return Math.ceil(this.totalRegistros/this.itemsPerPagina)
    }
  },
  watch: {
    paginaselect() {
      this.refrescarItems()
      this.nPropPageSel = this.paginaselect;
    },
    itemsPerPagina() {
      this.nPropsItemPerPage = this.itemsPerPagina;
      this.paginaselect = 1;
      this.refrescarItems()
    },
    selected(aNewValue){

      if(aNewValue.length == 0){
        this.$emit('idSelect', 0)
        this.$emit('nameField', '')
        this.$emit('oItemSeleccionado', {})
      }
      aNewValue.forEach(oElement => {
         if (oElement.hasOwnProperty(`id${this.tabla}`)){
          this.$emit('nameField', Object.keys(oElement)[0])
          this.$emit('idSelect', parseInt(oElement[`id${this.tabla}`]))
         }else{
          this.$emit('nameField', this.sIdTable)
          this.$emit('idSelect', parseInt(oElement[this.sIdTable]))
         }
        this.$emit('oItemSeleccionado', oElement)
      })
    },
    nIdValor(sNewValue, sOldValue) {
      if (sNewValue != sOldValue) {
        this.refrescarItems()
      }
    }
  },
}
</script>

<style lang="scss" >
.rounded-basic{
    border-radius: 13px!important;
  }
  .v-data-table-header {
    background-color : #F5F6FA!important;
  }
  /* .v-icon.notranslate.fas.fa-check-square.theme--light{ */
  .v-data-table__checkbox.v-simple-checkbox i{
    font-size: 14px !important;
  }
  .HeaderOculto {
    display: none;
  }
  .v-data-table td {
    font-size: 0.7rem !important;
  }
  
  // Ajuste del tamaño del icono de busqueda
  .v-input__icon--append{
    i{
      font-size: 20px!important;
    }
  }
 
  .v-pagination__item{
    box-shadow: none!important;
    color: var(--v-colortextinfo-base)!important;
  }
  // Ajuste de color en la paginación de la grid
  .v-pagination__navigation{
    box-shadow: none!important;
  }
  .v-pagination__item--active{
    color: white!important;
  }
  .container-search{
    font-size: 0.8em!important;
  }
  .cont-acciones{
    margin-left: auto!important;
  }
  .font-titulo{
    font-weight: 500!important;
    line-height: 1.2em;
    padding-top: 10px!important;
    padding-bottom: 10px!important;
  }
  .col-nopadding{
    padding: 0!important;
  }
  .resaltado{
    border: solid 1px var(--v-primary-base);
    text-align: center;
    line-height: 0.8em;
    display: flex;
    justify-items: center;
    align-items: center;
    padding: 6px 4px;
    border-radius: 3px;
    font-size: 1em;
    margin-left: 4px;
    color: var(--v-primary-base);
  }
  .container {
    padding: 0px 16px!important;
  }
  .select-registros .v-input__slot{
    min-height: 30px!important;
  }
  .select-registros .v-input__append-inner{
    margin-top:0px!important;
  }
  .text-registros{
    margin-bottom: 0px!important;
  }
  .col-pagination{
    padding: 10px;
  }
  .container__comp--btn{
    display: flex;
    justify-content: center;
  }
  .item-action{
    min-width: auto!important;
  }
  .table-app{
    // border:solid red;
    overflow-y: scroll!important;
    overflow-x: scroll!important;

  }
  .no-acceso{
    margin: 0;
    height: 70vh;
    position: relative;
    text-align: center;
    .mensaje{
      position: absolute;
      margin: 0 auto;
      top: 20%;
      width: 100%;
    }

    .cont-icon{
      background: linear-gradient(0deg, rgba(218,218,218,1) 0%, rgba(238,238,238,1) 100%);
      width: 80px;
      height: 80px;
      border: solid #FFF 1px;
      box-shadow: 0 0 10px 3px rgba($color: #000000, $alpha: .2);
      margin: 0 auto;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      i{
      font-size: 55px;
      color:var(--v-primary-base);
      }
    }
    .texto-mensaje{
      border-radius: 10px;
      border: solid #FFF 1px;
      margin-top:5px;
      background: linear-gradient(0deg, rgba(218,218,218,0.6) 0%, rgba(238,238,238,0.6) 100%);      
    }
  }

</style>