<template>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512.001 512.001" style="enable-background:new 0 0 512.001 512.001;" xml:space="preserve" width="512px" height="512px" class=""><g><g>
	<g>
		<path d="M271.029,0c-33.091,0-61,27.909-61,61s27.909,61,61,61s60-27.909,60-61S304.12,0,271.029,0z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#15ADC7"/>
	</g>
</g><g>
	<g>
		<path d="M337.621,122c-16.485,18.279-40.096,30-66.592,30c-26.496,0-51.107-11.721-67.592-30    c-14.392,15.959-23.408,36.866-23.408,60v15c0,8.291,6.709,15,15,15h151c8.291,0,15-6.709,15-15v-15    C361.029,158.866,352.013,137.959,337.621,122z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#15ADC7"/>
	</g>
</g><g>
	<g>
		<path d="M144.946,460.404L68.505,307.149c-7.381-14.799-25.345-20.834-40.162-13.493l-19.979,9.897    c-7.439,3.689-10.466,12.73-6.753,20.156l90,180c3.701,7.423,12.704,10.377,20.083,6.738l19.722-9.771    C146.291,493.308,152.354,475.259,144.946,460.404z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#15ADC7"/>
	</g>
</g><g>
	<g>
		<path d="M499.73,247.7c-12.301-9-29.401-7.2-39.6,3.9l-82,100.8c-5.7,6-16.5,9.6-22.2,9.6h-69.901c-8.401,0-15-6.599-15-15    s6.599-15,15-15c20.09,0,42.332,0,60,0c16.5,0,30-13.5,30-30s-13.5-30-30-30c-70.446,0-3.25,0-78.6,0    c-7.476,0-11.204-4.741-17.1-9.901c-23.209-20.885-57.949-30.947-93.119-22.795c-19.528,4.526-32.697,12.415-46.053,22.993    l-0.445-0.361L89.016,281.03L174.28,452h25.248h146.501c28.2,0,55.201-13.5,72.001-36l87.999-126    C515.929,276.799,513.229,257.601,499.73,247.7z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#15ADC7"/>
	</g>
</g></g> </svg>
</template>
<script>
  export default {
    name:"customerIcon"
  }
</script>

<style scoped>

</style>