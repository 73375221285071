<template>
	<div class="imprimir">
		
		<v-list-item one-line v-for="(oInfo, i) in aInfo" :key="i">
			<h4 class="text-white text-medium">{{oInfo.index}}:&nbsp;</h4><br>
			<MapaView v-if="oInfo.value!=''" ref="map"
				:lat="oInfo.value.split(',')[0]"
				:lng="oInfo.value.split(',')[1]"
				:dimencion="dimensionMapa"
				:bControlZoom="false">
			</MapaView>
		</v-list-item>
	</div>
</template>
<script>
import MapaView from "@/components/Mapas/mapLocalizar.vue"

export default {
	name:"Mapa",
	components: {
    MapaView,
  },
	props:{
		aInfo: {},
		nIdParent: {
			type: String,
			default: ''
		},
		sNameDetails: {
			type: String,
			default: ''
		},
		bSubdominioTemporal:{ //Indica que se activa puente con subdominio temporal
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			dimensionMapa : { alto: '500px' , ancho  : '98%'}
		}
	},
}
</script>
<style lang="sass" scoped>
</style>