
export default {
  getCurrentPosition () {

    // se regresa un objeto con la propiedad coords y dentro estan la propiedades  latitude y longitude
    // se llama  this.oLocation = await GPSLocation.getCurrentPosition()
     
    if (navigator.geolocation) {
      const oOptions = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      }
      return new Promise(
        (resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject, oOptions)
      )
    } else {

      return new Promise(
        resolve => resolve({})
      )
    }
  }
  

  /* se llama dentro de un try para capturar el erro de si no se da permios

      try {
      this.oLocation = await GPSLocation.getCurrentPosition()

    } catch (error) {
        this.$store.dispatch('notificaciones/notificaciones/SetMensaje','ERROR: GPS inactivo o No se tienen permiso para obtener la Ubicabion. Valide y refresque la Pantalla' )
        this.$store.dispatch('notificaciones/notificaciones/ShowNotificacion',true)
        this.bErrorPermisos = false
    }

    */
  
}