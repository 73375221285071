<template>
  <v-dialog v-model="bShowMdlColor" persistent max-width="600px">
    <v-card>
      <v-toolbar
        color="headformsdark"
        height="45px"
        elevation="0"
        dense
        dark>
        <v-spacer></v-spacer>
        <span class="mdlHeadForms">{{titulo}}</span>
        <v-spacer></v-spacer>
        <v-btn 
          color="btncolor-dark"
          @click="CloseDialog()"
          icon
          small
          dark
          outlined>
          <v-icon size="14px">fa fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-progress-linear
        :active="progressbar"
        indeterminate
        color="btncolor">
      </v-progress-linear>
      <v-container>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <span class="titleslight--text font-weight-medium">Cliente</span>
            <span class="titleslight--text font-weight-bold ml-1">*</span>
            <br>
            <span class="font-weight-bold">{{ sNameParent }}</span>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <span class="titleslight--text font-weight-medium">Elige el color</span>
            <span class="titleslight--text font-weight-bold ml-1">*</span>
            <v-text-field
              v-model="txtColor"
              :rules="fieldEmpty"
              outlined
              dense
              required>
              <template v-slot:append-outer>
                <v-btn text icon color="btncolor" class="mt-n2 pa-0" @click="bMdlColor=true">
                  <v-icon>fa-palette</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <small class="d-flex justify-end"><span class="titleslight--text font-weight-medium">* Campo Obligatorio</span></small>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark outlined color="btncolor" @click="limpiarCampos()" :disabled="btnEnable">Limpiar</v-btn>
        <v-btn dark outlined color="btncolor" @click="saveColor()" :disabled="btnEnable">Guardar</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="bMdlColor" persistent max-width="300px">
      <v-card>
        <v-container>
          <v-row align="center" justify="center">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
              <span class="titleslight--text font-weight-medium">Seleccionar color</span>
              <span class="titleslight--text font-weight-bold ml-1">*</span>
              <v-color-picker
                v-model="color">
              </v-color-picker>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark small outlined color="btncolor" @click="closePiker()">Cancelar</v-btn>
          <v-btn dark small outlined color="btncolor" @click="setColor()">Guardar</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog> 
</template>
<script>
import axiosServices from '@/services/axiosServices.js';
export default {
  name: "FormColorCliente",
  props: {
    bShowMdlColor: {
      type: Boolean,
      default: false
    },
    titulo: {
      type: String,
      default: ''
    },
    oDataParent: {
      type: Object,
    },
    sTable: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      nIdParent: '',
      sNameParent: '',
      AuxColor: '',
      txtColor: '',
      btnEnable: false,
      bMdlColor: false,
      type: 'hex',
      progressbar: true,
      fieldEmpty : [v => !!v],
    }
  },
  methods: {
    validateInputs() {
      let bEnable = false;
      if (this.txtColor !== "") {
        bEnable = true;
      }
      return bEnable;
    },
    getColor() {
      let sParams = `sTable=${this.sTable}&nIdParent=${this.nIdParent}`;
      axiosServices.getConfiguration('/setColorCliente', true, sParams)
      .then(aGetColor => {
        if (aGetColor.nStatusCode == 200) {
          if (aGetColor.aData.Color !== "") {
            this.txtColor = aGetColor.aData.Color;
          }
          this.progressbar = false;
        }
      });
    },
    saveColor() {
      if (this.validateInputs()) {
        let sParams = `sTable=${this.sTable}`;
        let oBody = {
          "nIdParent": this.nIdParent,
          "Color": this.txtColor
        };
        this.progressbar = true;
        this.btnEnable = true;
        axiosServices.putConfiguration('/setColorCliente', oBody, true, sParams)
        .then(aResponse => {
          if (aResponse.nStatusCode == 214) {
            this.btnEnable = true;
            this.$swal({
              timer: 1500, 
              icon:`${aResponse.sStatus}`, 
              title:`${aResponse.sMessage}`, 
              showConfirmButton: false
            });
            this.CloseDialog();
          } else {
            this.btnEnable = false;
            this.$store.dispatch('notificaciones/SetMensaje', "Estamos presentando problemas, por favor intente mas tarde.");
            this.$store.dispatch('notificaciones/SetColor','error')
            this.$store.dispatch('notificaciones/ShowNotificacion', true);
          }
        });
      } else {
        this.btnEnable = false;
        this.$store.dispatch('notificaciones/SetMensaje', "Campos vacios.");
        this.$store.dispatch('notificaciones/SetColor','error')
        this.$store.dispatch('notificaciones/ShowNotificacion', true);
      }
    },
    setColor() {
      this.txtColor = this.AuxColor;
      this.bMdlColor = false;
    },
    closePiker() {
      this.bMdlColor = false;
    },
    limpiarCampos(){
      this.oFormCampos = {}
    },
    CloseDialog() {
      this.txtColor = "";
      this.progressbar = true;
      this.btnEnable = false;
      this.$emit('input', false)
    },
  },
  computed: {
    color: {
      get () {
        return this[this.type];
      },
      set (v) {
        this.AuxColor = v[this.type];
      },
    }
  },
  watch: {
    bShowMdlColor(bNewValue) {
      if (bNewValue){
        this.nIdParent = this.oDataParent.idClientes;
        this.sNameParent = this.oDataParent.Nombre_Cliente;
        this.getColor();
      }
    }
  }
}
</script>