<template>
  <div>
    <span class="titleslight--text font-weight-medium">{{titulo}}</span>
    <span v-if="obligatorio" class="titleslight--text font-weight-medium ml-1">*</span>
    <v-text-field
      v-model="valor"
      single-line
      outlined
      clearable
      dense
      type="number"
      :disabled="bEditable"
      :error="esError"
      :rules="noVacio">
    </v-text-field>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
  export default {
    name: 'FormNumero',
    props: {
      value: {
      },
      titulo:{
        type:String,
        default:''
      },
      obligatorio:{
        type:Boolean,
        default: false
      },
      campo:{
        type:String,
        default : ''
      },
      editable: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        valor   : this.value,
        esError : false,
        noVacio : [v => !!v],
        bEditable : (this.editable == "0") ? true : false,
      }
    },
    mounted(){
    },
    methods: {

    },
    computed: {
      ...mapGetters(['getoFormError'])
    },
    watch: {
      valor(NewValor){
        this.esError = isNaN(NewValor) 
        this.$emit('input',NewValor)
        if(this.esError && this.valor != ''){
          this.esError = false
        }
      },
      value(NewValue){
        this.valor = NewValue
      },
      getoFormError(){
        this.esError = this.getoFormError[this.campo]
      }
    },
    
  }
</script>

<style scoped>
