<template>
<div>
  <v-navigation-drawer v-if="!$vuetify.breakpoint.xs"
    v-model="drawer" dark mini-variant mini-variant-width="54"
    permanent app class="nav-bar">
    <v-list-item class="py-0 px-2">
      <v-img max-width="45" src="@/assets/icons/logoseif.svg"></v-img>
    </v-list-item>
    <template v-slot:append v-if="getLogin">
      <v-list dense rounded>
        <v-list-item-group
          v-for="item in items"
          :key="item.idModuloPrincipal"> 
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-list-item v-on="on"
              rounded dense 
               @click="changeTo(item.idModuloPrincipal, item.Modulo, item.Path)">
                <v-list-item-icon>
                  <v-icon>{{ item.Icono }}</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </template>
            <span>{{ item.Modulo }}</span>
          </v-tooltip>
        </v-list-item-group>
        <v-list-item>
          <v-btn
            :to="'/DashBoard'"
            small icon dark 
            class="mx-1">
            <v-icon size="25px" dark class=" mx-1">mdi-home</v-icon>
          </v-btn>
        </v-list-item>
      </v-list>
      <v-menu
        offset-x :close-on-content-click="false"
        transition="slide-x-transition">
        <template v-slot:activator="{ on }">
          <v-list-item v-on="on">
            <v-list-item-avatar size="45">
              <v-img :src="imageURL"></v-img>
            </v-list-item-avatar>
          </v-list-item>
        </template>
        <v-list dense>
          <v-list-item @click="profile=true">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Perfil</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="changepass=true">
            <v-list-item-icon>
              <v-icon>mdi-key</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Cambiar Contraseña</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="logOut()">
            <v-list-item-icon>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Cerrar sesión</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <user v-model="profile"></user>
    <changepass v-model="changepass"></changepass>
    
  </v-navigation-drawer>
  <v-bottom-navigation v-if="$vuetify.breakpoint.xs"
    v-model="value" 
    max-width="100vw"
    class="navigator overflow-hidden mx-auto" 
    height="45px" 
    dark fixed 
    justify-center
    >
    <v-tabs show-arrows background-color="primary accent-4" center-active>
      <v-tab v-for="item in items" :key="'horizontal'+item.idModuloPrincipal" dark >
        <v-btn text 
          x-small min-width="50"
          @click="changeTo(item.idModuloPrincipal, item.Modulo, item.Path)">
          <span>{{ item.Modulo }}</span>
          <v-icon>{{ item.Icono }}</v-icon>
        </v-btn>
      </v-tab>
      <template>
        <v-btn :to="'/DashBoard'" small icon dark  class="mx-1">
        <span>Dash Board</span>
          <v-icon size="25px" dark class=" mx-1">mdi-view-dashboard-outline</v-icon>
        </v-btn>
      </template>
      <v-tab >
        <v-menu offset-y :close-on-content-click="false"
          transition="slide-y-transition">
          <template v-slot:activator="{ on }">
              <v-btn x-small min-width="35" v-on="on" rounded >
                <v-avatar color="primary" size="35">
                  <v-img :src="imageURL"></v-img>
                </v-avatar>
              </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="profile=true">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Perfil</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="changepass=true">
              <v-list-item-icon>
                <v-icon>mdi-key</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Cambiar Contraseña</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="logOut()">
              <v-list-item-icon>
                <v-icon>mdi-exit-to-app</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Cerrar sesión</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-tab>
    </v-tabs>
   

  </v-bottom-navigation>
</div>
  
</template>
<script>
import axiosServices from '@/services/axiosServices.js';
import { mapGetters } from 'vuex';
import user from "@/views/PerfilUsuario.vue";
import changepass from "@/views/CambiarContrasena.vue";
export default {
  name: 'modulos',
  components: {
    user,
    changepass
  },
  data() {
    return {
      value:1,
      profile: false,
      changepass: false,
      items: [],
      drawer: false,
      imageURL: 'https://files.monday.com/logos/5134504/thumb_square/techboss_logo_932700.png',
    }
  },
  mounted() {
    if (this.getLogin == true) {
      this.getImgUser();
      this.getModules();
    }
  },
  methods: {
    changeTo(idModuloPrincipal, Modulo, Path) {
      // console.log(idModuloPrincipal, Modulo);
      this.$store.dispatch('saveIdModule', idModuloPrincipal);
      this.$store.dispatch('saveTitleModule', Modulo);
      this.$router.replace({
        name: 'Inicio', 
        params: {
          Modulo: `${Path}`,
          nId: `${idModuloPrincipal}`,
          sTitle: `${Modulo}`
        }
      }).catch(err => {
        sessionStorage.err = err;
        this.$store.dispatch('saveIdModule', idModuloPrincipal);
        this.$store.dispatch('saveTitleModule', Modulo);
      });
    },
    getModules() {
      axiosServices.getConfiguration("/modules", true, `nIdPerfil=${this.getIdPerfil}`)
      .then(aGetModules => {
        this.items = aGetModules.aData;
      })
    },
    getImgUser() {
      axiosServices.getConfiguration("/saveimg", true, `nIdUser=${this.getIdUser}`)
      .then(aGetImg => {
        if (typeof aGetImg !== "undefined") {
          this.imageURL
          //this.imageURL = aGetImg.aData.path; // ESTA ES LA FUNCION ORIGINAL REVISAR LA IMAGEN DEL USUARIO
        }
        this.avatar = false;
      });
    },
    logOut() {
      let oBody = {
        "nIdUser": this.getIdUser,
      };
      axiosServices.putConfiguration("/logout", oBody, true)
      .then(aLogOut => {
        if (aLogOut.sStatus == 'success') {
          this.$store.dispatch('setLogin', false).then(() => {
            window.localStorage.clear();
            sessionStorage.clear();
            this.$router.replace({
              name: 'login',
            });
          });
        }
      });
    }
  },
  computed: {
    ...mapGetters(['getLogin', 'getIdUser', 'getIdDeparment', 'getIdPerfil'])
  },
  watch: {
    getLogin(bNewValue) {
      if (bNewValue == true) {
        this.getImgUser();
        this.getModules();
      }
    }
  }
}
</script>
<style lang="scss">

.bandera{
  border: solid red;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: -10px;
}
.nav-bar{
  background-image: linear-gradient(to bottom, #0E69B0,  #0E69B0 , #15adc7);
  border-radius: 0 20px 20px 0px;
}
// Navegador horizontal solo visible en XS
.navigator{
    color: var(--v-primaryb-base)!important;
    background-image: linear-gradient(to bottom, #0E69B0,  #0E69B0 , #15adc7);
    box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.4)!important;
    border-radius: 10px 10px 0px 0px;
    span{
      font-size: 1em;
    }
}
// Ajusta el desplazamiento del icono al dar click
.v-application--is-ltr .v-list-item__action:last-of-type:not(:only-child), .v-application--is-ltr .v-list-item__avatar:last-of-type:not(:only-child), .v-application--is-ltr .v-list-item__icon:last-of-type:not(:only-child) {
    margin-left: 0px!important;
}

.theme--dark.v-btn--active:hover::before, .theme--dark.v-btn--active::before{
  opacity: 0;
}
.v-slide-group__content {
    justify-content: center;
}
</style>å