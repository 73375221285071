<template name="component-name">
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 594 473" style="enable-background:new 0 0 594 473;" xml:space="preserve">
  <g id="Dashboard_2_">
    <g id="_x31__2_" transform="translate(-149.000000, -137.000000)">
      <g id="Path-5_2_">
        <path class="st0" style="fill: #FFFFFF;" d="M281.8,430.7c27-1.4,67.1-32.9,68.5-46.7c-1-9.5,2.5-49.9-2.3-53.9c-9.9-7.2-53.3-9-67.2-12.4
          c-0.2,0.7-43.2,6.4-46.7,7.5c0.9-11.9-2.7-41.8,3.6-50.7c14.9-24.9,79-19.4,85.8,8.5c2.3,12.4-2.7,39.8,17.9,26.7
          c6.6-22.5-0.1-28.1,0.3-31.8c-21.6-60-133.2-41.5-126.6,11c2.3,21.9-7.6,58.7,11.4,55.8c56.6-11.9,69.3-7.2,104.7-1.2v37.7
          c-52.4,64.1-100.3,6.5-93.8-7c0.5-4.2-1.3-9-7.3-10.9c-12.2-2.1-12.3,8.9-11.7,20.7C219.5,411.2,269.7,430.6,281.8,430.7z"/>
      </g>
    </g>
  </g>
  <path class="st0" style="fill: #FFFFFF;" d="M331.6,120c3.9,17.8-84.4,5.9-90.6,9.1v55.7c9.3,2.4,72-9,53,16.2c-3.2,4.7-48.7,0.9-53,2v72.2
    c0-0.1,66.9,0,80,0c20.9,2.3,10,22.7-6.9,18.2c-126.8-3.3-88.9,25.7-94.8-103.2c2.3-107.6-21.4-74,97.8-79.4
    C320.4,110.9,329.9,109.1,331.6,120z"/>
  <path class="st0" style="fill: #FFFFFF;" d="M372.6,116.2c0,0,0,104.8,0,147.1c5,39.8-24.5,27.2-21.6,14.6c0-20.8,0-153.2,0-161.3
    C349,107.9,369.8,100.3,372.6,116.2z"/>
  <path class="st0" style="fill: #FFFFFF;" d="M506.5,119.4c3.5,17.6-78.7,5.9-85.2,9.1v55.7c33.1,1.8,89.9-8.1,71.3,16.2c-1.5,4.8-67.9,0.8-71.3,2
    c-0.2,3.6,0.5,79.2-0.2,83.4c-9.1,17.5-24.3,0.2-21.4-3.2c0-14,0-153.3,0-163.4c0.3-14.5,21.3-7.3,57.3-8.9
    C485.9,111.4,505.7,105.5,506.5,119.4z"/>
  </svg>
</template>
<script>
  export default {
    name:"logoIcon"
  }
</script>

<style scoped>

</style>