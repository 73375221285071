<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" width="512px" height="512px" class=""><g><g>
	<path d="M422,60v452h90V60H422z M452,330v-30h30v30H452z M482,360v30h-30v-30H482z M452,270v-30h30v30H452z M452,210v-30h30v30H452   z M452,150v-30h30v30H452z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#15ADC7"/>
	<path d="M90,60H0v452h90V60z M60,180v30H30v-30H60z M30,150v-30h30v30H30z M60,240v30H30v-30H60z M60,300v30H30v-30H60z M60,360v30   H30v-30H60z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#15ADC7"/>
	<rect x="210" y="90" width="92" height="29" data-original="#000000" class="active-path" data-old_color="#000000" fill="#15ADC7"/>
	<rect x="210" y="329" width="92" height="30" data-original="#000000" class="active-path" data-old_color="#000000" fill="#15ADC7"/>
	<rect x="210" y="210" width="92" height="29" data-original="#000000" class="active-path" data-old_color="#000000" fill="#15ADC7"/>
	<rect x="271" y="389" width="31" height="123" data-original="#000000" class="active-path" data-old_color="#000000" fill="#15ADC7"/>
	<rect x="210" y="389" width="31" height="123" data-original="#000000" class="active-path" data-old_color="#000000" fill="#15ADC7"/>
	<path d="M392,0H120v512h60V299h152v213h60V0z M180,60h152v89H180V60z M180,269v-89h152v89H180z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#15ADC7"/>
</g></g> </svg>
</template>
<script>
  export default {
    name:"companyIcon"
  }
</script>

<style scoped>

</style>