<template>
  <v-container>
    <v-row class="elevation-3 rounded" justify="center" align="center">
      <v-col lg="6" md="5" sm="6" cols="12">
        <v-btn text icon color="primary" @click="remove">
          <v-icon dark>fa-times-circle</v-icon>
        </v-btn>
        <span class="subtitle-1" color="primary">{{title}}</span>
      </v-col>   
      <v-col lg="6" md="7" sm="6" cols="12" class="ColumnaResumida">
        <span class="titleslight--text">Seleccione Opción</span>
        <v-select
          :items="itemsSelect"
          item-text="titulo"
          item-value="valor"
          v-model="selectOpcion"
          height="30px"
          outlined
          dense>
        </v-select>
      </v-col>
      <v-col v-if="selectOpcion!='between'" lg="12" md="12" sm="12" class="py-0 ma-0 ColumnaResumida">
        <span class="titleslight--text">Valor Fecha Filtrar</span>
        <v-dialog
          ref="dialog"
          v-model="modalCalendar"
          :return-value.sync="FechaSelect"
          persistent
          width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="FechaSelect"
              prepend-icon="fa-calendar-alt"
              readonly
              v-on="on"
              outlined
              dense>
            </v-text-field>
          </template>
          <v-date-picker v-model="FechaSelect" scrollable locale="es-419" color="primarydark">
            <v-row class="white">
              <v-col cols="12" class="text-center">
                <v-btn class="btn-rounded mx-2" depressed light outlined color="buttondark" @click="modalCalendar = false">Cancelar</v-btn>
                <v-btn class="btn-rounded mx-2" depressed light outlined color="buttondark" @click="$refs.dialog.save(FechaSelect)">OK</v-btn>
               </v-col>
            </v-row>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col v-if="selectOpcion=='between'" md="12" sm="12" class="py-0 ma-0 ColumnaResumida">
        <span class="titleslight--text">Rango de Fechas a Filtrar</span>
        <v-dialog
          ref="dialog"
          v-model="modalCalendarRange"
          :return-value.sync="FechaRange"
          persistent
          width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateRangeText"
              prepend-icon="fa-calendar-alt"
              readonly
              v-on="on"
              outlined
              dense>
            </v-text-field>
          </template>
          <v-date-picker v-model="FechaRange" scrollable range locale="es-419" color="primarydark">
            <v-row class="white">
              <v-col cols="12" class="text-center">
                <v-btn class="btn-rounded mx-2" depressed light outlined color="buttondark" @click="modalCalendarRange = false">Cancelar</v-btn>
                <v-btn class="btn-rounded mx-2" depressed light outlined color="buttondark" @click="$refs.dialog.save(FechaRange)">OK</v-btn>
             </v-col>
            </v-row>
          </v-date-picker>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import mixinGridComponenteFiltros from "@/mixins/GridComponenteFiltros.js"

  export default {
    name:"DateText",
    mixins: [mixinGridComponenteFiltros],
    data() {
      return {
        itemsSelect: [
          {titulo:'Mayor Igual',valor: '>='},
          {titulo:'Menor Igual',valor: '<='},
          {titulo:'Igual',valor: '='},
          {titulo:'Entre',valor: 'between'},

        ],
        selectOpcion  : '=',
        valorConsulta : '',
        modalCalendar : false,
        modalCalendarRange : false,
        // FechaSelect   : new Date().toISOString().substr(0, 10),
        FechaSelect   : null,
        FechaRange    :[this.FechaSelect, this.FechaSelect],
      }
    },
    mounted() {
      this.stringConsulta ()
    },
    methods: {
      remove() {
        this.$emit('remove',this.indice)
      },
      stringConsulta (){
        let sConsulta = ''
        switch (this.selectOpcion) {
          case '':
            sConsulta = ''
          break
          case '>=':
            sConsulta = ">= '" + this.FechaSelect + "'"
          break
          case '<=':
            sConsulta = "<= '" + this.FechaSelect + "'"
          break
          case '=':
             //sConsulta = "= '" + this.FechaSelect + "'"
            sConsulta = ` between '${this.FechaSelect} 00:00:00' AND  '${this.FechaSelect} 23:59:59'`
          break
          case 'between':
            // sConsulta = "between '" + this.FechaRange.join("' AND '") +"'"
            sConsulta = ` between '${this.FechaRange[0]} 00:00:00' AND  '${this.FechaRange[1]} 23:59:59'`
          break
        }
        this.$emit("input",sConsulta)
      }
    },
    computed: {
      dateRangeText () {
        return this.FechaRange.join(' ~ ')
      }
    },
    watch: {
      FechaSelect() {
        this.stringConsulta();
      },
      FechaRange() {
        this.stringConsulta();
      },
      selectOpcion() {
        this.stringConsulta();
      },

    },  
  }
</script>

<style  scoped>
.ColumnaResumida{
    max-height: 80px;
  }
</style>