<template>
<!-- <div> -->
  <v-dialog v-model="bMostrarModal" persistent max-width="680px">
    <v-card>
      <v-toolbar
        color="headformsdark"
        height="45"
        elevation="0"
        dense
        dark>
        <v-spacer></v-spacer>
        <span class="mdlHeadForms">Imágenes</span>
        <v-spacer></v-spacer>
        <v-btn 
          color="btncolor-dark"
          @click="CloseDialog()"
          icon
          small
          dark
          outlined>
          <v-icon size="14px">fa fa-times</v-icon>
        </v-btn>
      </v-toolbar>
        <!-- <section> -->
      <v-card-text>
        <v-card v-for="(oItem, nIndex) in aData" :key="nIndex" class="my-2 border-card text-center" >
          <v-card-text class="ColumnaResumida under-sec-flat titlesdark--text py-1 px-2">
              Fotografía Nro {{nIndex+1}}
          </v-card-text>
          <v-card-text class="text-center">
            <v-img
              :lazy-src="oItem[oCampos.sAnexo]"
              max-height="150"
              max-width="250"
              :src="oItem[oCampos.sAnexo]"
              class="margin-auto"
            ></v-img>
          </v-card-text>
        </v-card>  
      </v-card-text>
    <!-- </section> -->
    </v-card>  


  </v-dialog>
            
  <!-- </div> -->
</template>

<script>
import axiosServices from '@/services/axiosServices.js'
import oJsonAnexos from '@/assets/file/json_anexos.js'

export default {
  name:"ModalImagenes",
  props: {
    bMostrarModal: {
      type: Boolean,
      default: false
    },
    oDataParent: {
      type: Object,
      default: () => {}
    },
    
    sIdCampo: { // Se recibe el nombre del campo de la llave principapara extracto el id valor 
      type: String,
      default: ''
    },
    sNombreTabla: {
      type: String,
      default: ''
    },
  },
  // 
  components: {},
  data() {
      return {
        sIdLlaveForanea: '', // Indica elid d e filtro para traer la info
        aData:[], // Informacion de las firmas
        oCampos: {},
      }
  },
  async mounted(){
    if(oJsonAnexos.hasOwnProperty(this.sNombreTabla)){
      this.oCampos = oJsonAnexos[this.sNombreTabla].oFotos
      this.sIdLlaveForanea = this.oDataParent[this.sIdCampo]
      var  sParams = '&nTipo=1&sTabla='+ oJsonAnexos[this.sNombreTabla].oFotos.sTablaAnexos + '&' + 'nValor=' + this.sIdLlaveForanea + `&oCampos=${JSON.stringify(this.oCampos)}` 
      
      const  aRespuesta = await axiosServices.getConfiguration('/pGrids_Anexos', true ,sParams)
      this.aData = aRespuesta.aData
    }
  },
  methods: {
    CloseDialog() {
      this.$emit('input', false)
    },
  },
  
}
</script>

<style lang="scss" scoped>

</style>