import Vue from 'vue'
import Vuex from 'vuex'
// importar modulos

// plugin a agregar
import  createPersistedState  from  'vuex-persistedstate'

// Import de drag and drop 
import defaultBoard from '@/components/dragdrop/default-board.js'


// se busca si localmentente en el storage existe informacion bajo el nombre de board sino se carga el por default
const board = JSON.parse(localStorage.getItem('board')) || defaultBoard

// Modulos
import notificaciones from '@/store/modulos/notificaciones.js'
import firebasenotificaciones from '@/store/modulos/firebasenotificaciones.js'
import BuildCorreos from '@/store/modulos/buildCorreos.js'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    login               : false, 
    token               : '',          // token del usuario registrado
    idUser              : '',          // id Usuario logueado
    sSubDominio         : '',          // subdominio del cliente         
    latlng              : {},          // objeto de latitud longitud 
    oFormError          : {},          // menejo de error en los formularios
    idDeparment         : '',          // Id departamento usuario
    idSubProyecto       : '',          // Id sub proyecto aplica solo para seif modulos, si es seif devuelve 0
    sSubdominioTemporal : '',          // Subdominio temporal para hacer switch de conexiones y para hacer puentes 
    idCliente           : '',          // idcliente principal del usuario 
    idPerfil            : '',          // Id peril del usuario
    idModule            : '',          // Id Modulo activo
    sTitleModule        : '',          // Titulo del modulo activo
    oPermission         : {},          // Objetos de permisos
    sParamsExt          : '',          // Parametros adicionales en la URL incluye nombre del proyecto al cual se hara la conexión
    board,                             // Variable para manejo de board de construir BuildDinamicos    
    componentSelect     : {},          // Aqui se cargara el componente seleccionado para mostarlo en confguracion BuildDinamicos
    nIdPrevPage         : '',          // Numoero de pagina par regresar de Reporte de subformulario
    processImport       : '',
    selectedProcessImport: {},
    dataImport          : [],
    processesImport     : [],
    processesImportNames: [],
    aDiccionario: [],
    errorMessagesImport : "!Error:",
    timestampImport : parseInt(new Date()/1000)

  },
  getters: {
    getLogin : (state) => {
      return state.login;
    },
    getToken : (state) => {
      return state.token;
    },
    getIdUser : (state) => {
      return state.idUser;
    },
    getDomain : (state) => {
      return state.sSubDominio;
    },
    getlatlng : (state) => {
      return state.latlng;
    },
    getoFormError : (state) => {
      return state.oFormError;
    },
    getIdDeparment : (state) => {
      return state.idDeparment;
    },
    getIdSubProyecto : (state) => {
      return state.idSubProyecto;
    },
    getSubdominioTemporal: (state) => {
      return state.sSubdominioTemporal;
    },
    getIdPerfil : (state) => {
      return state.idPerfil;
    },
    getIdModule : (state) => {
      return state.idModule;
    },
    getTitleModule : (state) => {
      return state.sTitleModule;
    },
    getPermisionUser : (state) => {
      return state.oPermission;
    },
    getsParams : (state) => {
      return state.sParamsExt
    },
    // -------------------- Seccion buildDinamicos  --------------------------------

    getsboard : (state) => {
      return state.board
    },
    getscomponentSelect : (state) => {
      return state.componentSelect
    },
    getidFormulario : (state) => {
      return state.board.columns[1].idFormulario
    },
    // -------------------- FIN Seccion buildDinamicos  --------------------------------

    getIdPrevPage : (state) => {
      return state.nIdPrevPage
    },
    // -------------------- Seccion import  --------------------------------
    getProcessImport : (state) => {
      return state.processImport
    },
    getSelectedProcessImport : (state) => {
      return state.selectedProcessImport
    },
    getProcessesImportNames : (state) => {
      return state.processesImportNames
    },
    getProcessesImport : (state) => {
      return state.processesImport
    },
    getDataImport : (state) => {
      return state.dataImport
    },
    getTimestampImport : (state) => {
      return state.timestampImport
    }
    // -------------------- FIN Seccion import  --------------------------------
  },
  mutations: {
    SETLOGIN: (state,value) => {
      state.login = value;
    },
    SETTOKEN: (state,value) => {
      state.token = value;
    },
    SETIDUSER: (state, value) => {
      state.idUser = value;
    },
    SETSUBDOMAIN: (state, value) => {
      state.sSubDominio = value;
    },
    SETLATLNG: (state, value) => {
      state.latlng = value;
    },
    SETFORMERROR: (state, value) => {
      state.oFormError = value;
    },
    SETIDDEPARMENT: (state, value) => {
      state.idDeparment = value;
    },
    SETIDSUBPROYECTO: (state, value) => {
      state.idSubProyecto = value;
    },
    SETSSUBDOMINIOTEMPORAL: (state, value) => {
      state.sSubdominioTemporal = value;
    },
    SETIDCLIENTE: (state, value) => {
      state.idCliente = value;
    },
    SETDICCIONARIO: (state, value) => {
      state.aDiccionario = value;
    },
    SETIDPERFIL: (state, value) => {
      state.idPerfil = value;
    },
    SETIDMODULE: (state, value) => {
      state.idModule = value;
    },
    SETTITLEMODULE: (state, value) => {
      state.sTitleModule = value;
    },
    SETPERMISSION: (state, value) => {
      state.oPermission = value
    },
    SETPARAMS: (state, value) => {
      state.sParamsExt = value
    },

    // -------------------- Seccion buildDinamicos  --------------------------------
    SAVE_BOARD (state, value) {
      state.board = value
    },
    SAVE_CAMPOBOARD (state, value) {
      state.board.columns[1].campos.splice([value.camposIndex],1,value.campo)
    },
    UPDATE_CAMPO (state, { task, key, value }) {
      task[key] = value
    },
    DELETE_CAMPO (state,Index){
      board.columns[1].campos.splice(Index,1)
      state.componentSelect = {nombreTipo:'', campos:{}, camposIndex:''}
    },
    MOVE_CAMPO (state, { fromTasks, toTasks, fromTaskIndex, toTaskIndex, fromColumn, toColumn  }) {
      // si se mueva en la misma columna elimino la posicion inicial
      if(fromColumn == toColumn){
        const taskToMove = fromTasks.splice(fromTaskIndex, 1)[0]
        
        toTasks.splice(toTaskIndex === undefined ? toTasks.length : toTaskIndex, 0, taskToMove)
      } else {
        if(toColumn != 'Tipos de Campos'){
          toTasks.splice(toTaskIndex === undefined ? toTasks.length : toTaskIndex, 0, fromTasks[fromTaskIndex])
        } else {
          // Como saco el registro de Fomulario lo borro
          fromTasks.splice(fromTaskIndex, 1)[0]
        }
      }
    },
    CHANGE_COMPONENTSELECT (state, NewComponentSelect){
     state.componentSelect = NewComponentSelect
    },
    SAVE_FORMULARIO (state, value){
      state.board.columns[1].idFormulario = value
    },
    CLEAN_CAMPOS (state) {
      state.board.columns[1].campos = []
    },
    CLEAN_BOARD (state) {
      state.board = {
          name: 'builDinamic',
          columns: [
            {
              name: 'Tipos de Campos',
              ancho: 300,
              campos: [
              ]
            },
            {
              name: 'Formulario',
              ancho: 750,
              campos: [
              ]
            },
          ]
        }
    },
    // -------------------- FIN Seccion buildDinamicos  --------------------------------


    SAVE_IDPREVPAGE (state, value) {
      state.nIdPrevPage = value
    },
    // -------------------- Seccion import  --------------------------------
    SAVE_PROCESS_IMPORT : (state, value) => {
      state.processImport = value
    },
    SAVE_SELECTED_PROCESS_IMPORT : (state, value) => {
      state.selectedProcessImport = value
    },
    SAVE_PROCESSES_IMPORT_NAMES : (state, value) => {
      state.processesImportNames = value
    },
    SAVE_PROCESSES_IMPORT : (state, value) => {
      state.processesImport = value
    },
    SAVE_DATA_IMPORT : (state, value) => {
      state.dataImport = value
    },
    SAVE_TIMESTAMP_IMPORT : (state, value) => {
      state.timestampImport = value
    }
    // -------------------- FIN Seccion import  --------------------------------
  },
  actions : {
    setLogin({commit}, value){
      commit ('SETLOGIN', value)
    },
    saveToken({commit}, value){
      commit ('SETTOKEN', value)
    },
    saveIdUser({commit}, value){
      commit ('SETIDUSER', value)
    },
    saveSubDomain({commit}, value){
      commit ('SETSUBDOMAIN', value)
    },
    savelatlng({commit}, value){
      commit ('SETLATLNG', value)
    },
    saveoFormError({commit}, value){
      commit ('SETFORMERROR', value)
    },
    saveIdDeparment({commit}, value){
      commit ('SETIDDEPARMENT', value)
    },
    saveIdSubProyecto({commit}, value){
      commit ('SETIDSUBPROYECTO', value)
    },
    saveSubdominioTemporal({commit}, value){
      commit ('SETSSUBDOMINIOTEMPORAL', value)
    },
    saveIdCliente({commit}, value){
      commit ('SETIDCLIENTE', value)
    },
    saveDiccionario({commit}, value){
      commit ('SETDICCIONARIO', value)
    },
    saveIdPerfil({commit}, value){
      commit ('SETIDPERFIL', value)
    },
    saveIdModule({commit}, value){
      commit ('SETIDMODULE', value)
    },
    saveTitleModule({commit}, value){
      commit ('SETTITLEMODULE', value)
    },
    savePermissions({commit}, value){
      commit ('SETPERMISSION', value)
    },
    saveParamsProyect({commit}, value) {
      commit ('SETPARAMS', value)
    },
    // -------------------- Seccion buildDinamicos  --------------------------------

    salvarBoard({commit}, value) {
      commit ('SAVE_BOARD', value)
    },
    cambiarComponentSelet({commit}, value) {
      commit ('CHANGE_COMPONENTSELECT', value)
    },
    salvarCampoBoard({commit}, value) {
      commit ('SAVE_CAMPOBOARD', value)
    },
    salvarFormulario({commit}, value){
      commit ('SAVE_FORMULARIO', value)
    },
    cleanCampos ({commit}){
      commit ('CLEAN_CAMPOS')
    },
    cleanBoard ({commit}){
      commit('CLEAN_BOARD')
    },
    // -------------------- FIN Seccion buildDinamicos  --------------------------------

    saveIdPrevPage ({commit}, value) {
      commit ('SAVE_IDPREVPAGE', value)
    },
    // -------------------- Seccion import  --------------------------------

    saveProcessImport({commit}, value) {
      commit ('SAVE_PROCESS_IMPORT', value)
    },
    saveSelectedProcessImport({commit}, value) {
      commit ('SAVE_SELECTED_PROCESS_IMPORT', value)
    },
    saveProcessesImport({commit}, value) {
      commit('SAVE_PROCESSES_IMPORT', value)
    },
    saveProcessesImportNames({commit}, value) {
      commit ('SAVE_PROCESSES_IMPORT_NAMES', value)
    },
    saveDataImport({commit}, value) {
      commit ('SAVE_DATA_IMPORT', value)
    },
    saveTimestampImport({commit}, value) {
      commit ('SAVE_TIMESTAMP_IMPORT', value)
    }
    // -------------------- FIN Seccion import  --------------------------------
  },
  modules: {
    // para el manejo de modulos se debe tener en cuenta que toca anteceder el mnombre del modulo 
    // guia de uso https://vuex.vuejs.org/guide/modules.html#module-local-state

    notificaciones,
    firebasenotificaciones,
    BuildCorreos,
  },  
  // aquí agregamos el plugin
	plugins: [createPersistedState()]
});
