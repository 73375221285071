<template>
	<div class="imprimir">
		<v-layout row justify-space-between> 
			<v-flex xs4 v-for="(oInfo, idx) in aInfo" :key="'itemcol'+idx" d-flex justify-space-between>
				<div class="container-info">
					<div class="under-sec-flat-blue py-2">
						<h4 class="text-medium text-uppercase">{{oInfo.index}}</h4>
					</div>
					<div class="pa-2">
						<p class="no-margin text-min">{{oInfo.value}}</p>
					</div>
				</div>
			<v-divider vertical></v-divider>
			</v-flex>
		</v-layout>
	</div>
</template>
<script>
export default {
    name:"InfoTablaVista",
    props:{
			aInfo: {},
			nIdParent: {
				type: String,
				default: ''
			},
			sNameDetails: {
				type: String,
				default: ''
			}
    },
    data() {
			return {
					
			}
    },
}
</script>
<style lang="scss">
.container-info{
	margin: 0 auto;
	width: 100%;
	text-align: center;
}
.no-margin{
	margin: 0px!important;
}
</style>