<template>
  <v-container grid-list-xs class="pa-0 ma-0 pt-2" fluid>
    <v-row class="pa-0 ma-0 titlesdark--text" >
      <v-col md="6" sm="12" class="pa-0 ma-0">
          <span class="pl-1 text-light font-size-2">Clientes</span>
      </v-col>
      <v-col md="6" sm="6" class="pa-0 ma-0 col-right">
        <v-btn color="btndest" class="no-uppercase btn-rounded d-flex mx-2" small depressed dark @click="openModal('TiposClientes', 'Tipo Cliente')">Tipo Cliente<v-icon size="18px" right>mdi-account-box-multiple</v-icon></v-btn>
        <v-btn color="btndest" class="no-uppercase btn-rounded d-flex mx-2" small depressed dark @click="openModal('SectoresClientes', 'Sector Cliente')">Sector Cliente<v-icon size="18px" right>mdi-earth</v-icon></v-btn>
      </v-col>
    </v-row>
    <v-row class="pa-0 my-2 container-rounded-shadown">
      <v-col md="12" sm="12" class="pa-0 ma-0 rounded-basic">
        <grid
          :tabla="'Clientes'"
          :Detalles="'DetallesClientes'"
          :bActiveFilter="true"
          :bActiveExport="true"
          :bSingleSelect="true"
          :bShowSelect="true"
          :sIdTable="'idClientes'"
          :bShowMapDetails="true"
          @idSelect="getIdSelect"
          @nameField="getNameFieldSelect"
          class="rounded-basic">
        </grid>
      </v-col>
    </v-row>
    <v-divider class="my-4"></v-divider>
    <v-row class="pa-0 ma-0 titlesdark--text" >
      <v-col md="6" sm="12" class="pa-0 ma-0">
          <span class="pl-1 text-light font-size-2">Sedes</span>
      </v-col>
      <v-col md="6" sm="6" class="pa-0 ma-0 col-right">
        <v-btn color="btndest" class="no-uppercase btn-rounded d-flex mx-2" small depressed dark  @click="openModal('TiposSedes', 'Tipo Sede')">Tipo Sede<v-icon size="18px" right>mdi-home-modern</v-icon></v-btn>
      </v-col>
    </v-row>
    <v-row class="pa-0 my-2 container-rounded-shadown">
      <v-col md="12" sm="12" class="pa-0 ma-0 rounded-basic">
        <gridDetails
          :sTabla="'Sedes'"
          :sNameForeignkey="nameField"
          :idValue="idSelect"
          :bActiveExport="true"
          class="rounded-basic">
        </gridDetails>
      </v-col>
    </v-row>
    <ModalExterno
      :bShowModal="bShowModal"
      :sTable="sTableModal"
      :sTitle="sTitleModal"
      @input="changeStateModal">
    </ModalExterno>
  </v-container>
</template>

<script>
import grid from '@/components/Grid/Grid.vue'
import gridDetails from '@/components/Grid/GridDetails.vue'
import ModalExterno from '@/components/ModalExterno.vue'
export default {
  name:"Clientes",
  components: {
    grid,
    gridDetails,
    ModalExterno,
  },
  data() {
    return {
      idSelect: '',                  // Id seleccionado
      nameField: '',                 // Nombre del campo foreignKey
      bShowModal: false,             // Habilita o deshabilita modal externo
      sTableModal: '',               // Nombre tabla a trabajar sobre el modal
      sTitleModal: '',               // Titlulo del modal
    }
  },// fin data
  methods: {
    openModal(sTable, sTitulo) {
      switch (sTable) {
        case 'TiposClientes': {
          this.bShowModal = true;
          this.sTableModal = sTable;
          this.sTitleModal = sTitulo;
          break;
        }
        case 'SectoresClientes': {
          this.bShowModal = true;
          this.sTableModal = sTable;
          this.sTitleModal = sTitulo;
          break;
        }
        case 'TiposSedes': {
          this.bShowModal = true;
          this.sTableModal = sTable;
          this.sTitleModal = sTitulo;
          break;
        }
      }
    },
    changeStateModal(bNewValue) {
      this.bShowModal = bNewValue;
    },
    getIdSelect(sIdSelected) {
      this.idSelect = sIdSelected;
    },
    getNameFieldSelect(sNameFiled) {
      this.nameField = sNameFiled;
    }
  },
}
</script>

<style lang="scss" scoped>
  .rounded-basic{
    border-radius: 15px!important;
  }
  .container-rounded-shadown{
    border-radius: 13px;
    box-shadow: 0px 0px 5px 2px #15223214;
  }
</style>