<template>
  <v-container>
    <v-row class="elevation-3 rounded" justify="center" align="center">
      <v-col lg="6" md="5" sm="6" cols="12">
        <v-btn text icon color="primary" @click="remove">
          <v-icon dark>fa-times-circle</v-icon>
        </v-btn>
        <span class="subtitle-1" color="primary">{{title}}</span>
      </v-col>  
      <v-col lg="6" md="7" sm="6" cols="12" class="ColumnaResumida">
        <span class="titleslight--text">Seleccione Opción</span>
        <v-select
          :items="itemsSelect"
          item-text="titulo"
          item-value="valor"
          v-model="selectOpcion"
          height="30px"
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col lg="12" md="12" sm="12" cols="12" class="py-0 ma-0 ColumnaResumida" dense>
        <span class="titleslight--text">Seleccione una Opción</span>
        <v-autocomplete
          v-model="valorConsulta"
          :items="itemsData"
          item-text="texto"
          item-value="id"
          :loading="loadSelect"
          height="30px"
          clearable
          outlined
          dense>
        </v-autocomplete>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axiosServices from "@/services/axiosServices.js"
  import mixinGridComponenteFiltros from "@/mixins/GridComponenteFiltros.js"

  export default {
    name:"Selector",
    mixins: [mixinGridComponenteFiltros],
    data() {
      return {
        itemsSelect: [
          {titulo:'Igual',valor: '='},

        ],
        selectOpcion    : '=',
        valorConsulta   : '',
        valorConsulta2  : '',
        itemsData       : [],
        loadSelect      : false,
      }
    },
    mounted () {
      this.getItems()
    },
    methods: {
      getItems(){
        let sAux = this.inner.split(',')
        this.loadSelect = true
        let sParams = ``
        if(this.bSubdominioTemporal){
          sParams = sParams + `&subdominiot=${this.$store.state.sSubdominioTemporal}`
        }
        axiosServices.getConfiguration('/select/' + sAux[0] + '/' + this.campo , true ,sParams).then(aItems =>{
        //  console.log(aItems.aData)
         this.itemsData = aItems.aData
         this.loadSelect = false
        })
      },
      remove() {
        this.$emit('remove',this.indice)
      },
      stringConsulta (){
        let sConsulta = ''
        switch (this.selectOpcion) {
          case '':
            sConsulta = ''
          break
          case '>=':
            sConsulta = ">= '" + this.valorConsulta + "'"
          break
          case '<=':
            sConsulta = "<= '" + this.valorConsulta + "'"
          break
          case '=':
            sConsulta = "= '" + this.valorConsulta + "'"
          break
          case 'between':
            sConsulta = " between '" + this.valorConsulta + "' AND '" + this.valorConsulta2 +"'"
          break
        }
        this.$emit("input",sConsulta)
      }
    },
    watch: {
      valorConsulta() {
        this.stringConsulta();
      },
      valorConsulta2() {
        this.stringConsulta();
      },
      selectOpcion() {
        this.stringConsulta();
      },

    },
    
    
  }
</script>

<style scoped>

  .ColumnaResumida{
    max-height: 80px;
  }
  
 
</style>