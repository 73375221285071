<template>
  <div>
    <span class="titleslight--text">{{titulo}}</span>
    <span v-if="obligatorio" class="titleslight--text font-weight-bold ml-1">*</span>
      <div class="d-flex justify-center align-center mt-3"> 
      <v-radio-group  
        class="ma-0"
        v-model="valor" 
        row 
        dense
        :disabled="bEditable"
        :error="esError"
        :rules="noVacio">
        <v-radio label="SI" value="1"></v-radio>
        <v-radio label="NO" value="0"></v-radio>
      </v-radio-group>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
  export default {
    name: 'FormSiNo',
    props: {
      value: {
      },
      titulo:{
        type:String,
        default:''
      },
      obligatorio:{
        type:Boolean,
        default: false
      },
      campo:{
        type:String,
        default : ''
      },
      editable: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        valor   : this.value,
        esError : false,
        noVacio : [v => !!v],
        bEditable       : (this.editable == "0") ? true : false,
      }
    },
    mounted(){
    },
    methods: {

    },
    computed: {
      ...mapGetters(['getoFormError'])
    },
    watch: {
      valor(NewValor){
        this.$emit('input',NewValor)
        if(this.esError && this.valor != ''){
          this.esError = false
        }  
      },
      value(NewValue){
        this.valor = NewValue
      },
      getoFormError(){
        this.esError = this.getoFormError[this.campo]
      }
    },
    
  }
</script>

<style scoped>


</style>