<template>
  <v-dialog v-model="bShowExport" hide-overlay persistent width="500">
    <v-card
      color="primary"
      dark>
      <v-card-text>
        {{ sLabel }}
        <v-progress-linear
          :active="bFinish"
          indeterminate
          color="white"
          class="mb-0">
        </v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
// import XLSX from 'xlsx'
import axiosServices from '@/services/axiosServices.js'
import Hora from '@/Utlidades/Hora_Fecha.js'

export default {
  name: 'ExportExcel',
  props: {
    bShowExport: {            // Si se activa el boton exportar
      type: Boolean,
      default: false
    },
    TypeExport: {             // Indica el formato dearchivo a exportar
      type: String,
      default: 'xlsx'
    },
    sTable: {                 // Tabla principal a exportar
      type: String,
      default: ''
    },
    oFiltro: {                // El filtro activo para la exportacion
      type: Object,
      default: () => {}
    },
    nPage: {                  // No Pargina para cuando se requiere exportar actual
      type: Number,
      default: 1
    },
    nPerPage: {               // No de registros por pagina cuando se requier exportar actual
      type: Number,
      default: 5
    },
    bAllExport: {             // Indica que requiere exportacion total = true
      type: Boolean,
      default: false
    },
    aInfoTBDetalleExp: {      // arreglo donde vienen la informacón de la las tablas detalles
      type: Array,
      default: () => []
    },
    bSubdominioTemporal:{ //Indica que se activa puente con subdominio temporal
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      json_meta   : [
        [
          {
            'key': 'charset',
            'value': 'utf-8'
          }
        ]
      ],
      aHeaders: [],
      aItems: [],
      sLabel : 'Este proceso puede llevar varios minutos, por favor espere. Generando...',
      bFinish: true,
      nameArchive: '',
    }
  },
  methods: {
    async getDataExport() {


      const aFiltro = (Object.keys(this.oFiltro).length === 0) ? "" : `&filtros=${JSON.stringify(this.oFiltro)}`
      const aDetalle = (this.aInfoTBDetalleExp.length === 0) ? "" : `&aDetalle=${JSON.stringify(this.aInfoTBDetalleExp)}`
      let sParamsExport = `sTable=${this.sTable}&bExportAll=${this.bAllExport}${aFiltro}&nopagina=${this.nPage}&noregistros=${this.nPerPage}${aDetalle}&openWindow=false&responseType=blob`
      
      this.sLabel = "Este proceso puede llevar varios minutos, por favor espere. Generando...";
      if(this.bSubdominioTemporal){
        sParamsExport = sParamsExport + `&subdominiot=${this.$store.state.sSubdominioTemporal}`
      }
      const aRespuesta = await axiosServices.getConfiguration('/dataExport', true, sParamsExport)
      this.sLabel = "Archivo Listo. Se procede a descargar ...";

      const fileURL = window.URL.createObjectURL(new Blob([aRespuesta]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `Exportacion-${Hora.getNow()}.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.CloseDialog()

    },
    CloseDialog() {
      this.$emit('input', false)
    },
    onExport () { // On Click Excel download button  Se deja comento por si se requier a futubo como ejemplo 
      // // export json to Worksheet of Excel
      // // only array possible
      // var Sheet = XLSX.utils.json_to_sheet(this.aItems, {header: this.aHeaders})
      // // A workbook is the name given to an Excel file
      // var wb = XLSX.utils.book_new() // make Workbook of Excel
      // // add Worksheet to Workbook
      // // Workbook contains one or more worksheets
      // XLSX.utils.book_append_sheet(wb, Sheet, this.nameArchive)// sheetAName is name of Worksheet
      // // export Excel file
      // XLSX.writeFile(wb, `${this.nameArchive}.${this.TypeExport}`) // name of the file
      // this.CloseDialog()
    }
  },
  watch: {
    bShowExport(bNewValue) {
      if (bNewValue == true) {
        this.getDataExport();
        this.nameArchive = `${this.sTable}`;
        this.bFinish = true;
      }
    }
  }
}
</script>
