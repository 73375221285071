export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    title:{
      type: String,
      default: 'SinTitulo'
    },
    indice:{},
    campo:{
      type: String,
      default: ''
    },
    inner:{
      type: String,
      default: ''
    },
    bSubdominioTemporal:{ //Indica que se activa puente con subdominio temporal
      type: Boolean,
      default: false
    }
  } 
}