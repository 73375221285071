import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify';
import VueGoogleCharts from 'vue-google-charts';
import '@fortawesome/fontawesome-free/css/all.css'
import 'md5'
import '@/css/main.css';

// AXIOS
import axios from 'axios'
import VueAxios from 'vue-axios'

// Params
import params from '@/params/params.js'

// NProgress
import 'nprogress/nprogress.css'

// export excel
import JsonExcel from 'vue-json-excel'

//Geocodec -- Convertir direcciones en coordenadas
import Geocoder from "@pderas/vue2-geocoder";

// Leaflet y google map
import L from 'leaflet' 
import * as VueGoogleMaps from 'vue2-google-maps'
import 'leaflet/dist/leaflet.css' 
import 'leaflet-contextmenu/dist/leaflet.contextmenu.js' 
import 'leaflet-contextmenu/dist/leaflet.contextmenu.css' 
import Vue2LeafletMarkerCluster from './components/LeafletMap/Vue2LeafletMarkercluster.vue' 
import Vue2LeafletGoogleMutant from './components/LeafletMap/Vue2LeafletGoogleMutant.vue' 
import { LMap, LTileLayer, LMarker, LCircle, LGeoJson, LControlZoom, LControlAttribution, 
  LControlLayers, LLayerGroup, LFeatureGroup, LPopup, LControl, LPolygon } from 'vue2-leaflet' 

// sweetalert 2 
import VueSweetalert2 from 'vue-sweetalert2';  
import 'sweetalert2/dist/sweetalert2.min.css'
//Double Select
import DoubleSelect from 'vue-double-select'
// jspdf
import jsPDF from 'jspdf'

// parser csv
import VuePapaParse from 'vue-papa-parse'

import VueExcelEditor from 'vue-excel-editor'

Vue.use(VueExcelEditor)
Vue.use(VuePapaParse)

// Firebase
import './firebase/firebase'

Vue.use(jsPDF)

Vue.use(VueAxios, axios)

Vue.component('downloadExcel', JsonExcel)

// Variable global para ruta del API
Vue.prototype.$API_Path = params.path
Vue.prototype.$bCargando = false

// Geocodec
Vue.use(Geocoder, {
  googleMapsApiKey: 'AIzaSyAsGgRnC34wiGutlNDOOhb83VzdlZzr1i4'
})

// Leaflet y google map
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyASxvfXQztRor0PV7efa0WDJBUbbq3x8Gc',
    libraries: 'places' // This is required if you use the Autocomplete plugin
  }
})

Vue.component('l-map', LMap) 
Vue.component('l-tile-layer', LTileLayer) 
Vue.component('l-marker', LMarker) 
Vue.component('l-circle', LCircle) 
Vue.component('l-geo-json', LGeoJson) 
Vue.component('l-control-zoom', LControlZoom) 
Vue.component('l-control-attribution', LControlAttribution) 
Vue.component('l-control-layers', LControlLayers) 
Vue.component('l-layer-group', LLayerGroup) 
Vue.component('l-feature-group', LFeatureGroup) 
Vue.component('l-popup', LPopup) 
Vue.component('l-control', LControl) 
Vue.component('l-polygon', LPolygon) 
Vue.component('l-marker-cluster', Vue2LeafletMarkerCluster)

// cambiar icono del mapa en leaflet
delete L.Icon.Default.prototype._getIconUrl 
L.Icon.Default.mergeOptions({ 
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'), 
  iconUrl: require('leaflet/dist/images/marker-icon.png'), 
  shadowUrl: require('leaflet/dist/images/marker-shadow.png') 
}) 

delete L.Icon.Default.prototype._getIconUrl
Vue.component('l-tilelayer-googlemutant', Vue2LeafletGoogleMutant)

// sweetalert
Vue.use(VueSweetalert2)
// double_select
Vue.use(DoubleSelect)
//Google Chart
Vue.use(VueGoogleCharts)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')