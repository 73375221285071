<template>
 <div>
  <v-btn v-if="valor!=''" color="primary" class="mx-1" outlined small icon @click="isLecturaQR = true">
    <v-icon size="13px" class="btncolor--text mx-1">
      fas fa-qrcode
    </v-icon>
  </v-btn>
  <v-dialog
    v-model="isLecturaQR"
    hide-overlay
    max-width="250px"
    transition="dialog-bottom-transition"
  >
   <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="isLecturaQR = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> QR</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <!-- puede invocar un metodo :callback="test" -->
          <vue-qr :text="valor"  :qid="valor"></vue-qr>
     </v-card-text>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
import VueQr from 'vue-qr';
import mixinGridComponenteCelda from "@/mixins/GridComponenteCelda.js"

export default {
  name: 'CeldaQR',
  components: { VueQr },
  mixins: [mixinGridComponenteCelda],
  data() {
    return {
      isLecturaQR: false,
    };
  },
  methods: {
    /*test(dataUrl, id) {
      console.log(dataUrl, id);
    },*/
  },
};
</script>
<style scoped>
</style>