<template>
  <v-container grid-list-xs class="pa-0 ma-0 pt-2" fluid>
    <v-row class="pa-0 ma-0 titlesdark--text" >
      <v-col md="8" sm="12" class="pa-0 ma-0">
        <span class="pl-1 text-light font-size-2">Departamentos</span>
      </v-col>
    </v-row>
    <v-row class="pa-0 my-2 container-rounded-shadown">
      <v-col md="12" sm="12" class="pa-0 ma-0 rounded-basic">
        <grid
          :tabla="'Departamentos'"
          :bActiveFilter="true"
          :bActiveExport="true"
          :bSingleSelect="true"
          :bShowSelect="false"
          :sIdTable="'idDepartamentos'"
          :oTablesDependens="oTables"
          :bShowMapDetails="false"
          @idSelect="getIdSelect"
          @nameField="getNameFieldSelect"
          class="rounded-basic">
        </grid>
      </v-col>
    </v-row>
    <v-divider class="my-4"></v-divider>
    <v-row class="pa-0 ma-0 titlesdark--text">
      <v-col md="8" sm="12" class="pa-0 ma-0">
        <span class="pl-1 text-light font-size-2">Dependencias</span>
      </v-col>
    </v-row>
    <v-row class="pa-0 my-2 container-rounded-shadown">
      <v-col md="12" sm="12" class="pa-0 ma-0 rounded-basic">
        <grid
          :tabla="'Dependencias'"
          :bActiveFilter="true"
          :bActiveExport="true"
          :bSingleSelect="true"
          :bShowSelect="false"
          :sIdTable="'idDependencias'"
          :oTablesDependens="oTables"
          :bShowMapDetails="false"
          @idSelect="getIdSelect"
          @nameField="getNameFieldSelect"
          class="rounded-basic">
        </grid>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import grid from '@/components/Grid/Grid.vue'
export default {
  name:"Departamentos",
  components: {
    grid,
  },
  data() {
    return {
      idSelect  : '',                 // Id seleccionado
      nameField : '',                 // Nombre del campo foreignKey
      oTables: {
        "Primary": "Departamentos",
        "Second": '',
        "Third": '',
        "Aux": ''
      }
    }
  },// fin data
  methods: {
    getIdSelect(sIdSelected) {
      this.idSelect = sIdSelected;
    },
    getNameFieldSelect(sNameFiled) {
      this.nameField = sNameFiled;
    }
  },
}
</script>