<template>
  <v-app style="background: #F8F8F8;" id="app">
    <Modulos v-if="this.$route.query.incrustado == undefined"></Modulos>
    <v-main>
      <v-container fluid>
        <router-view/>
      </v-container>
    </v-main>
    <Notificacion/>
  </v-app>
</template>
<script>
import Modulos from "@/components/Modulos.vue"
import Notificacion from '@/components/Notificacion.vue';
export default {
  name: 'App',
  components: {
    Modulos,
    Notificacion
  },
  data: () => ({
    popupActivo: true
  }),
}
</script>
<style lang="scss">
@import  'css/fonts.scss';
@import  'css/variables.scss';
@import  'css/global.scss';

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  font-size: 16px;
  font-family: 'Poppins';
  line-height: 18px;
  @media screen and (max-width: 600px){
    font-size: 14px;
    padding-bottom: 50px;
  }
}
// Elimina el padding para que el login se vea bien, se debe asignar un pt a las vistas
.container {
  padding: 0px !important;
}

// Ajuste del container general para que se expanda al 100%
@media (min-width: 1264px){
  .container {
    max-width: 100vw!important;
    }
}
</style>