<template>
  <!-- <div> -->
    <l-map ref="map" :options="optionsMap" :center="center" :zoom="18" :style="`width:${dimencion.ancho}; height: ${dimencion.alto}; margin: 10px;`">
      <l-tilelayer-googlemutant :apikey="apikeyGoogle" :options="optionsMap"></l-tilelayer-googlemutant>
      <l-marker :lat-lng.sync="center" :draggable="false" :title="value"></l-marker>
    </l-map>
  <!-- </div> -->
</template>

<script>
import params from '@/params/params.js'
  export default {
    name:'mapLocalizar',
    props: {
      lat : {
        type: String,
        default:'' 
      },
      lng : {
        type: String,
        default:'' 
      },
      dimencion : {
        type: Object,
        default: () => { 
         return  { 
            ancho : "50%",
            alto  : "250px"
         } 

        } 
      },
      bControlZoom: {
        type: Boolean,
        default: true
      }
    },
    data() {
      let optionsMap = {
        type: "roadmap",
        static: "yes",
        zoomControl: this.bControlZoom,
        scrollWheelZoom: this.bControlZoom
      };
      return {
        optionsMap,
        apikeyGoogle: params.sApiKeyMap,
        center  : { lat: this.lat, lng: this.lng },
        value   : 'value',
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>