<template>
  <v-dialog color="primary" class="text-center" v-model="value" persistent max-width="350px" @click="onCerrarModal">
    <v-card class="pa-4">
      <v-container class="text-center">
        <div class="cont-icon my-2">
          <v-icon dark size="40px"> {{ onTipoMensaje }}</v-icon>
        </div>
        <p> {{ sMensaje }} </p>
        <v-btn
          class="ma-2"
          color="primary"
          dark
          @click="onCerrarModal"
        >
        Cerrar
          <v-icon size="20" right>mdi-close</v-icon>
        </v-btn>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name:'ModalMensaje',
  components: { },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    sMensaje: {
      type: String,
    },
    // sTitulo: {
    //   type: String,
    //   default: ''
    // },
    nTipo: {
      type: Number,
      default: 1 // 1 - Exitoso | 2 - Informativo | 3 Error 
    }
  },
  methods:{
    onCerrarModal () {
      // this.bMostrarModal = false
      this.$emit('input', false)
    }
  },
  computed: {
    onTipoMensaje(){
      return (this.nTipo==1) ? 'mdi-check-bold' : ((this.nTipo==2) ? 'mdi-information-variant' : 'mdi-close-thick')
    },
    
  }
}
</script>
<style lang="scss" scoped>
.cont-icon{
  width: 50px;
  height: 50px;
  margin:0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--v-primary-base);
}
</style>