<!--
  Componente deprecado si se requiere usar grid detalle invocar la GRID normal en modo detalle 
-->
<template>
  <div>
    <v-data-table
      v-model="selected"
      :headers="formatHeader"
      :items="dataItems"
      :search="quitSearch"
      :items-per-page.sync="itemsPerPagina"
      :page.sync="paginaselect"
      item-key="idSedes"
      :loading="cargando"
      hide-default-footer
      dense
      class="rounded-basic">
      <template v-slot:top >
        <v-container class="px-3 pt-2 pb-0 mb-1" fluid>
          <v-row align="center" justify="start">
            <v-col lg="4" md="5" sm="12" xs="12" cols="12" class="py-0">
              <v-text-field
                v-model="quitSearch"
                label=" Busqueda Rápida"
                append-icon="fa-search"
                background-color="white"
                flat
                hide-details
                rounded
                dense
                outlined
                class="container-search"
                >
              </v-text-field>  
            </v-col> 
            <v-col lg="8" md="7" sm="12" cols="12" class="pt-4 col-right">
              <v-btn v-if="bActiveFilter" small color="btncolors" dark @click="filtrar">Filtrar</v-btn>
              <v-btn color="btndest" class="no-uppercase btn-rounded d-flex mx-2" small depressed dark @click="nuevoItem">Nuevo<v-icon size="13px" right>fa-file-alt</v-icon></v-btn>
              <v-menu
                class="d-flex mx-2"
                offset-y
                :close-on-content-click="false"
                transition="slide-y-transition">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" color="primary" outlined class="no-uppercase btn-rounded" small depressed> Exportar <v-icon size="13px" right>fa-file-export</v-icon>
                  </v-btn>
                </template>
                <v-list
                  dense>
                  <!-- <v-list-item @click="exportar('csv', true)">
                    <v-list-item-icon>
                      <v-icon>fa-file-csv</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Exportar todo a CSV</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item> -->
                  <v-list-item @click="exportar('xlsx')">
                    <!-- <v-list-item-icon left>
                    </v-list-item-icon> -->
                    <v-list-item-content>
                      <v-list-item-title> <v-icon left>fa-file-excel</v-icon>Exportar información actual a Excel</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- <v-list-item @click="exportar('csv')">
                    <v-list-item-icon>
                      <v-icon>fa-file-csv</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Exportar información actual a CSV</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item> -->
                </v-list>
              </v-menu>
            </v-col>
            <v-col v-if="bActiveExport" md="2" sm="2" class="pt-5 d-flex justify-end">
            </v-col>
          </v-row>  
        </v-container>
        <v-container v-show="filtrarActive">
          <v-card style="overflow:auto">
            <v-toolbar
              height="40"
              elevation="0"
              dense>
              <v-spacer></v-spacer>
              <span class="font-weight-black mdlHeadForms">Filtros Detallados</span>
              <v-spacer></v-spacer>
              <v-tooltip bottom v-if="filtradoEstate">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    class="font-weight-black"
                    color="btncolors"
                    @click="LimpiarFiltros()"
                    outlined
                    dark>
                    <v-icon small>fa-times-circle</v-icon>
                    Limpiar Filtros
                  </v-btn>
                </template>
                <span>Limpiar Filtros</span>
              </v-tooltip>
            </v-toolbar>
            <v-card-text>
              <FilterSecction
                :Headers="headers"
                @ejecutarFiltro="getConsultaFiltro">
              </FilterSecction>
            </v-card-text>
          </v-card>
        </v-container>
      </template>
      <!-- COLUMNA DE ACCIONES -->
      <template v-slot:[`item.actions`]="{ item }">
        <component v-for="(boton,index) in BtnAcciones" :key="index"
          :is="boton.componente"
          :item="item"
          :HoverMensaje="boton.mensaje"
          :tabla="sTabla"
          @btnActionEvento="btnActionEvento">
        </component>
      </template>
      <!-- FOOTER DE GRID GENERAL -->
      <template v-slot:footer>
        <v-divider ></v-divider>
        <v-row class="pa-0 ma-0 justify-start align-center mt-2">
          <v-col cols="12" sm="12" md="2" lg="2" class="col-nopadding cont-shadown-up col-registros">
            <v-row justify-center class="">
              <!-- <v-col sm="6" lg="6" cols="6" class="d-flex justify-center align-cente col-nopadding">
                <v-chip class="ma-2 " text-color="black" color="white">
                  <span class="text-min text-primary">Registros Totales</span>  
                  <div class="resaltado"><span> &nbsp;{{totalRegistros}} </span>  </div>
                </v-chip>
              </v-col> -->
              <v-col  cols="12" class="d-flex justify-center align-center py-2 px-2">
                  <p class="mr-1 text-min text-primary text-registros">No. Registro</p>
                  <v-select style="max-width: 80px;" class="text-min text-primary select-registros" 
                  v-model="itemsPerPagina" :items="itemsPagina" 
                  dense outlined hide-details height="30px" >
                  </v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="12" md="5" lg="6" xs="12" class="d-flex justify-center align-center cont-shadown-up col-pagination">
            <v-pagination
              v-model="paginaselect"
              :circle="false"
              :length="pageLength"
              :total-visible="pageTotalVisible"
              :page="paginaselect"
              class=""
              >
            </v-pagination>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    <formulario
      :key="keyForm"
      v-model="showNew"
      :tableName="sTabla"
      @saveOK="refrescarItems"
      :modNuevo="formNew"
      :oItem.sync="onItem"
      :titulo="sTitulo">
    </formulario>
    <ExportModal
      :bShowExport="bShowEx"
      :bExport="bShowEx"
      :sTable="sTabla"
      :TypeExport="sPropsType"
      :sFiltro="''"
      :nPage="nPropPageSel"
      :nPerPage="nPropsItemPerPage"
      :bAllExport="bExportAll"
      @input="setExportModal()">
    </ExportModal>
  </div>
</template>
<script>
import axiosServices from '@/services/axiosServices.js'
import BtnEdit  from '@/components/Grid/Btn_Component/BtnEdit.vue'
import BtnColor  from '@/components/Grid/Btn_Component/BtnColor.vue'
import BtnDetalleCliente  from '@/components/Grid/Btn_Component/BtnDetalleCliente.vue'
import BtnAddUser  from '@/components/Grid/Btn_Component/BtnAddUser.vue'
import formulario from '@/components/Form/Form.vue'
import ExportModal from '@/components/ExportExcel.vue'
import FilterSecction from '@/components/Grid/Filter_Component/FilterSecction.vue'

export default {
  name:"GridDetailsComponent",
  components: {
    BtnEdit,
    BtnColor,
    BtnDetalleCliente,
    BtnAddUser,
    formulario,
    ExportModal,
    FilterSecction,
  },
  props: {
    sTabla:{
      type:String,
      default:''
    },
    sNameForeignkey: {
      type: String,
      default: ''
    },
    idValue: {
      type: String,
      default: ''
    },
    sTitulo: {
      type: String,
      default: ''
    },
    bActiveExport: {
      type: Boolean,
      default: false
    },
    bActiveCreate: {
      type: Boolean,
      default: true
    },
    showAcciones: {
      type: Boolean,
      default: true
    },
    bActiveFilter: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      selected          : [],                   // v-model de la tabla
      pageTotalVisible  : 10,                   // Indica el numero de indicadores visibles en el paginados 
      paginaselect      : 1,                    // indica la pagina seleccionada
      itemsPagina       : [5,10, 20, 50],       // indica los item de numero de registros por pagina 
      filtrarActive     : false,                // si esta activo la zona de filtros dinamicos
      itemsPerPagina    : 5,                    // indica el item seleccionado en el control de registros por pagina 
      quitSearch        : '',                   // valor de la busqueda rapida  
      cargando          : false,                // indica si se esta cargando algo para mostrar el progres en la grid
      totalRegistros    : 0,                    // indica el total de registros que se cargan en la grid
      headers           : [],                   // Array de objetos que se cargan en la cabecera de la grid
      dataItems         : [],                   // Data que se carga en la grid
      BtnAcciones       : [],                   // los botones que se muestran en la seccion (columna) de acciones  
      showNew           : false,                // empleado para mostrar el form de new
      formNew           : true,                 // indica en que modo se abre el formulario
      itemEdit          : {},                   // Item a ser editado
      keyForm           : 0,                    // indice para re dibujar el form por cada apertura
      bShowEx           : false,                // Habilita el modal de exportación
      sPropsType        : '',                   // Tipo archvio de exportación
      sParamsFiltro     : '',                   // Parametros de filtro para exportación
      nPropPageSel      : 1,                    // Parametro de pagina seleccionada para exportar
      nPropsItemPerPage : 5,                    // Parametro de item por pagina que se exportara
      bExportAll        : false,                // Parametro para exportar toda la informacion o solo lo que se esta visualizando
      filtradoEstate    : false,                // estado de que si estado filtrado o no la grid    

    } // fin return data
  },  // fin data
  mounted() {
    this.refrescarItems()
    this.getHeaders()

  },
  methods: {
    refrescarItems(){
      if(this.filtradoEstate){
        this.getConsultaFiltro()
      }else{
        this.getItems()
        this.getTotalRegistros()
        this.getBtnAcciones()
      }
    },
    getBtnAcciones(){
      this.cargando = true
      axiosServices.getConfiguration('/pGrids_acciones',true, `sTable=${this.sTabla}`).then(aAccionesBtn => {
        this.BtnAcciones = aAccionesBtn.aData
        this.cargando = false
      })
    },
    getTotalRegistros(){
      this.cargando = true
      axiosServices.getConfiguration('/dGrids_totalregistros', true, `sTable=${this.sTabla}&sIdForeignKey=${this.sNameForeignkey}&nIdValue=${this.idValue}`).then(aTotalRegistro =>{
        this.totalRegistros = aTotalRegistro.aData[0]['Cantidad']
        this.cargando = false;
      })
    },
    getHeaders(){
      this.cargando = true
      axiosServices.getConfiguration('/pGrids_headers', true, `sTable=${this.sTabla}`).then(aHeaders =>{
        this.headers = aHeaders.aData
        this.cargando = false;
      })
    },
    getItems(){
      if(!this.filtradoEstate){
        this.cargando = true
        let sParams = 'nopagina='+ this.paginaselect + '&' + 'noregistros=' + this.itemsPerPagina + `&sTable=${this.sTabla}` + `&sIdForeignKey=${this.sNameForeignkey}` + `&nIdValue=${this.idValue}`
        axiosServices.getConfiguration('/dGrids', true ,sParams).then(adataItems =>{
          this.cargando = false;
          this.dataItems = adataItems.aData
        })
      }
    },
    exportar(sTypeFile, bAll = false){
      this.bShowEx = true;
      this.sPropsType = sTypeFile;
      this.bExportAll = bAll;
    },
    nuevoItem(){
      this.keyForm += 1 
      this.itemEdit = {}
      this.formNew  = true
      this.showNew = !this.showNew
    },
    filtrar() {
      this.filtrarActive = !this.filtrarActive
    },
    getConsultaFiltro(aFiltro) {
      this.cargando = true
      
      // this.sParamsFiltro = (!this.filtradoEstate) ? aFiltro : this.sParamsFiltro ;
      if(!this.filtradoEstate){
        aFiltro[this.sNameForeignkey] = `= ${this.idValue}`     // fijo este valor en el filtro
        this.sParamsFiltro = aFiltro
      }

      let sParams = 'filtros=' + JSON.stringify(this.sParamsFiltro) + `&sTable=${this.sTabla}` 
      axiosServices.getConfiguration('/pGrids_filtrar', true, sParams).then(aFilter => {
        this.totalRegistros = aFilter.aData['TotalRegistros'][0].Cantidad
        this.dataItems = aFilter.aData['Data']
        this.cargando = false;
        this.filtradoEstate = true
      })
    },
    LimpiarFiltros() {
      this.sParamsFiltro = "";
      this.filtradoEstate = false
      this.refrescarItems()
    },
    btnActionEvento(oEvento){
      // manejo de evento de los tonones de action 
      // lleva un objeto con {btnname: '' , item: '' }
      switch (oEvento.btnName) {
        case 'btnEdit': {
          this.keyForm += 1
          this.formNew = false
          this.itemEdit = {...oEvento.item}
          this.showNew = !this.showNew
          break;
        }
        default: {
          break;
        }
      }
    },
    setExportModal(bNewValue) {
      this.bShowEx = bNewValue;
    },
  },
  computed: {
    onItem (){
      return this.formNew? { [this.sNameForeignkey] : this.idValue } : this.itemEdit
    },
    formatHeader(){
      // le doy formato al header añadiendo align: 'center', sortable: true OJO todo es un texto en value
      let Headers = this.headers
      Headers.forEach((value, Key) => {
        if(value.visible == '0'){
          Headers.splice(Key,1)
        } else {
          Headers[Key].align = 'center'
          Headers[Key].sortable = true
          Headers[Key].divider = true
          Headers[Key].class = 'titlesdark--text' 
        }
      })
      // Headers.push({value:'prueba', text: 'Prueba',filtro:'0',visible: '1', width:'80', tipo:'',InnerJoin:'',align:'center',divider:true, class:'titlesdark--text font-weight-bold' })
      if(this.showAcciones){
        Headers.push({value:'actions', text:'ACCIONES',filtro:'0',visible: '1', width:'300', tipo:'',InnerJoin:'',align:'center',divider:true, class:'titlesdark--text' })
      }
      return Headers
    },
    pageLength(){
      return Math.ceil(this.totalRegistros/this.itemsPerPagina)
    },
  },
  watch: {
    paginaselect() {
      this.getItems()
      this.nPropPageSel = this.paginaselect;
    },
    itemsPerPagina() {
      this.nPropsItemPerPage = this.itemsPerPagina;
      this.paginaselect = 1;
      this.getItems()
    },
    idValue(sNewValue, sOldValue) {
      if (sNewValue != sOldValue) {
        this.refrescarItems()
      }
    }
  },
}
</script>

<style lang="scss" >

  .rounded-basic{
    border-radius: 13px!important;
  }
  .v-data-table-header {
    background-color : #F5F6FA!important;
  }
  /* .v-icon.notranslate.fas.fa-check-square.theme--light{ */
  .v-data-table__checkbox.v-simple-checkbox i{
    font-size: 14px !important;
  }
  .HeaderOculto {
    display: none;
  }
  .v-data-table td {
    font-size: 0.7rem !important;
  }
  
  // Ajuste del tamaño del icono de busqueda
  .v-input__icon--append{
    i{
      font-size: 20px!important;
    }
  }
 
  .v-pagination__item{
    box-shadow: none!important;
    color: var(--v-colortextinfo-base)!important;
  }
  // Ajuste de color en la paginación de la grid
  .v-pagination__navigation{
    box-shadow: none!important;
  }
  .v-pagination__item--active{
    color: white!important;
  }
  .container-search{
    font-size: 0.8em!important;
  }
  .cont-acciones{
    margin-left: auto!important;

  }
  .font-titulo{
    font-weight: 500!important;
    line-height: 1.2em;
    padding-top: 10px!important;
    padding-bottom: 10px!important;
  }
  .col-nopadding{
    padding: 0!important;
  }
  .resaltado{
    border: solid 1px var(--v-primary-base);
    text-align: center;
    line-height: 0.8em;
    display: flex;
    justify-items: center;
    align-items: center;
    padding: 6px 4px;
    border-radius: 3px;
    font-size: 1em;
    margin-left: 4px;
    color: var(--v-primary-base);
  }
  .container {
    padding: 0px 16px!important;
  }
  .select-registros .v-input__slot{
    min-height: 30px!important;
  }
  .select-registros .v-input__append-inner{
    margin-top:0px!important;
  }
  .text-registros{
    margin-bottom: 0px!important;
  }
  .col-pagination{
    padding: 10px;
    border-radius: 10px!important;
  }
  .container__comp--btn{
    display: flex;
    justify-content: center;
  }
  .col-registros{
    border-radius: 10px!important;
  }
  
</style>