<template>
  <v-tooltip v-if="onValidarBoton" bottom>
    <template v-slot:activator="{ on }" >
      <v-btn color="primary" class="mx-1" outlined width="27px" height="27px" icon v-on="on" @click="emitEvento()">
        <v-icon size="13px" class="btncolor--text mx-1">
          {{iconb[0].iconname }}
        </v-icon>
      </v-btn>
      <h3></h3>
    </template>
    <span>{{HoverMensaje}}</span>
  </v-tooltip>
</template>

<script>

export default {
  name:"BtnGrid",
  props: {
    item: {
      type:Object,
    },
    HoverMensaje:{
      type: String,
      default: 'Sin Mensaje'
    },
    oPermisos: {
      type: Object,
      default: () => {}
    },
    typebtn:null
  },
  data() {
    return {
        listbtn:[
           {name:'BtnAddUser', iconname:'fa-user-plus', eventName:'btnAddUser'},
           {name:'BtnColor', iconname:'fa-palette', eventName:'btnColor'},
           {name:'BtnDetalleCliente', iconname:'fa-eye',eventName:'btnDetails'},
           {name:'BtnEdit', iconname:'fa-pencil-alt',eventName:'btnEdit'},
           {name:'BtnMapa', iconname:'fas fa-map-marker-alt',eventName:'btnMapa'},
           {name:'BtnFirmas', iconname:'fas fa-signature',eventName:'btnFirmas'},
          {name:'BtnImagenes', iconname:'fas fa-images',eventName:'btnImagenes'},
           {name:'BtnBuildCorreos', iconname:'fa-pencil-alt',eventName:'btnBuildCorreos'},
        ],
        iconb:[{name:'BtnDetalleCliente', iconname:'fa-home'}]

    }//fin return
  }, // Fin data
  mounted() {
    this.cambiarIcono(this.typebtn)
  },
  methods: {
      cambiarIcono(val){
          this.iconb = this.listbtn.filter(function(item){
              return item.name == val
      })
    },
    emitEvento(){
        this.$emit('btnActionEvento',{btnName: this.iconb[0].eventName, item: this.item })
    },
  },
  computed: {
    onValidarBoton(){
      let oPermitir = true
      if(this.typebtn=='BtnBuildCorreos' || this.typebtn=='BtnEdit'){
        if(this.oPermisos.priv_update=='0') oPermitir = false
      }

      return oPermitir
    }
  }
}

</script>

<style scoped>

</style>
