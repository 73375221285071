<template>
  <div>
    <span :class="[color, color !='' ? 'white--text': '']">{{valor == "1" ? 'SI' : 'NO'}}</span>
  </div>
</template>
<script>
import mixinGridComponenteCelda from "@/mixins/GridComponenteCelda.js"

export default {
  name: "CeldaSiNo",
  mixins: [mixinGridComponenteCelda],
};

</script>

<style scoped></style>