<template>
  <v-container>
    <v-row justify="center" align="center" class="under-sec-nomargin">
      <v-col xl="5" lg="7" md="9" sm="12" cols="12" class="py-0 ma-0">
        <span class="titleslight--text">Seleccione los campos de filtrado</span>
        <v-select
          v-model="FiltrosActivos"
          :items="getCamposFiltro"
          item-value="key"
          chips
          multiple
          outlined
          dense
        ></v-select>
      </v-col>  
      <v-col xl="2" lg="2" md="3" sm="12" cols="12">
        <v-btn
          color="btncolor"
          class=""
          :disabled="FiltrosActivos.length == 0"
          small
          dark
          outlined
          @click="ejecutar">
          Ejecutar
          <v-icon dark small right>fa-play</v-icon>
        </v-btn>
      </v-col>  
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6"
        v-for="(Filtro,index) in getFiltrosActivos"
        :key="index" class="cont-col">
        <component class="pa-0 ma-0"
          :is="Filtro.tipo"
          :title="Filtro.text"
          :indice="Filtro.value"
          :campo="Filtro.value"
          :inner="Filtro.InnerJoin"
          :bSubdominioTemporal="bSubdominioTemporal"
          v-model="aFiltro[Filtro.value]"
          @remove="remove"
        ></component>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import texto from '@/components/Grid/Filter_Component/Texto.vue'
import numero from '@/components/Grid/Filter_Component/Numero.vue'
import datetext from '@/components/Grid/Filter_Component/DateText.vue'
import selector from '@/components/Grid/Filter_Component/Selector.vue'
import select_user from '@/components/Grid/Filter_Component/Select_user.vue'
import campoSiNo from '@/components/Grid/Filter_Component/CampoSiNo.vue'
import campoBuenoRegularNA from '@/components/Grid/Filter_Component/CampoBuenoRegularNA.vue'

export default {
  name:"FilterSecction",
  props:{
    Headers: {
      type: Array,
    },
    value: {
      type: Object
    },
    bSubdominioTemporal:{ //Indica que se activa puente con subdominio temporal
      type: Boolean,
      default: false
    }
  },
  components:{
    texto,
    numero,
    datetext,
    selector,
    select_user,
    campoSiNo,
    campoBuenoRegularNA,
  },
  data() {
    return {
      FiltrosActivos : [],
      aFiltro        : this.value  || {}
    }
  },
  mounted () {
  },
  methods: {
    remove(indice){
      // console.table(indice);
      this.FiltrosActivos.splice(this.FiltrosActivos.indexOf(indice),1)
      delete this.aFiltro[indice]
    },
    ejecutar(){
      this.$emit('ejecutarFiltro',this.aFiltro)
    },
  },
  computed: {
    getFiltrosActivos() {
      let filtros = []
      this.Headers.forEach( header =>{
        if(this.FiltrosActivos.indexOf(header.value) >= 0){
          filtros.push(header)
        }
      })
      return filtros 
    },
    getCamposFiltro() {
      let campos= []
      this.Headers.forEach( header =>{
          if(header.filtro == 1){
            let oCampos = {
              "key": header.value,
              "text": header.text
            };
            campos.push(oCampos)
          }
      })
      // console.log(campos)
      return campos
    },
  },
}
</script>
<style scoped>
.cont-col{
  grid-gap: 20px!important;
  padding: 15px;
}
</style>