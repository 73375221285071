<template name="component-name">
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 594 473" style="enable-background:new 0 0 594 473;" xml:space="preserve">
  <!-- <style type="text/css">
    .st0{fill:url(#SVGID_1_);}
    .st1{fill:none;stroke:#FFFFFF;stroke-width:18;stroke-linecap:round;stroke-linejoin:round;}
  </style> -->
  <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="418.177" y1="215.7732" x2="157.8448" y2="215.7732">
    <stop  offset="0" style="stop-color:#001659"/>
    <stop  offset="0.9987" style="stop-color:$color-primary"/>
  </linearGradient>
  <circle class="st0" cx="288" cy="215.8" r="130.2"/>
  <g id="Dashboard_7_">
    <g id="_x31__7_" transform="translate(-149.000000, -137.000000)">
      <path id="Path-5_7_" class="st1" d="M385.3,389v13.8c0,0,5.7,15.2,20.9,23.9c15.2,8.8,29.5,12,29.5,12s12.5-0.7,29.5-11.6
        s26.7-26.1,26.7-26.1v-48.2c0,0-56.1-9.2-56.2-9.2l-53.5,9.2c0-20.4,0-40.7,0-48.8c0-12.1,7.3-37,49.6-37s51.9,24.3,53.6,36.4
        c0,0.2,0,17.7,0,17.7"/>
    </g>
  </g>
  </svg>
</template>
<script>
  export default {
    name:"avatarIcon"
  }
</script>
<style type="text/css">
  .st0{fill:url(#SVGID_1_);}
  .st1{fill:none;stroke:#FFFFFF;stroke-width:18;stroke-linecap:round;stroke-linejoin:round;}
</style> 