<template>
  <div>
    <span :class="[color, color !='' ? 'white--text': '']" v-html="textoHTML"></span>
  </div>
</template>
<script>
import mixinGridComponenteCelda from "@/mixins/GridComponenteCelda.js"

export default {
  name: "CeldaTexto",
  mixins: [mixinGridComponenteCelda],
  computed: {
    textoHTML() {
      return this.valor ? this.valor.replace('\n','<br>') : ''
    }
  },
};

</script>

<style scoped>
</style>