import axios from 'axios'
import params from '@/params/params.js'
import NProgress from 'nprogress'
import store from '@/store/index.js'
import Base64ToFile from '@/Utlidades/Base64ToFile.js'


const apiClient = axios.create({
  baseURL: `${params.path}`,
})

let oConfigBase = {
  headers: {
    'Content-Type'  : 'application/json'
  }
}

let oConfigFile = {
  headers: {
    'Content-Type'  : 'multipart/form-data',
  }
}
NProgress.configure({
  parent: '.v-main',
  showSpinner: false ,
  trickle: true,
  // template: '<div class="cover-page-load"></div><div class="bar bar-primary striped-app" role="bar"></div>'
  template: '<div class="bar bar-primary striped-app" role="bar"></div>'
});
// Se crean interceptore para poner la barra de progreso
apiClient.interceptors.request.use(config => { // Called on request
  NProgress.start()
  return config
})
apiClient.interceptors.response.use(response => { // Called on response
  NProgress.done()
  if(store.state.aDiccionario.length>0){
    let sData = JSON.stringify(response.data)
    store.state.aDiccionario.forEach(item => {
      sData = sData.replace( `??${item.variable}??`, `${item.equivalencia}`);
    })
    response.data = JSON.parse(sData)
  }
  return response
},(error)=>{
  NProgress.done()
  //console.log('error', error)
  switch(error.response.data.nStatusCode){
    case 401: // Cualquier error de Login
      // console.log('dentro 401')
        store.dispatch('notificaciones/SetMensaje','Notificación: '+ error.response.data.sMessage );
        store.dispatch('notificaciones/ShowNotificacion',true);    
        store.dispatch('setLogin', false).then(() => {
        window.localStorage.clear()
        sessionStorage.clear()
        let URLdomain = window.location
        window.location.href = `${URLdomain.origin}`
      });
    break
    case 412: // Registro Duplicado
    // console.log('dentro 401')
      store.dispatch('notificaciones/SetMensaje','Notificación: '+ error.response.data.sMessage );
      store.dispatch('notificaciones/ShowNotificacion',true);        
    break
  }
  return error.response
})
export default {
  // Metodos get
  getConfiguration(sURL, bBearer = false, sParams = '' ) {
    let  sComplementURL = `?origen=WEB&idSubProyecto=${store.state.idSubProyecto}&ip=${sessionStorage.ip}&subdominio=${store.state.sSubDominio}&v=${new Date().getTime()}`
    if(store.state.idCliente != '' && store.state.idCliente != '0'){
      sComplementURL += `&idCliente=${store.state.idCliente}`
    }
    if (bBearer) {
      oConfigBase.headers.Authorization = `Bearer ${store.state.token}`
    }
    if(sParams !== ''){
      sComplementURL+= '&' + sParams
    }
    // Pass parameter openWindow=true in sParams to open in new screen
    if(typeof sParams === 'string' && sParams.indexOf('openWindow=true') !== -1){
      return window.open(`${params.path}${sURL}${sComplementURL}`);
    }
    // Valido el parametro de responseType=blob
    if(typeof sParams === 'string' && sParams.indexOf('responseType=blob') !== -1){
      oConfigBase.responseType = 'blob'
    }
    return apiClient.get(`${sURL}${sComplementURL}` , oConfigBase).then(aGetData => aGetData.data)
  },
  // Metodos POST
  postConfiguration(sURL, oBody, bBearer = false, sParams = '') {
    let sComplementURL = `?origen=WEB&ip=${sessionStorage.ip}&subdominio=${store.state.sSubDominio}&v=${new Date().getTime()}`;
    if(store.state.idCliente != '' && store.state.idCliente != '0'){
      sComplementURL += `&idCliente=${store.state.idCliente}`
    }
    if (bBearer) {
      oConfigBase.headers.Authorization = `Bearer ${store.state.token}`;
    }
    if(sParams !== ''){
      sComplementURL+= '&' + sParams
    }
    oBody.nIdUser = store.state.idUser
    return apiClient.post(`${sURL}${sComplementURL}`, oBody, oConfigBase)
     .then(aResponsePOST => aResponsePOST.data);
  },
  // Metodo PUT
  putConfiguration(sURL, oBody, bBearer = false, sParams = '') {
    let sComplementURL = `?origen=WEB&ip=${sessionStorage.ip}&subdominio=${store.state.sSubDominio}&${store.state.sParamsExt}&v=${new Date().getTime()}`;
    if(store.state.idCliente != '' && store.state.idCliente != '0'){
      sComplementURL += `&idCliente=${store.state.idCliente}`
    }
    if (bBearer) {
      oConfigBase.headers.Authorization = `Bearer ${store.state.token}`;
    }
    if(sParams !== ''){
      sComplementURL+= '&' + sParams
    }
    oBody.nIdUser = store.state.idUser
    return apiClient.put(`${sURL}${sComplementURL}`, oBody, oConfigBase)
     .then(aResponsePUT => aResponsePUT.data);
  },
  // Metodo para post file debe surbir un FormData()
  //   let formData = new FormData()
  //   formData.append('file', file)  -> file es la fila como tal
  // Tipo Anexo 1 Foto 2 DOc
  postFile(sURL, FormData, bBearer = false,nTipoAnexo =1) {
    let sComplementURL = `?nTipoAnexo=${nTipoAnexo}&origen=WEB&ip=${sessionStorage.ip}&subdominio=${store.state.sSubDominio}&${store.state.sParamsExt}&v=${new Date().getTime()}`;
    if(store.state.idCliente != '' && store.state.idCliente != '0'){
      sComplementURL += `&idCliente=${store.state.idCliente}`
    }
    if (bBearer) {
      oConfigFile.headers.Authorization = `Bearer ${store.state.token}`;
    }
    
    return apiClient.post(`${sURL}${sComplementURL}`, FormData, oConfigFile)
    .then(aResponsePOST => {
      try {
       return aResponsePOST.data
      } catch (error) {
        return []
      }
    }) 
  },
  // Metodo para enviar fotos como files recibiendo com base 64
  async postFoto(sNombreArchivo, sBase64){
    const file = await Base64ToFile.Base64toFile(sBase64, sNombreArchivo,'image/png')

    let formData = new FormData()

    formData.append('file', file)

    return this.postFile('/subirarchivo', formData, true)

  },
}
