<template>
<div >
  <span class="titleslight--text">{{titulo}}</span>
  <span v-if="obligatorio" class="titleslight--text font-weight-bold ml-1">*</span>
    <v-autocomplete
      class="mt-1"
      v-model="valor"
      :items="itemsData"
      item-text="texto"
      item-value="id"
      :placeholder="Mensaje"
      :loading="loadSelect"
      :disabled="bEditable"
      height="30px"
      clearable
      outlined
      dense
      :error="esError"
      :rules="noVacio">
      <template slot="no-data">
        <div>No hay información</div>
      </template>
    </v-autocomplete>
</div>
</template>

<script>
import axiosServices from "@/services/axiosServices.js"
import { mapGetters } from 'vuex'

  export default {
    name: 'FormSelect',
    props: {
      value: {
      },
      titulo:{
        type:String,
        default:''
      },
      obligatorio:{
        type:Boolean,
        default: false
      },
      inner:{
        type: String,
        default: ''
      },
      campo:{
        type: String,
        default: ''
      },
      editable: {
        type: String,
        default: ''
      },                        // condiciones para filtrar la informacion que se muestra
      condicion:{
        type: String,
        default: ''
      },
    },
    data() {
      return {
        valor           : this.value,
        itemsData       : [],
        loadSelect      : false,
        Mensaje         : 'Cargando..',
        esError         : false,
        noVacio         : [v => !!v],
        bEditable       : (this.editable == "0") ? true : false,
      }
    },
    mounted () {
      this.getItems()
    },
    methods: {
      getItems(){
        let sParams = ''
        if(this.condicion != null || this.condicion != ""){
          sParams = "condicion=" + this.condicion
        }
        let sAux = this.inner != null ? this.inner.split(',') : '' 
        this.loadSelect = true
        this.Mensaje = 'Cargando ..'
        axiosServices.getConfiguration('/select/' + sAux[0] + '/' + this.campo , true, sParams ).then(aItems =>{
          this.itemsData = aItems.aData
          this.loadSelect = false
          this.Mensaje = 'Seleccione ' + this.titulo
        })
      },
    },
    computed: {
      ...mapGetters(['getoFormError'])
    },
    watch: {
      valor(NewValor){
        this.$emit('input',NewValor)
        if(this.esError && this.valor != ''){
          this.esError = false
        }
      },
      value(NewValue){
        this.valor = NewValue
      },
      getoFormError(){
        this.esError = this.getoFormError[this.campo]
      }
    },
    
  }
</script>

<style scoped>


</style>