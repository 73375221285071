<template>
<!-- <div> -->
  <v-dialog v-model="bShowMap" persistent max-width="680px">
    <v-card>
      <v-toolbar
        color="headformsdark"
        height="45"
        elevation="0"
        dense
        dark>
        <v-spacer></v-spacer>
        <span class="mdlHeadForms">Ubicación del Registro</span>
        <v-spacer></v-spacer>
        <v-btn 
          color="btncolor-dark"
          @click="CloseDialog()"
          icon
          small
          dark
          outlined>
          <v-icon size="14px">fa fa-times</v-icon>
        </v-btn>
      </v-toolbar>
        <!-- <section> -->
    <MapaView v-if="lat!=''" ref="map"
      :lat="lat"
      :lng="lng"
      :dimencion="dimencionMapa">
    </MapaView>
    <!-- </section> -->
    </v-card>  


  </v-dialog>
            
  <!-- </div> -->
</template>

<script>
import MapaView from "@/components/Mapas/mapLocalizar.vue"


export default {
  name:"FormMapa",
  props: {
    bShowMap: {
      type: Boolean,
      default: false
    },
    oDataParent: {
      type: Object,
      default: () => {}
    },
  },
  components: {
    MapaView,
  },
  data() {
      return {
        dimencionMapa : { alto: '500px' , ancho  : '98%'},
        lat : '',
        lng : '',
      }
  },
  mounted(){
    const sLatitud = this.onValidarCoordenada(1)
    const sLongitud = this.onValidarCoordenada(2)
    this.lat = this.oDataParent[sLatitud]
    this.lng = this.oDataParent[sLongitud]
    //console.log(this.lat,this.lng, this.oDataParent)
    // Debido a que no se dibujaba me toco reibujarlo mirar los metodos del mapa leaflet https://leafletjs.com/reference-1.7.1.html
    // se debe llegar hata la ref del componente por eso el doble $refs
    // Empleo un metodo de redibujar el mapa cuando el tamaño cambia.
    this.$refs.map.$refs.map.mapObject.invalidateSize()

  },
  methods: {
    CloseDialog() {
      this.$emit('input', false)
    },
    onValidarCoordenada(nTipo){ // 1 Latitud 2 Longitud
      if(nTipo==1){
        if (this.oDataParent.hasOwnProperty('Latitud')){
          return 'Latitud'
        }else if (this.oDataParent.hasOwnProperty('latitud')){
          return 'latitud'
        }else{
          return 'lat'
        }
      }else{
        if (this.oDataParent.hasOwnProperty('Longitud')){
          return 'Longitud'
        }else if (this.oDataParent.hasOwnProperty('longitud')){
          return 'longitud'
        }else{
          return 'lng'
        }
     }
    }
  },
  
}
</script>

<style lang="scss" scoped>

</style>