import { render, staticRenderFns } from "./DataCard.vue?vue&type=template&id=2f4a3351&scoped=true&"
import script from "./DataCard.vue?vue&type=script&lang=js&"
export * from "./DataCard.vue?vue&type=script&lang=js&"
import style0 from "./DataCard.vue?vue&type=style&index=0&id=2f4a3351&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f4a3351",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
installComponents(component, {VCard,VCardText,VCol,VContainer,VImg,VProgressCircular,VRow,VSheet,VSlideGroup,VSlideItem})
