const state = () => ({
  mensajeNotificacion : "",
  tituloNotificacion : "",
  snackbar : false, 

})

const getters = {
  gettituloNotificacion: ( state ) => {
    return state.tituloNotificacion;
  },
  getmensajeNotificacion: ( state ) => {
    return state.mensajeNotificacion;
  },
  getsnackbar: (state) => {
    return state.snackbar;
  },
} 

const mutations = {
  MENSAJE : ( state, {title, message} ) => {
    state.tituloNotificacion = title;
    state.mensajeNotificacion = message;
  },
  NOTIFICAR : ( state, estado ) => {
    state.snackbar = estado;
  },
}

const actions = {
  SetMensaje( {commit}, {title, message} ) {
    commit( "MENSAJE", {title, message});
  },
  ShowNotificacion( {commit}, estado ) {
    commit("NOTIFICAR", estado);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}