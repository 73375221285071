<template>
<div :class="onModo">
  <div v-if="!obligatorio" class="titleslight--text mt-2">{{titulo}} </div>
  <div v-else class="mt-2"> 
    <span class="titleslight--text">{{titulo}}</span>
    <span class="titleslight--text font-weight-bold ml-1"> * </span>
  </div>
  <div class="cont-valor mt-1 pt-1">
    <p> {{ valor }}</p>
  </div>
</div>
</template>

<script>
import axiosServices from "@/services/axiosServices.js"

  export default {
    name: 'CampoLectura',
    props: {
      value: {
      },
      titulo:{
        type:String,
        default:''
      },
      obligatorio:{
        type:Boolean,
        default: false
      },
      inner:{
        type: String,
        default: ''
      },
      campo:{
        type: String,
        default: ''
      },
      modo: {
        type: Number,
        default: 1 // 1 - Apilado | 2 -Secuencial
      },
      aItems: {
        type: Array,
        default: function () {
          return []
        }
      }
    },
    data() {
      return {
        valor           : '',
        itemsData       : [],
      }
    },
    mounted () {
      if(this.inner!='' && this.campo){
        this.valor = 'Cargando datos...'
        this.getItems()
      }else{
        this.valor = this.value
      }

      if(this.aItems.length>0){
        this.aItems.forEach(item =>{
          if (item.id==this.value) this.valor = item.texto
        })
      }
    },
    methods: {
      getItems(){
        let sAux = this.inner != null ? this.inner.split(',') : '' 
        this.loadSelect = true
        axiosServices.getConfiguration('/select/' + sAux[0] + '/' + this.campo , true ).then(aItems =>{
          this.itemsData = aItems.aData
          this.itemsData.forEach(item =>{
            if (item.id==this.value) this.valor = item.texto
          })
        })
      },
    },
    computed: {
      onModo () {
        return (this.modo == 1) ? 'd-flex flex-column' : 'd-flex flex-row'
      }
    }
  }
</script>

<style lang="scss" scoped>
.cont-valor{
  width: 100%!important;
  p{
    margin: 0;
  }
}

</style>