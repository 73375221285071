<template>
  <v-row justify="center">
    <v-dialog v-model="value" persistent max-width="600px">
        <v-progress-linear v-if="cargando"
          indeterminate
          color="titlesdark darken-2"
        ></v-progress-linear>
      <v-card>
        <v-toolbar
          color="headformsdark"
          height="45"
          elevation="0"
          dense dark>
          <v-spacer></v-spacer>
          <span v-if="modNuevo" class="mdlHeadForms"> Nuevo {{titulo}}</span>
          <span v-else class="mdlHeadForms"> Editar {{titulo}}</span>
          <v-spacer></v-spacer>
          <v-btn 
            color="btncolor-dark"
            dark
            @click="CloseDialog()"
            icon
            small
            outlined>
            <v-icon size="14px">fa fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row >
              <v-col sm="12" cols="12" :md="campo.cols" :lg="campo.cols" :xl="campo.cols" v-for="(campo, index) in campos" :key="index"  class="py-0 ma-0 ColumnaResumida">
                <component :is="campo.tipo" 
                  v-model="oFormCampos[campo.nombre]"
                  :titulo="campo.label"
                  :obligatorio="campo.obligatorio == 1 ? true : false"
                  :condicion="campo.Condicion"
                  :inner="campo.innerJoin"
                  :campo="campo.nombre"
                  :editable="campo.editable"
                  :nCiudad="oFormCampos.NombreCiudad">
                </component>
              </v-col>
            </v-row>
          </v-container>
          <small class="d-flex justify-end"><span class="titleslight--text font-weight-medium">* Campo Obligatorio</span></small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark outlined color="btncolor" @click="limpiarCampos" :disabled="btnEnable">Limpiar</v-btn>
          <v-btn dark outlined v-if="modNuevo" color="btncolor" @click="salvar" :disabled="btnEnable">Guardar</v-btn>
          <v-btn dark outlined v-else color="btncolor" @click="actualizar" :disabled="btnEnable">Actualizar</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
      <error-view v-model="bMostrarErrores" :aErrores="aErroresMensaje"></error-view>
    </v-dialog>
  </v-row>
</template>

<script>
import axiosServices from '@/services/axiosServices.js'
import numero from '@/components/Form/Componentes/FormNumero.vue'
import texto from '@/components/Form/Componentes/FormTexto.vue'
import datetext from '@/components/Form/Componentes/FormDate.vue'
import dateRango from '@/components/Form/Componentes/FormDateRango.vue'
import selector from '@/components/Form/Componentes/FormSelect.vue'
import selectorMultiple from '@/components/Form/Componentes/FormMultiSelect.vue'
import correo from '@/components/Form/Componentes/FormCorreo.vue'
import campoSiNo from '@/components/Form/Componentes/FormSiNo.vue'
import campoSwitch from '@/components/Form/Componentes/FormSwitch.vue'
import archivo from '@/components/Form/Componentes/FormFile.vue'
import direccion from '@/components/Form/Componentes/FormDireccion.vue'
import latitud from '@/components/Form/Componentes/FormLatitud.vue'
import longitud from '@/components/Form/Componentes/FormLongitud.vue'
import activoInactivo from '@/components/Form/Componentes/FormActivoInactivo.vue'
import selectordoble from '@/components/Form/Componentes/FormDoubleSelect.vue'
import selectordobledependiente from '@/components/Form/Componentes/FormDoubleSelectDep.vue'
import pickcolor from '@/components/Form/Componentes/FormColor.vue'
import textolargo from '@/components/Form/Componentes/FormTextArea.vue'
import ErrorView from '@/components/ErrorView.vue'

export default {
  name:'Form',
  components: {
    numero,
    texto,
    datetext,
    dateRango,
    selector,
    correo,
    selectorMultiple,
    campoSiNo,
    campoSwitch,
    archivo,
    direccion,
    latitud,
    longitud,
    activoInactivo,
    selectordoble,
    selectordobledependiente,
    pickcolor,
    textolargo,
    ErrorView,
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    tableName: {
      type: String,
      defaul: ''
    },
    oItem: {
      // objeto con los valores del Item a modificar en caso de edit
      type:Object,
      defaul:{}
    },
    modNuevo:{
      type:Boolean,
      default:true
    },
    titulo: {
      type: String,
      default: ''
    }
    
  },
  data() {
    return {
      cargando        : false,                            // empleada para indicar que el form esta cargando
      campos          : [],                               // arreglo de campos del formulario  
      oFormCampos     : {},
      btnEnable       : false,
      idItem          : `id${this.tableName}`,
      aErroresMensaje : [],                               // Arreglo de mensajes para la plataforma,
      bMostrarErrores : false                             // variableque muestra los errores
    }//fin return
  },// Fin Data
  mounted(){
    this.getCampos()
    this.loadDataEdit()
  },
  methods: {
    loadDataEdit(){
      if(!this.modNuevo){
        this.modNuevo = false
        this.cargando = true
        let sParam = `sTable=${this.tableName}` + '&Item=' + this.oItem[this.idItem]
        axiosServices.getConfiguration('/formulario_item', true, sParam ).then(aItemData =>{
          this.oFormCampos = aItemData.aData[0]
          this.cargando = false
        })
      }else{
         if(Object.keys(this.oItem).length>0){
          this.oFormCampos = this.oItem
        }
      }
    },
    getCampos(){
      this.cargando = true
      let sParams = 'sTable='+ this.tableName
      axiosServices.getConfiguration('/formulario_campos',true, sParams).then(aCampos => {
        this.campos = aCampos.aData
        this.cargando = false
      })
    },
    CloseDialog() {
      this.oFormCampos = {}
      this.$emit('input', false)
    },
    async salvar(){
      this.btnEnable = true  

      if(this.validarCampos()){
        let sParams = 'sTable='+ this.tableName
        this.cargando = true
        let oResponse =  await axiosServices.postConfiguration('/formulario', this.oFormCampos, true, sParams)
        if (oResponse.sStatus == 'success'){
          this.cargando = true
          this.$swal({
            timer: 1500,
            icon:`${oResponse.sStatus}`,
            title:`${oResponse.sMessage}`,
            showConfirmButton: false
          })
          this.$emit('saveOK')
          this.oFormCampos = {}
          this.CloseDialog()
        }
      }else{
        this.btnEnable = false
        this.cargando = false
        this.bMostrarErrores = true
      }
    },
    validarCampos(){
      // esta funcion valida que los campos obligatorios esten completos y regrese un objeto de los errores true indica que hay error
      // tomo el objeto de elementos y los recorro uno a uno
      let oFormError      = {}
      let aux_nombreCampo = ''
      let bRspVAlidar     = true   // variable empleada para dar la respueta de si se continua con el post o no

      for (let prop in this.campos ){
        aux_nombreCampo = this.campos[prop].nombre
        
        // valido si es obligatorio y si tienen valor
        if(this.oFormCampos[aux_nombreCampo] != undefined){
          if(this.campos[prop].obligatorio == '1' && this.oFormCampos[aux_nombreCampo] != ''){
            oFormError[aux_nombreCampo] = false
          }else{
            if(this.campos[prop].obligatorio == '0'){
              oFormError[aux_nombreCampo] = false
            }else{
              this.aErroresMensaje.push('Campo '+ this.campos[prop].label+ ' obligatorio.')
              oFormError[aux_nombreCampo] = true
              bRspVAlidar = false
            }  
          }  
        }else{
           if(this.campos[prop].obligatorio == '0'){
              oFormError[aux_nombreCampo] = false
            }else{
              this.aErroresMensaje.push('Campo '+ this.campos[prop].label+ ' obligatorio.')
              oFormError[aux_nombreCampo] = true
              bRspVAlidar = false
            }  
        }
      }
      this.$store.dispatch('saveoFormError',oFormError)
      return bRspVAlidar

    },
    limpiarCampos(){
      this.oFormCampos = {}
    },
    async actualizar(){
      this.btnEnable = true  

      if(this.validarCampos()){
        let sParams = 'sTable='+ this.tableName
        let oPut = {}
        oPut.nuevo = this.oFormCampos
        oPut.viejo = this.oItem
        oPut.nuevo.id = this.oItem[this.idItem]
        this.cargando = true
        let oResponse =  await axiosServices.putConfiguration('/formulario', oPut, true, sParams)
        if (oResponse.sStatus == 'success'){
          this.cargando = false
          this.btnEnable = false
          this.$swal({
            timer: 1500,
            icon:`${oResponse.sStatus}`,
            title:`${oResponse.sMessage}`,
            showConfirmButton: false
          })
          this.oFormCampos = {}
          this.$emit('saveOK')
          this.CloseDialog()
        }
      }else{
        this.btnEnable = false
        this.cargando = false
        this.bMostrarErrores = true
      }      

    },

  },
  computed: {
  },
}
</script>

<style scoped>
 .ColumnaResumida{
    max-height: 65px;
  }
</style>