<template>
<div >
  <span class="titleslight--text font-weight-medium">{{titulo}}</span>
  <span v-if="obligatorio" class="titleslight--text font-weight-bold ml-1">*</span>
  <double-select
    :items="itemsData"
    :selectedItems="aItemsSelected"
    :searchable="true"
    text-field="texto"
    value-field="id"
    disabled="bEditable"
    :searchablePlaceholder="Searchstates">
  </double-select>
</div>
</template>

<script>
import axiosServices from "@/services/axiosServices.js"
import { mapGetters } from 'vuex'
export default {
  name: 'FormDoubleSelect',
  props: {
    value: {
    },
    titulo:{
      type:String,
      default:''
    },
    obligatorio:{
      type:Boolean,
      default: false
    },
    inner:{
      type: String,
      default: ''
    },
    campo:{
      type: String,
      default: ''
    },
    editable: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      aItemsSelected  : this.value,
      itemsData       : [],
      Searchstates    : `Buscar`,
      bEditable       : (this.editable == "0") ? true : false,
    }
  },
  mounted () {
    this.getItems()
  },
  methods: {
    getItems(){
      let sAux = this.inner.split(',')
      this.loadSelect = true
      axiosServices.getConfiguration('/select/' + sAux[0] + '/' + this.campo , true ).then(aItems =>{
        this.itemsData = aItems.aData
      })
    },
  },
  computed: {
    ...mapGetters(['getoFormError'])
  },
  watch: {
    valor(NewValor){
      this.$emit('input',NewValor)
      if(this.esError && this.valor != ''){
        this.esError = false
      }
    },
    value(NewValue){
      this.valor = NewValue
    },
    getoFormError(){
      this.esError = this.getoFormError[this.campo]
    }
  },
}
</script>