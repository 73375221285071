import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js'
import clientes from '@/views/Clientes.vue'
import regionales from '@/views/Regionales.vue'
import departamentos from '@/views/Departamentos.vue'
import correos from '@/views/Correos.vue'
import firebase from "firebase/app"

/* SEIF MODULOS */
/* Minuta */
import MinutaAsuntos from '@/views/seif_modulos/minuta/asuntos.vue'
import Minutas from '@/views/seif_modulos/minuta/minuta.vue'
import ReportesSinGestion from '@/views/seif_modulos/riesgos/ReportesSinGestion.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import ('@/components/Login.vue')
  },
  {
    path: '/RestorePassword/:Hash',
    name: 'RestorePass',
    component: () => import ('@/views/newPass.vue')
  },
  {
    path: '/DashBoard',
    name: 'DashBoard',
    component: () => import ('@/views/DashBoard.vue'),
  },
  {
    path: '/FormDinamicoReporte/:id',
    name: 'FormDinamicoReporte',
    component: () => import ('@/components/FormDinamicos/FormDinamicoReporte.vue'), 
  },
  {
    path: '/InformeRiesgos',
    name: 'InformeRiesgos',
    component: () => import('@/components/Reportes/InformeRiesgos.vue'),
  },
  {
    path: '/GridDinamicos/:sIDForm',
    name: 'GridDinamicos',
    component: () => import('@/components/Grid/GridFormDinamicos.vue'),
  },
  {
    path: '/buildDinamicos',
    name: 'buildDinamicos',
    component: () => import('@/views/buildDinamicos.vue'),
  },
  {
    path: '/buildCorreos/:idCorreos',
    name: 'buildCorreos',
    component: () => import('@/views/buildCorreos.vue')
  }, 
  {
    path: '/FormDinamicPQR/:id',
    name: 'FormDinamicPQR',
    component: () => import ('@/views/FormDinamicPQR.vue'),
  },
  {
    path: '/ViewRspPQR/:id',
    name: 'ViewRspPQR',
    component: () => import ('@/views/RspFormDinamicPQR.vue'),
  },
  {
    path: '/ExportPQR',
    name: 'ExportPQR',
    component: () => import ('@/views/ExportPQRFormDinamic.vue'),
  },
  {
    path: '/MostrarImangen/:sFilename',
    name: 'MostrarImangen',
    component: () => import ('@/views/seif/Utils/MostrarImagen.vue'),
  },
  {
    path: '/Inicio/:Modulo',
    name: 'Inicio',
    component: () => import ('@/views/Contenedor.vue'),
    props: true,
    children: [
      {
        path: '/Inicio/:Modulo/clientes',
        name: 'clientes',
        components: {
          config: clientes,
        }
      },
      {
        path: '/Inicio/:Modulo/regionales',
        name: 'regionales',
        components: {
          config: regionales
          ,
        }
      },
      {
        path: '/Inicio/:Modulo/departamentos',
        name: 'departamentos',
        components: {
          config: departamentos
          ,
        }
      },
      {
        path: '/Inicio/:Modulo/correos',
        name: 'correos',
        components: {
          config: correos
          ,
        }
      },
      /* MODULO MINUTA */
      {
        path: '/Inicio/:Modulo/reportes-sin-gestion',
        name: 'ReportesSinGestion',
        components: {
          config: ReportesSinGestion
          ,
        }
      },
      /* MODULO MINUTA */
      {
        path: '/Inicio/:Modulo/asuntos',
        name: 'asuntos',
        components: {
          config: MinutaAsuntos
          ,
        }
      },
      {
        path: '/Inicio/:Modulo/minuta',
        name: 'minuta',
        components: {
          config: Minutas
          ,
        }
      },
      /* MODULO Actas reunion */
      {
        path: '/Inicio/:Modulo/acta-reunion/pqr',
        name: 'ActaReunionPQR',
        components: {
          config : () => import('@/views/seif/operaciones/ActaReunionPqr.vue')
        }
      },
      // Modulo Supervision Virtual 
      {
        path: '/Inicio/:Modulo/supervision',
        name: 'Supervision',
        components: { 
          config : () => import('@/views/seif/operaciones/Supervision.vue') 
        } 
      },
      // Modulo Elementos Control activos
      {
        path: '/Inicio/:Modulo/entradas-salidas',
        name: 'ControlActivosEntradasSalidas',
        components: { 
          config : () => import('@/views/seif_modulos/control-activos/EntradasSalidas.vue') 
        } 
      },
      {
        path: '/Inicio/:Modulo/qr',
        name: 'ControlActivosQr',
        components: { 
          config : () => import('@/views/seif_modulos/control-activos/Qr.vue') 
        } 
      },
      {
        path: '/Inicio/:Modulo/dashActivos',
        name: 'DashControlActivos',
        components: { 
          config : () => import('@/views/seif_modulos/control-activos/DashControlActivos.vue') 
        } 
      },
      // Configuracion - casos Correos
      {
        path: '/Inicio/:Modulo/casoscorreos',
        name: 'CasosCorreos',
        components: { 
          config : () => import('@/views/seif/Configuracion/CasosCorreos.vue') 
        } 
      },
    ]
  },
  {
    path: '/Import',
    name: 'Import',
    component: () => import('@/views/Import.vue'),
  }

]
// const urlParams = window.location.search.split('?')[1];
// store.dispatch('saveParamsProyect', urlParams)
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// interceptor para validar que se este logueado
router.beforeEach ((to, from, next) => {
    if(to.query.t != undefined && to.query.ip != undefined){
      // console.log("query","ingreso validacion exitosa")
      let sToken = to.query.t
      let sIP = to.query.ip
      store.dispatch('setLogin', true);
      store.dispatch('saveToken', sToken);
      sessionStorage.ip = sIP
      let sSubdominioTemporal = (to.query.st != undefined) ? to.query.st : ''
      let idCliente = (to.query.cl != undefined) ? to.query.cl : ''
      let nIdPerfil = (to.query.nPerfil != undefined) ? to.query.nPerfil : ''
      store.dispatch('saveSubdominioTemporal', sSubdominioTemporal);
      store.dispatch('saveIdCliente', idCliente);
      store.dispatch('saveIdPerfil', nIdPerfil);
    }
   // Manejo de Firebase para reportar ingresos
   let oLog = {
    page_location: window.location.origin+"/"+to.fullPath,
    page_path: to.fullPath,
    page_title: to.name
  }
  firebase.analytics().logEvent("page_view", oLog)
  // Manejo de Subdominio
  const urlParams = window.location.search.split('?')[1]
  let sSubdomain = ''
  if (typeof window.location.host.split('.')[0] !== 'undefined') {
    if (window.location.host.split('.')[0].includes('localhost')) {
      sSubdomain = 'desarrollo';
    } else {
      sSubdomain = window.location.host.split('.')[0]
    }
  } else {
    sSubdomain = 'desarrollo';
  }
  store.dispatch('saveSubDomain', sSubdomain);
  store.dispatch('saveParamsProyect', urlParams)
  switch (to.name) {
    case "login":
    case "RestorePass":
    case "GridDinamicos":
    case "buildDinamicos":
    case "FormDinamicoReporte":
    case "FormDinamicPQR":
    case "ViewRspPQR":
    case "ExportPQR":
    case "Import":
    case "asuntos":
    case "minuta":
    case "ControlActivos":
    case "Supervision":   
    case "ActaReunionPQR":
    case "MostrarImangen":  
    case "InformeRiesgos": {
      next();
      break;
    }
    default: {
      if (!store.state.login) {
        next('/');
      } else {
        next();
      }
      break;
    }
  }
})

export default router