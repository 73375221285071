<template>
  <div>
    <span class="titleslight--text font-weight-medium">{{ titulo }}</span>
    <span v-if="obligatorio" class="titleslight--text font-weight-bold ml-1">*</span>
    <double-select
      :items="itemsData"
      :selectedItems="aItemsSelected"
      :searchable="true"
      text-field="texto"
      value-field="id"
      :disabled="bEditable"
      :searchablePlaceholder="Searchstates">
    </double-select>
  </div>
</template>
<script>
import axiosServices from "@/services/axiosServices.js"
import { mapGetters } from 'vuex'
export default {
  name: 'FormDoubleSelectDep',
  props: {
    value: {
    },
    titulo:{
      type:String,
      default:''
    },
    obligatorio:{
      type:Boolean,
      default: false
    },
    inner:{
      type: String,
      default: ''
    },
    campo:{
      type: String,
      default: ''
    },
    editable: {
      type: String,
      default: ''
    },
    where: {
      type: String,
      default: ''
    },
    sIdParent: {
      type: String,
      default: ''
    },

  },
  data() {
    return {
      aItemsSelected  : [],
      itemsData       : [],
      Searchstates    : `Buscar`,
      bEditable       : (this.editable == "0") ? true : false,
    }
  },
  methods: {
    getItems(sIdParent) {
      this.loadSelect = true
      let sTable = this.inner.spli(',')
      let sParams = `sTable=${sTable[0]}&nIdParent=${sIdParent}&sWhere=${this.where}&sField=${this.campo}`;
      axiosServices.getConfiguration('/doubleselect', true, sParams)
      .then(aItems => {
        this.itemsData = aItems.aData;
      });
    },
  },
  computed: {
    ...mapGetters(['getoFormError'])
  },
  watch: {
    getoFormError() {
      this.esError = this.getoFormError[this.campo]
    },
    nIdParent(sNewValue) {
      this.getItems(sNewValue);
    },
  },
}
</script>