<template>
	<div>
		<v-card height="100%">
			<v-navigation-drawer fixed temporary right
				v-model="repover"
				:width="$vuetify.breakpoint.xs ? '93%' : $vuetify.breakpoint.md ? '85%' : $vuetify.breakpoint.lg ? '50%' : '35%' " 
				class="contendor-vista">
				<!-- GENERAR COMPONENTES, cada uno tiene una propiedad -->
				<v-btn 
				class="close-btn py-0 px-2" 
				@click="$emit('click', false)" 
				absolute depressed plain 
				min-width="auto">
					<v-icon color="primary" size="25px">fa-chevron-right</v-icon>
				</v-btn>
				<!-- Boton de exportar ******** -->
				<v-row class="py-2 px-3 col-right under-sec-flat">
					<v-btn color="primary" 
						outlined class="no-uppercase btn-rounded "
						small depressed :loading="exportStatus"
						@click="printSection()"> Exportar <v-icon size="13px" right>fa-file-export</v-icon>
					</v-btn>
				</v-row>
				<div id="printPage">
					<div>
						<CabeceraVista
							:aInfo="aEncabezado"
							>
						</CabeceraVista>
					</div>
					<div>
						<component v-for="(oDataShow, idx) in aDataShow" :key="'campo'+idx"
							:is="oDataShow.tipo"
							:aInfo="oDataShow.aData"
							:sNameDetails="sNameDetails"
							:nIdParent="oParent[`${sFielItem}`]"
							:hiddenElement="exportStatus"
							:sParametros="sParametros"
							:bSubdominioTemporal="bSubdominioTemporal"
							>
						</component>
					</div>
				</div>
			</v-navigation-drawer>
		</v-card>
	</div>
</template>
<script>
import axiosServices from '@/services/axiosServices.js'
import CabeceraVista from '@/components/Grid/Detalle/Componentes/CabeceraVista'
import InfoTablaVista from '@/components/Grid/Detalle/Componentes/InfoTablaVista'
import AsuntoVista from '@/components/Grid/Detalle/Componentes/AsuntoVista'
import TextoVista from '@/components/Grid/Detalle/Componentes/TextoVista'
import FotosVista from '@/components/Grid/Detalle/Componentes/FotosVista'
import NotasVista from '@/components/Grid/Detalle/Componentes/NotasVista'
import DataCard from '@/components/Grid/Detalle/Componentes/DataCard'
import AnexosVista from '@/components/Grid/Detalle/Componentes/AnexosVista'
import InfoTablaFoto from '@/components/Grid/Detalle/Componentes/InfoTablaFoto'
import Mapa from '@/components/Grid/Detalle/Componentes/Mapa'

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf'

export default {
	components:{
		FotosVista,
		TextoVista,
		NotasVista,
		AsuntoVista,
		InfoTablaVista,
		CabeceraVista,
		DataCard,
		AnexosVista,
		InfoTablaFoto,
		Mapa
	},
	name:"VistaReporte",
	model:{
		prop:'repover',
		event:'click'
	},
	props:{
		oParent: {
			type: Object,
		},
		sNameDetails: {
			type: String,
			default: ''
		},
		sNameTablePrin: {
			type: String,
			default: ''
		},
		repover:{
			type:Boolean,
			default:false
		},
		sIdCampo: { // Se recibe el nombre del campo de la llave principapara extracto el id valor 
			type: String,
			default: ''
		},
		aParametros: {
			type: Array,
			default: () => [] // Listado de string que continene parametros adicionales que se usan para realizar filtrado, este listado son campos existentes.
		},
		bSubdominioTemporal:{ //Indica que se activa puente con subdominio temporal
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			model: null,
			campos: "",
			sFielItem: (this.sIdCampo!='') ? this.sIdCampo: `id${this.sNameTablePrin}`,
			aDataShow: [],
			exportStatus: false,
			aEncabezado: [], // Permite manejar el encabezado
			sParametros: ''
		}
	},
	mounted() {
		this.getCampos()
		this.getInfo()
		this.aParametros.forEach(sPar =>{
			this.sParametros = this.sParametros + `&${sPar}=${this.oParent[sPar]}`
		})
	},
	methods: {
		getCampos(){
			let sParams = 'sDetails='+ this.sNameDetails
			if(this.bSubdominioTemporal){
				sParams = sParams + `&subdominiot=${this.$store.state.sSubdominioTemporal}`
			}
			axiosServices.getConfiguration('/drawerDetails_campos',true, sParams).then(aCampos => {
				this.campos = aCampos.aData
			})
		},
		getInfo() {
			let nIdParent = this.oParent[`${this.sFielItem}`]
			let sParams = `sTablePrin=${this.sNameTablePrin}&nIdParent=${nIdParent}&sDetails=${this.sNameDetails}` + this.sParametros
			if(this.bSubdominioTemporal){
				sParams = sParams + `&subdominiot=${this.$store.state.sSubdominioTemporal}`
			}
			axiosServices.getConfiguration('/drawerDetails', true, sParams).then(aInfo => {
				this.aDataShow = aInfo.aData.Detalle
				this.aEncabezado = aInfo.aData.Encabezado

			})
		},
		cardAction(val){
			this.ampliar = true
			this.ftampliada = val
		},
		async printSection() {
			this.exportStatus =  true
      // Se imprime todo lo que este detro del div id printPage y los componentes principales que tengan la class imprimir
			let elements = document.querySelectorAll("#printPage .imprimir")
			let doc = new jsPDF('p', 'mm', 'a4', true)
			doc.setFillColor(245);
			doc.rect(0, 0, 210, 700, "F");
			const imgWidth = doc.internal.pageSize.getWidth()
			const pageHeight = doc.internal.pageSize.getHeight() - 5
			let position = 5
			let pageHeightLeft = pageHeight
			for (let i = 0; i < elements.length; i++) {
				await html2canvas(elements[i], {
					scale: 2,
					useCORS: true,
					allowTaint: true
				}).then(function(canvas) {
					let imgData = canvas.toDataURL('image/png', 1.0)
					let imgHeight = canvas.height * imgWidth / canvas.width
					// Comprobamos salto
					if (pageHeightLeft - imgHeight <= 0) {
						doc.addPage()
						pageHeightLeft = pageHeight
						position = 5 // Pintaremos en el inicio de la nueva pagina
					}
					doc.addImage(imgData, 'PNG', 0, position, (imgWidth - 2), imgHeight, '', 'FAST')
					position += imgHeight // Marcamos el siguiente inicio
					pageHeightLeft -= imgHeight // Marcamos cuanto espacio nos queda
				})
			}
			this.exportStatus =  false
			doc.save(`Reporte.pdf`)
			this.$analytics.logEvent('Grid-'+this.sNameTablePrin+'', { exportarDetalle:1}) //Analitics
    },
	},
	watch: {
		oParent (oNewValue) {
			if (oNewValue !== '') {
				this.getInfo()
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.close-btn{
    left: -25px;
    top: 2px;
		background: white;
		z-index:-1;
}
.v-navigation-drawer{
    overflow: initial;
}
@media screen and(max-width:600px) {
    .contendor-vista{
        font-size: 0.8em!important;
    }
}
</style>