export default {
  props: {
    texto: {
      type: String,
      default: "",
    },
    titulo: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      valor: this.texto,
      esError: false,
    };
  },
}