<template>
<v-tooltip bottom >
  <template v-slot:activator="{ on }">
    <v-btn color="primary" class="mx-1" outlined small icon v-on="on" @click="emitEvento()"> 
      <v-icon size="13px" class="btncolor--text mx-1" >
        fa-eye
      </v-icon>
    </v-btn>
  </template>
  <span>{{HoverMensaje}}</span>
  
</v-tooltip>

</template>

<script>
export default {
  name:"BtnDetalleCliente",
  props: {
    item: {
    },
    HoverMensaje:{
      type: String,
      default: 'Sin Mensaje'
    }
  },
  data() {
    return {
    }//fin return
  }, // Fin data
  methods: {
    emitEvento() {
      this.$emit('btnActionEvento',{btnName: 'btnDetails', item: this.item })
    }, 
  },
}

</script>

<style scoped>

</style>