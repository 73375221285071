<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="512px" height="512px" viewBox="0 0 22.044 22.044" style="enable-background:new 0 0 22.044 22.044;" xml:space="preserve" class=""><g><g>
	<path d="M18.54,8.402c-1.036-0.357-2.206,0.405-2.89-0.339c-0.725-0.788-1.456-0.925-2.409-0.895   c-0.732,0.021-1.482-0.132-1.242-1.202c0.077-0.354-0.179-0.561-0.321-0.821c-0.887-1.626-0.482-2.965,1.191-4.018   c0.296-0.184,0.968-0.187,0.622-0.79c-0.319-0.551-0.853-0.332-1.177-0.002c-0.773,0.792-1.681,1.158-2.767,1.325   C8.675,1.794,7.684,2.101,7.556,3.184c-0.132,1.13-1.035,1.545-1.736,2.11c-0.577,0.469-1.137,1.62-0.837,2.268   c0.635,1.375,0.235,2.844,0.323,4.263c0.042,0.647-0.805,1.06-1.376,1.501c-0.29,0.225-1.11,0.863-0.483,1.405   c0.744,0.642,1.69,1.44,2.562,1.459c2.199,0.05,3.486,1.334,4.614,2.854c0.443,0.596,0.841,0.746,1.466,0.693   c0.498-0.04,1-0.123,1.494-0.083c0.843,0.066,1.577,0.268,0.728,1.312c-0.388,0.48-0.115,0.714,0.342,0.865   c0.187,0.064,0.441,0.461,0.537-0.025c0.282-1.434,1.303-3.251,0.395-4.239c-1.146-1.248,1.139-2.114-0.122-2.994   c-0.029-0.023,0.405-0.173,0.65-0.196c0.737-0.065,1.452-0.026,2.207-0.352c0.455-0.196,0.607-0.079,0.453-0.63   C18.311,11.766,17.429,10.153,18.54,8.402z" data-original="#121111" class="active-path" data-old_color="#121111" fill="#15ADC7"/>
</g></g> </svg>
</template>
<script>
  export default {
    name:"countryIcon"
  }
</script>

<style scoped>

</style>