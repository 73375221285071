<template>
  <v-layout v-if="value">
    <v-card min-height="800px" class="border-card">
      <v-toolbar
        color="primary"
        height="45"
        dense
        dark>
        <v-spacer></v-spacer>
        <span class="mdlHeadForms">Crear acta de reunión/PQR</span>
        <v-spacer></v-spacer>
        <v-btn 
          color="btncolor-dark"
          @click="CloseDialog()"
          icon
          small
          dark
          outlined>
          <v-icon size="14px">fa fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-toolbar
        color="headformsdark"
        height="45"
        elevation="0"
        dense
        dark>
        <span><h3 class="text-medium font-normal"><v-icon>mdi-calendar-month-outline</v-icon> Fecha de Ejecución:  {{HoraRegistro}}</h3></span>
      </v-toolbar>  
      <v-container :key="KeyRefresh">
        <v-row class="pt-3">
          <v-col sm="6" cols="6" class="py-0 ma-0 ColumnaResumida" >
            <FormSelect
              v-model="nCliente"
              :titulo="'Cliente'"
              :inner="'Clientes,'"
              :campo="'Nombre_Cliente'"
              :obligatorio="true"
              :editable="'1'"
            ></FormSelect>
          </v-col>  
          <v-col sm="6" cols="6" class="py-0 ma-0 ColumnaResumida">
            <FormSelectFiltro
              v-model="nSede"
              :titulo="'Sede'"
              :inner="'sede,'"
              :campo="'Nombre'"
              :obligatorio="true"
              :campoFiltro="'idCliente'"
              :valorFiltro="nCliente"
              :editable="'1'"
            ></FormSelectFiltro>
          </v-col>
          <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida">
            <FormTextoArea
              v-model="sTemasTratados"
              :titulo="'Temas Tratados'"
              :editable="'1'"
              :sPlaceHolder="'Redacta los temas tratados'"
              :obligatorio="true">
            </FormTextoArea>
          </v-col>  
          <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida">
          <FormTextoArea
            v-model="sDesarrollo"
            :titulo="'Desarrollo de la reunión'"
            :editable="'1'"
            :sPlaceHolder="'Redacta el desarrollo de la reunión'"
            :obligatorio="true">
          </FormTextoArea>
          </v-col>
          <!-- CAMPO DE AÑADIR COMPROMISOS-->
          <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida mt-2">
            <span class="titleslight--text"> Compromisos</span>
            <v-col sm="12" cols="12" class="pa-2 ma-0 under-sec-nomargin d-flex align-center justify-end">
              <p color="primary" class="titleslight--text font-weight-medium pa-0 ma-0"> Añadir Compromisos</p>
              <v-btn color="primary" elevation="2" fab small @click="bMostrarCompromisos = true" class="ml-3">
                <v-icon size="35">mdi-plus</v-icon>
              </v-btn>
            </v-col> 
            <v-row>
              <v-col cols="12" sm="6" class="pa-0 ma-0 ColumnaResumida" 
              v-for="(oRow, nKey) in aCompromisos" :key="nKey">
                <v-card class="border-card px-2 ma-2">
                  <v-card-text class="text-h6 py-1 px-0 titlesdark--text">
                    Descripción
                  </v-card-text>
                  <v-card-text class="py-1 px-0 titlesdark--text">
                    {{ oRow.sDescripcion}}
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions class="d-flex align-end flex-column">
                    <v-btn depressed light outlined small color="buttondark"                   
                      @click="onCargarDetalleCompromiso(oRow)">
                      Ver más >
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-col> 
          <!-- CAMPO DE AÑADIR  ASISTENTES-->
          <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida mt-2">
            <span class="titleslight--text"> Asistentes*</span>
            <v-col sm="12" cols="12" class="pa-2 ma-0 under-sec-nomargin d-flex align-center justify-end">
              <p color="primary" class="titleslight--text font-weight-medium pa-0 ma-0"> Añadir Asistentes</p>
              <v-btn color="primary" elevation="2" fab small @click="bMostrarAsistentes = true" class="ml-3">
                <v-icon size="35">mdi-plus</v-icon>
              </v-btn>
            </v-col> 
            <v-row>
              <v-col sm="6" cols="12" class="pa-0 ma-0 ColumnaResumida" 
              v-for="(oRow, nKey) in aAsistentes" :key="nKey">
                <v-card class="border-card px-2 my-1 mx-1">
                  <v-card-text class="ma-0 pa-1">
                      <v-row class="ma-0 pa-0">
                        <v-col sm="12" cols="12" class="pa-0 ma-0 ColumnaResumida titlesdark--text">
                         <span class="text-medium ">Nombre:</span> <span>{{ oRow.sNombreAsistente }}</span>
                        </v-col>
                      </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col> 
          <!-- CAMPO DE ADJUNTAR ARCHIVOS-->
          <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida mt-2 adjuntar">
            <span class="titleslight--text"> Adjuntar archivos </span>
            <DragUpdateFile @files="f_anexos" :NoAnexos="10"></DragUpdateFile>
          </v-col>
          <!--  -->
          <v-col sm="12" cols="12" class="my-5 mx-0 ColumnaResumida">
            <FormCheckBox
              v-model="bCopiaCliente"
              :titulo="'Copia correo al cliente'"
              :editable="'1'">
            </FormCheckBox>
          </v-col>  
          <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida">
            <FormTexto
              v-model="sCorreos"
              :titulo="'Correos'"
              :editable="'1'"
              :sTooltip="'Ej: correo1@correo.com;correo2@correo.com'"
              :sPlaceHolder="'Ingresa los correos. Recuerda que deben separarse por ; y sin espacios'"
              :obligatorio="true">
            </FormTexto>
          </v-col>  
          <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida">
            <v-row justify="center" align="center" class="pa-4">
              <v-btn class="no-uppercase btn-rounded d-flex mx-2" large depressed dark color="btndest" @click="Save">Guardar</v-btn>
              <v-btn class="no-uppercase btn-rounded d-flex mx-2" large depressed dark color="btndest" @click="CloseDialog">Cancelar</v-btn>
            </v-row>  
          </v-col>
        </v-row>
      </v-container>    
    </v-card>
    <v-dialog v-model="bMostrarCompromisos" persistent max-width="750px" :key="nKeyModalCompromisos">
      <v-card min-height="400px">
        <v-toolbar
          color="headformsdark"
          height="45"
          elevation="0"
          dense
          dark>
          <v-spacer></v-spacer>
          <span class="mdlHeadForms">Añadir Compromisos</span>
          <v-spacer></v-spacer>
          <v-btn 
            color="btncolor-dark"
            @click="onResetVistaCompromisos"
            icon
            small
            dark
            outlined>
            <v-icon size="14px">fa fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container class="py-3">
          <v-row >
              <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida">
                <FormTexto
                  v-model="oCompromisos.sDescripcion"
                  :titulo="'Descripcion'"
                  :sPlaceHolder="'Escribe tu compromiso'"
                  :editable="'1'"
                  :obligatorio="true">
                </FormTexto>
              </v-col>  
                <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida">
                <FormTexto
                  v-model="oCompromisos.sResponsable"
                  :titulo="'Responsable'"
                  :sPlaceHolder="'Escribe el responsable del compromiso'"
                  :editable="'1'"
                  :obligatorio="true">
                </FormTexto>
              </v-col>
              <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida">
                <FormDate
                  v-model="oCompromisos.sFechaCompromiso"
                  :campo="'FechaCompromiso'"
                  :titulo="'Fecha de entrega'"
                  :sPlaceHolder="'Selecciona la fecha de entrega del compromiso'"
                  :editable="'1'"
                  :obligatorio="true"
                  :bDesdeHoy="true">
                </FormDate>
              </v-col> 
              <v-col sm="12" cols="12" class="pa-2 ma-0 ColumnaResumida under-sec-nomargin text-center">
                <FormSiNo
                  v-model="oCompromisos.bPqr"
                  :campo="'bPqr'"
                  :titulo="'¿Deseas convertirlo a PQR?'"
                  :editable="'1'"
                  :obligatorio="true">
                </FormSiNo>
              </v-col> 
              <v-col v-if="oCompromisos.bPqr=='1'" sm="12" cols="12" class="py-3 px-0  ColumnaResumida">
                <v-row >
                  <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida" >
                    <FormSelect
                      v-model="oCompromisos.nDepartamento"
                      :titulo="'Departamento'"
                      :inner="'Departamentos,'"
                      :campo="'NombreDepartamento'"
                      :obligatorio="true"
                      :editable="'1'"
                    ></FormSelect>
                  </v-col>  
                  <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida">
                    <FormSelectFiltro
                      v-model="oCompromisos.nDependencia"
                      :titulo="'Dependencia'"
                      :inner="'Dependencias,'"
                      :campo="'Nombre_Dependencia'"
                      :obligatorio="true"
                      :campoFiltro="'idDepartamento'"
                      :valorFiltro="oCompromisos.nDepartamento"
                      :editable="'1'"
                    ></FormSelectFiltro>
                  </v-col>
                  <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida">
                    <FormSelect
                        v-model="oCompromisos.nTipoRequerimiento"
                      :titulo="'Tipo Requerimiento'"
                      :inner="'PQR_Tipos_requerimiento,'"
                      :campo="'Detalle'"
                      :obligatorio="true"
                      :editable="'1'"
                    ></FormSelect>
                  </v-col>
                    <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida">
                    <FormSelect
                      v-model="oCompromisos.nPrioridad"
                      :titulo="'Prioridad'"
                      :inner="'PQR_Prioridad_requerimiento,'"
                      :campo="'Prioridad'"
                      :obligatorio="true"
                      :editable="'1'"
                    ></FormSelect>
                  </v-col>
                </v-row>
              </v-col> 
            <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida mt-2">
              <v-row justify="center"  align="center">
                  <v-btn class="btn-rounded d-flex mx-2" depressed light outlined color="buttondark" @click="onAgregarCompromiso">Añadir</v-btn>
                  <v-btn class="btn-rounded d-flex mx-2" depressed light outlined color="buttondark" @click="onResetVistaCompromisos">Cancelar</v-btn>
              </v-row>  
            </v-col>
          </v-row>
        </v-container>    
      </v-card>
    </v-dialog>
    <v-dialog v-model="bMostrarCompromisosLectura" persistent max-width="650px">
      <v-card class="pb-3">
        <v-toolbar
          color="headformsdark"
          height="45"
          elevation="0"
          dense
          dark>
          <v-spacer></v-spacer>
          <span class="mdlHeadForms">Ver Compromisos</span>
          <v-spacer></v-spacer>
          <v-btn 
            color="btncolor-dark"
            @click="onResetVistaCompromisos"
            icon
            small
            dark
            outlined>
            <v-icon size="14px">fa fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container class="pt-2">
          <v-row >
              <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida">
                <CampoLectura
                  v-model="oCompromisos.sDescripcion"
                  :titulo="'Descripcion'"
                  :obligatorio="true"
                />
              </v-col>  
              <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida">
                <CampoLectura
                  v-model="oCompromisos.sResponsable"
                  :titulo="'Responsable'"
                  :obligatorio="true"
                />
              </v-col>
              <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida">
                <CampoLectura
                  v-model="oCompromisos.sFechaCompromiso"
                  :titulo="'Fecha de entrega'"
                  :obligatorio="true"
                />
              </v-col> 
              <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida">
                <CampoLectura
                  v-model="oCompromisos.bPqr"
                  :titulo="'¿Deseas convertirlo a PQR?'"
                  :obligatorio="true"
                  :modo="2"
                  :aItems="[
                    {
                      id: 0,
                      texto: 'No'
                    },
                    {
                      id: 1,
                      texto: 'Si'
                    }
                  ]"
                />
              </v-col>
              <v-divider></v-divider>
            <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida d-flex justify-center">
                <v-btn class="btn-rounded d-flex mx-2 margin-auto" depressed light outlined color="buttondark" @click="onResetVistaCompromisos">Volver</v-btn>
            </v-col>
          </v-row>
        </v-container>    
      </v-card>
    </v-dialog>
    <v-dialog v-model="bMostrarAsistentes" persistent max-width="400px" :key="nKeyModalAsistentes">
      <v-card>
        <v-toolbar
          color="headformsdark"
          height="45"
          elevation="0"
          dense
          dark>
          <v-spacer></v-spacer>
          <span class="mdlHeadForms">Añadir Asistentes</span>
          <v-spacer></v-spacer>
          <v-btn 
            color="btncolor-dark"
            @click="onResetVistaAsistentes"
            icon
            small
            dark
            outlined>
            <v-icon size="14px">fa fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container class="mt-2">
          <v-row >
              <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida">
                  <FormTexto
                    v-model="oAsistentes.sNombreAsistente"
                    :titulo="'Nombre asistente'"
                    :editable="'1'"
                    :obligatorio="true">
                  </FormTexto>
                </v-col>
            <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida mb-3">
              <v-row justify="center"  align="center">
                  <v-btn class="btn-rounded d-flex mx-2" depressed light outlined color="buttondark" @click="onAgregarAsistente">Añadir</v-btn>
                  <v-btn class="btn-rounded d-flex mx-2" depressed light outlined color="buttondark" @click="onResetVistaAsistentes">Cancelar</v-btn> 
              </v-row>  
            </v-col>
          </v-row>
        </v-container>    
      </v-card>
    </v-dialog>
    <ErrorView v-model="bMostrarErrores" :aErrores="aErrores"></ErrorView>
    <ModalMensaje v-model="oModalMensaje.bModalMensaje" :sMensaje="oModalMensaje.sMensaje" :nTipo="oModalMensaje.nTipo" />
  </v-layout>    
</template>

<script>
import mxFunciones from '@/mixins/funciones.js'
import HoraFecha from '@/Utlidades/Hora_Fecha.js'
import FormSelect from '@/components/Form/Componentes/FormSelect.vue'
import CampoLectura from '@/components/CampoLectura.vue'
import ModalMensaje from '@/components/ModalMensaje.vue'
import FormSelectFiltro from '@/components/Form/Componentes/FormSelectFiltro.vue'
import FormSiNo from '@/components/Form/Componentes/FormSiNo.vue'
import FormDate from '@/components/Form/Componentes/FormDate.vue'
import FormCheckBox from '@/components/Form/Componentes/FormCheckBox.vue'
import FormTexto from '@/components/Form/Componentes/FormTexto.vue'
import FormTextoArea from '@/components/Form/Componentes/FormTextArea.vue'
import axiosServices from '@/services/axiosServices.js'
import DragUpdateFile from '@/components/DragUpdateFile.vue'
import GPSLocation from '@/Utlidades/GPSlocation.js'
import ErrorView from '@/components/ErrorView.vue'

export default {
  name:"NuevaActaReunionPqr",
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  components: {
    FormCheckBox,
    FormSelect,
    FormSelectFiltro,
    FormTexto,
    DragUpdateFile,
    ErrorView,
    FormSiNo,
    FormDate,
    CampoLectura,
    ModalMensaje,
    FormTextoArea
  },
  data() {
    return {
      nKeyModalCompromisos:0,
      nKeyModalAsistentes:0,
      bMostrarCompromisos: false,
      bMostrarAsistentes: false,
      bMostrarCompromisosLectura: false,
      oModalMensaje: {
        bModalMensaje: false,
        sMensaje: '',
        nTipo: 1
      },
      oCompromisos: {
        sDescripcion: '',
        sResponsable: '',
        sFechaCompromiso: '',
        bPqr: '',
        nDepartamento: '',
        nDependencia: '',
        nTipoRequerimiento: '',
        nPrioridad: ''
      },
      oAsistentes: {
        sNombreAsistente: '',
      },
      aAsistenes: [],
      nCliente : '',
      nSede    : '',
      sTemasTratados  : '',
      sDesarrollo  : '',
      sCorreos  : '',
      sObservaciones : '',
      aCompromisos : [],
      aAsistentes : [],
      bCopiaCliente: false,
      KeyRefresh : 0,   // empleo esta variale para refrescar los componentes
      bMostrarErrores : false,
      aAnexos  : [],
      aErrores : [],
    }
  },
  mixins: [mxFunciones],
  mounted (){
   
  },
  methods: {
    CloseDialog() {
      this.$emit('input', false)
    },
    refrescar(){
      this.KeyRefresh++
    },
    async Save(){
      try {
        this.validarCampos()

        // Valido que bMostrarErrores siga en falso
        if(this.bMostrarErrores) return 

        const oLocation = await GPSLocation.getCurrentPosition()
        const aLocalAsistentes = []
        this.aAsistentes.forEach(item=> {
          aLocalAsistentes.push({
            NombreAsistente: item.sNombreAsistente,
            NombreFirma: "sinfoto.jpg"
          })
        })
        const aLocalCompromisos = []
        this.aCompromisos.forEach(item=> {
          aLocalCompromisos.push({
            Descripcion: item.sDescripcion,
            Responsable: item.sResponsable,
            Fecha: item.sFechaCompromiso,
            isPQR: (item.bPqr=="0") ? false : true,
            idDepartamento: (item.nDepartamento=='') ? 0 : item.nDepartamento,
            idDependencia: (item.nDependencia=='') ? 0 : item.nDependencia,
            idTipo: (item.nTipoRequerimiento=='') ? 0 : item.nTipoRequerimiento,
            idPrioridad: (item.nPrioridad=='') ? 0 : item.nPrioridad,
          })
        })
        const aLocalCorreos = []
        this.sCorreos.split(';').forEach(item=> {
          aLocalCorreos.push({
            Email: item,
          })
        })
        const oBody = {
          app      : 'NuevaActaReunionPqr-web',
          accion   : "ActaReunion_Secancol",
          proyecto  : this.nCliente,
          sede     : this.nSede,
          tema      : this.sTemasTratados,
          desarrollo     : this.sDesarrollo,
          asistente   : aLocalAsistentes,
          desarrolloReunion   : aLocalCompromisos,
          fotos          : this.FormatAnexos(), 
          copyEmail  : (this.bCopiaCliente) ? '1' : '0',
          emails   : aLocalCorreos,
          fecha    : `${this.HoraRegistro}`,
          latitud : oLocation.coords.latitude,
          longitud : oLocation.coords.longitude,
          
        }
        const respuesta = await axiosServices.postConfiguration('/cop/acta-reunion-pqr', oBody, true)
        //console.log('ActaReunion respuesta', respuesta)
        if(respuesta.nStatusCode==200 || respuesta.nStatusCode==220){
          this.onSubirAnexos(this.aAnexos)
          this.$store.dispatch('notificaciones/SetMensaje',respuesta.sMessage)
          this.$store.dispatch('notificaciones/SetColor','success')
          this.$store.dispatch('notificaciones/ShowNotificacion',true)
          this.CloseDialog()
        }else{
          this.$store.dispatch('notificaciones/SetMensaje',respuesta.sMessage)
          this.$store.dispatch('notificaciones/SetColor','error')
          this.$store.dispatch('notificaciones/ShowNotificacion',true)
        }

      } catch (error) {
          //console.log(error)
          this.$store.dispatch('notificaciones/SetMensaje','ERROR: GPS inactivo o No se tienen permiso para obtener la Ubicabion. Valide y refresque la Pantalla' )
          this.$store.dispatch('notificaciones/SetColor','error')
          this.$store.dispatch('notificaciones/ShowNotificacion',true)
          this.bErrorPermisos = false
      }

      
    },
    f_anexos(files){
      this.aAnexos = files
    },
    FormatAnexos() {
      let aGaleria = [];
      // recorro los Anexos para sacar todos los nombres de las anexos que tenga y dejarlos como [ {Foto : "NombreArchico"}]
      this.aAnexos.forEach(Anexos => {
        // valido que no sea un file lizt con mas de un elemento 
        if(Anexos.length > 1){
          // Lo recorro de nuevo
          Anexos.forEach( Anexo => {
            aGaleria.push({ Foto : Anexo.name , TipoAnexo: (Anexo.type.includes('image')) ?'1' : '2' })
          })
        } else {
          aGaleria.push({ Foto : Anexos[0].name, TipoAnexo: (Anexos[0].type.includes('image')) ?'1' : '2'})
        }
      })

      return aGaleria

    },
    validarCampos(){
      this.aErrores = []
      this.bMostrarErrores = false

      if(this.nCliente === '' || this.nCliente === null) this.aErrores.push('Debe seleccionar un cliente')
      if(this.nSede === '' || this.nSede === null) this.aErrores.push('Debe seleccionar una sede')
      if(this.sTemasTratados === '' || this.sTemasTratados === null) this.aErrores.push('Debe diligenciar los temas tratados')
      if(this.sDesarrollo === '' || this.sDesarrollo === null) this.aErrores.push('Debe indicar desarrollo de la reunión')

      if(this.seContacto === '1'){
        if(this.Funcionario === '') this.aErrores.push('Debe indicar un funcionario')
        if(this.Cedula === '') this.aErrores.push('Debe indicar la cedula del funcinario')
        if(this.Motivo === '') this.aErrores.push('Debe indicar un motivo')
        if(this.Medio === '') this.aErrores.push('Debe indicar un Medio')
      }
      if(this.sCorreos === '' || this.sCorreos === null){
        this.aErrores.push('Debe diligenciar los correos')
      }else{
        this.sCorreos.split(';').forEach(item=> {
        const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i
        if (!emailRegex.test(item)) {
          this.aErrores.push('Correo ' + item + ' inválido')
        }
      })
      }
      
      this.bMostrarErrores = this.aErrores.length > 0

    },
    validarCamposCompromisos(){
      this.aErrores = []
      this.bMostrarErrores = false

      if(this.oCompromisos.sDescripcion === ''  || this.oCompromisos.sDescripcion === null ) this.aErrores.push('Debe diligenciar la descripción')
      if(this.oCompromisos.sResponsable === '' || this.oCompromisos.sResponsable === null ) this.aErrores.push('Debe diligenciar el responsable')
      if(this.oCompromisos.sFechaCompromiso === '' || this.oCompromisos.sFechaCompromiso === null ) this.aErrores.push('Debe seleccionar una fecha de compromiso')
      if(this.oCompromisos.bPqr === '' || this.oCompromisos.bPqr === null ) this.aErrores.push('Debe indicar si se creará o no un PQR')

      if(this.oCompromisos.bPqr === '1'){
        if(this.oCompromisos.nDepartamento === ''|| this.oCompromisos.nDepartamento === null ) this.aErrores.push('Debe seleccionar un departamento')
        if(this.oCompromisos.nDependencia === ''|| this.oCompromisos.nDependencia === null ) this.aErrores.push('Debe seleccionar una dependencia')
        if(this.oCompromisos.nTipoRequerimiento === ''|| this.oCompromisos.nTipoRequerimiento === null ) this.aErrores.push('Debe seleccionar un tipo de requerimiento')
        if(this.oCompromisos.nPrioridad === ''|| this.oCompromisos.nPrioridad === null ) this.aErrores.push('Debe seleccionar una prioridad')
      }

      this.bMostrarErrores = this.aErrores.length > 0

    },

    validarCamposAsistentes(){
      this.aErrores = []
      this.bMostrarErrores = false

      if(this.oAsistentes.sNombreAsistente === '' || this.oAsistentes.sNombreAsistente === null ) this.aErrores.push('Debe diligenciar elnombre del asistente')
    
  
      this.bMostrarErrores = this.aErrores.length > 0

    },
    onResetVistaCompromisos () {
      this.bMostrarCompromisos = false
      this.bMostrarCompromisosLectura = false
      this.oCompromisos = {
        sDescripcion: '',
        sResponsable: '',
        sFechaCompromiso: '',
        bPqr: '',
        nDepartamento: '',
        nDependencia:'',
        nTipoRequerimiento: '',
        nPrioridad: ''
      }
    },
    onResetVistaAsistentes() {
      this.bMostrarAsistentes = false
      this.oAsistentes = {
        sNombreAsistente: '',
      }
    },
    onAgregarCompromiso () {

        this.validarCamposCompromisos()

        // Valido que bMostrarErrores siga en falso
        if(this.bMostrarErrores) return

        this.aCompromisos.push(this.oCompromisos)
        this.oModalMensaje = {
          bModalMensaje: false,
          sMensaje: 'Compromiso creado',
          nTipo: 1
        }
        this.onResetVistaCompromisos()
    },
    onAgregarAsistente () {
        this.validarCamposAsistentes()

        // Valido que bMostrarErrores siga en falso
        if(this.bMostrarErrores) return

        this.aAsistentes.push(this.oAsistentes)
        this.oModalMensaje = {
          bModalMensaje: false,
          sMensaje: 'Asistente creado',
          nTipo: 1
        }
        this.onResetVistaAsistentes()
    },
    onCargarDetalleCompromiso (oRow) {
      this.oCompromisos = oRow
      this.bMostrarCompromisosLectura = true

    }
  },
  computed: {
    HoraRegistro() {
      this.refrescar()
      if(this.value){
        return HoraFecha.getNow()
      }

      return ''
    },
  },
  watch: {
    bMostrarCompromisos(bNuevoValor) {
      if(bNuevoValor) this.nKeyModalCompromisos++
    },
    bMostrarAsistentes(bNuevoValor) {
      if(bNuevoValor) this.nKeyModalAsistentes++
    },
  },

}
</script>

<style lang="scss" scoped>
</style>
