<template>
  <div>
    <v-radio-group
      class="mt-n4 pl-1"
      v-model="valor"
      row
      dense
      :disabled="true"
    >
      <v-switch :label="valor ? 'SI' : 'NO'" v-model="valor"></v-switch>
    </v-radio-group>
  </div>
</template>
<script>
import mixinGridComponenteCelda from "@/mixins/GridComponenteCelda.js"

export default {
  name: "CeldaSwitch",
  mixins: [mixinGridComponenteCelda],
};

</script>

<style scoped></style>