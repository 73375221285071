<template>
  <v-container grid-list-xs class="pa-0 ma-0" fluid>
    <v-row class="pa-0 ma-0 white titlesdark--text" >
      <v-col md="8" sm="12" class="pa-0 ma-0">
        <span class="title titlesdark--text font-weight-bold pl-1">Asuntos</span>
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0">
      <v-col md="12" sm="12" class="pa-0 ma-0">
        <grid
          v-if="this.$store.state.token!=''"
          :tabla="'MinutaAsuntos'"
          :bActiveFilter="true"
          :bActiveExport="true"
          :bSingleSelect="true"
          :bShowSelect="false"
          :sIdTable="'idMinutaAsuntos'"
          :oTablesDependens="oTables"
          :bShowMapDetails="false"
          @saveOk="createDetaill"
          @idSelect="getIdSelect"
          @nameField="getNameFieldSelect"/>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import grid from '@/components/Grid/Grid.vue'
import mxFunciones from '@/mixins/funciones.js'
// import axiosServices from '@/services/axiosServices.js'

export default {
  name:"MinutaAsuntos",
  components: {
    grid
  },
  mixins: [mxFunciones],
  data() {
    return {
      idSelect  : '',                 // Id seleccionado
      nameField : '',                 // Nombre del campo foreignKey
      oTables: {
        "Primary": "MinutaAsuntos",
        "Second": '',
        "Third": '',
        "Aux": ''
      }
    }
  },// fin data
  mounted() {
    this.onValidarTokenSinLogin()
  },
  methods: {
    getIdSelect(sIdSelected) {
      this.idSelect = sIdSelected;
    },
    getNameFieldSelect(sNameFiled) {
      this.nameField = sNameFiled;
    },
    createDetaill(){

    }
  },
}
</script>