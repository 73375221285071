<template>
	<div>
		<v-sheet v-for="(oInfo, i) in aInfo" 
		:key="i" class="mx-auto py-3 px-3 my-2" 
		elevation="8" max-width="800" color="primarydark" dark
		:hidden="hiddenElement">
			<h4 class="text-white text-medium ">{{ oInfo.value }}</h4>
			<v-slide-group class="pa-4" show-arrows>
			<v-slide-item v-for="(oImage, j) in aImages" :key="j">
				<v-card  class="ma-4" height="170" width="170"
					@click="cardAction(oImage.value)">
					<v-img class="align-end" height="170" :src="oImage.value" contain>
						<template v-slot:placeholder>
							<v-row
								class="fill-height ma-0"
								align="center"
								justify="center">
								<v-progress-circular
									indeterminate
									color="blue lighten-3">
								</v-progress-circular>
							</v-row>
						</template>
					</v-img>
				</v-card>
			</v-slide-item>
			</v-slide-group>
		</v-sheet>
		<AmpliarFoto
			v-model="ampliar" 
			:fotoampliada="ftampliada">
		</AmpliarFoto>
		<div v-for="(oInfo, i) in aInfo" :key="`expor`+i" class="imprimir" :hidden="!hiddenElement">
			<div width="100%" class="px-4 mb-3">
				<h4 class="text-medium">{{oInfo.value}}</h4>
			</div>
			<div width="50%">
				<div style="display: flex;">
					<v-container>
						<v-row class="border-card px-4">
							<v-col v-for="(oImage, j) in aImages" :key="j" sm="3" md="3" lg="3" xl="3">
								<div id="sImgReference" >
									<img class="aFotos" style="width: 180px; height: 180px;" :src="oImage.value" contain />
								</div>
							</v-col>
						</v-row>
					</v-container>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import AmpliarFoto from '@/components/AmpliarFoto'
import axiosServices from '@/services/axiosServices.js'
export default {
	name:"FotosVista",
	components:{
		AmpliarFoto
	},
	props:{
		aInfo: {},	// tine dos propiedades index = numero value = el titulo de la seccion
		nIdParent: {
			type: String,
			default: ''
		},
		sNameDetails: {
			type: String,
			default: ''
		},
		hiddenElement:{
			type: Boolean,
			default: false
		},
		bSubdominioTemporal:{ //Indica que se activa puente con subdominio temporal
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			ampliar: false,
			ftampliada: null,
			aImages: [],
			fotos: 1,
		}
	},
	mounted() {
		this.getImages()
	},
	methods: {
		getImages() {
			let sParams = ''
			Object.values(this.aInfo).forEach(oInfo => {
				sParams = `sTablePrin=${oInfo.index}&nIdParent=${this.nIdParent}&sDetails=${this.sNameDetails}`
				axiosServices.getConfiguration('/drawerDetails_getImages', true, sParams).then(aImages => {
					this.aImages = aImages.aData
				})
			})
			
		},
		cardAction(val){
			this.ampliar = true
			this.ftampliada = val
		}
	}
}
</script>