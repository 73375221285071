<template>
  <div>
    <span :class="[color, color !='' ? 'white--text': '']" >{{valor ? 'SI' : 'NO'}}</span>
  </div>
</template>
<script>
import mixinGridComponenteCelda from "@/mixins/GridComponenteCelda.js"

export default {
  name: "CeldaActivoInactivo",
  mixins: [mixinGridComponenteCelda],
};
</script>

<style scoped></style>