<template>
	<div class="imprimir under-sec-flat">
		<div v-for="(oInfo, i) in aInfo" :key="i" class=" px-3 py-3">
			<h4 class="text-medium">{{oInfo.index}}</h4>
			<v-card class="my-2 border-card" light>
				<v-card-text>
					<p class="descrip-container">{{oInfo.value}}</p>
				</v-card-text>
			</v-card>
		</div>
	</div>
</template>
<script>
export default {
	name:"TextoVista",
	props:{
		aInfo: {},
		nIdParent: {
			type: String,
			default: ''
		},
		sNameDetails: {
			type: String,
			default: ''
		}
	},
	data() {
		return {	
		}
	},
}
</script>