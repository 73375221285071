<template>
  <v-dialog v-model="value" persistent :max-width="sAncho">
    <v-card>
      <v-toolbar
        color="headformsdark"
        height="45"
        elevation="0"
        dense
        dark>
        <v-spacer></v-spacer>
        <span class="mdlHeadForms">Errores en Formulario</span>
        <v-spacer></v-spacer>
        <v-btn 
          color="btncolor-dark"
          @click="CloseDialog()"
          icon
          small
          dark
          outlined>
          <v-icon size="14px">fa fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-row >
        <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida">
          <v-list-item v-for="(error, index) in aErrores" :key="index" class="pa-0">
            <v-list-item-content class="red--text">
              <v-list-item-subtitle class="red--text"><v-icon v-text="'mdi-alert'" color="red" left></v-icon>{{error}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>  
      </v-row>   
      <v-card-actions class="mb-3">
        <v-btn class="btn-rounded d-flex mx-2 mx-auto" depressed light outlined color="buttondark" @click="CloseDialog">Aceptar</v-btn>
      </v-card-actions> 
    </v-card>
  </v-dialog>    
</template>

<script>
  export default {
    name: "ErrorView",
    props: {
      value: {
        type: Boolean,
        default: false
      },
      aErrores: {
        type: Array,
        default: () => []
      },
      sAncho:{
        typeof:String,
        default:"550px"
      }
    },
    data() {
      return {
      
      }
    },
    methods: {
      CloseDialog() {
        this.$emit('input', false)
      }
    },
    
  }
</script>

<style lang="scss" scoped>

</style>