<template>
<div >
  <span class="titleslight--text font-weight-medium">{{titulo}}</span>
  <span v-if="obligatorio" class="titleslight--text font-weight-bold ml-1">*</span>
  <v-text-field
    v-model="valor"
    :rules="emailValidacion"
    single-line
    outlined
    clearable
    dense
    :disabled="bEditable"
    :error="esError"
  ></v-text-field>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

  export default {
    name: 'FormCorreo',
    props: {
      value: {
      },
      titulo:{
        type:String,
        default:''
      },
      obligatorio:{
        type:Boolean,
        default: false
      },
      campo:{
        type:String,
        default : ''
      },
      editable: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        valor   : this.value || '',
        emailValidacion : [
          v => !!v ,
          v => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/.test(v),
        ],
        esError : false,
        bEditable : (this.editable == "0") ? true : false,
      }
    },
    mounted(){
    },
    methods: {

    },
    computed: {
      ...mapGetters(['getoFormError'])
    },
    watch: {
      valor(NewValor){
        this.$emit('input',NewValor.toLowerCase())
        if(this.esError && this.valor != ''){
          this.esError = false
        }
      },
      value(NewValue){
        if(NewValue != undefined){
          this.valor = NewValue
        }
      },
      getoFormError(){
        this.esError = this.getoFormError[this.campo]
      }
    },
    
  }
</script>

<style scoped>


</style>