<template>
	<div class="imprimir">
		<v-layout row justify-space-between my-1 px-3 py-3> 
			<v-flex xs4 v-for="(oInfo, idx) in aInfo" :key="'itemcol'+idx" d-flex justify-space-between>
				<div class="container-info">
					<v-img @click="onMostrarFoto(oInfo.value)" class="align-end" height="170" :src="oInfo.value" contain>
						<template v-slot:placeholder>
							<v-row
								class="fill-height ma-0"
								align="center"
								justify="center">
								<v-progress-circular
									indeterminate
									color="blue lighten-3">
								</v-progress-circular>
							</v-row>
						</template>
					</v-img>
					<h4 class="text-medium pb-1">{{oInfo.index}}</h4>
				</div>
			<v-divider vertical></v-divider>
			</v-flex>
		</v-layout>
		<AmpliarFoto
			v-model="ampliar" 
			:fotoampliada="ftampliada">
		</AmpliarFoto>
	</div>
</template>
<script>
import AmpliarFoto from '@/components/AmpliarFoto'

export default {
    name:"InfoTablaFoto",
	components:{
		AmpliarFoto
	},
    props:{
			aInfo: {},
			nIdParent: {
				type: String,
				default: ''
			},
			sNameDetails: {
				type: String,
				default: ''
			}
    },
    data() {
			return {
				ampliar: false,
				ftampliada: null,	
			}
    },
	methods: {
		onMostrarFoto(val){
			this.ampliar = true
			this.ftampliada = val
		}
	}
}
</script>
<style lang="scss">
.container-info{
	margin: 0 auto;
	width: 100%;
	text-align: center;
}
.no-margin{
	margin: 0px!important;
}
</style>