<template>
<div >
  <span class="titleslight--text font-weight-medium">{{titulo}}</span>
  <span v-if="obligatorio" class="titleslight--text font-weight-bold ml-1">*</span>
  <v-dialog
    ref="dialog"
    v-model="modalCalendar"
    :return-value.sync="FechaSelect"
    persistent
    width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="dateRangeText"
        prepend-icon="fa-calendar-alt"
        readonly
        v-on="on"
        dense
        :disabled="bEditable"
        :error="esError"
        :rules="noVacio">
      </v-text-field>
    </template>
    <v-date-picker v-model="FechaRange" scrollable range>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="modalCalendar = false">Cancelar</v-btn>
      <v-btn text color="primary" @click="$refs.dialog.save(FechaRange)">OK</v-btn>
    </v-date-picker>
  </v-dialog>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

  export default {
    name: 'FormDateRango',
    props: {
      value: {
      },
      titulo:{
        type:String,
        default:''
      },
      obligatorio:{
        type:Boolean,
        default: false
      },
      campo:{
        type:String,
        default : ''
      },
      editable: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        FechaSelect   : this.value == '' || this.value == null ? new Date().toISOString().substr(0, 10): this.value,
        FechaRange    :[this.FechaSelect, this.FechaSelect],
        modalCalendar : false,
        esError : false,
        noVacio : [v => !!v],
        bEditable : (this.editable == "0") ? true : false,
      }
    },
    mounted(){
      this.valor  = [this.FechaSelect, this.FechaSelect]
    },
    methods: {

    },
    computed: {
      dateRangeText () {
        return this.FechaRange.join(' ~ ')
      },

      ...mapGetters(['getoFormError'])

    },
    watch: {
      FechaSelect(NewValor){
        this.$emit('input',NewValor)
      },
      value(NewValue){
        this.valor = NewValue
      },
      getoFormError(){
        this.esError = this.getoFormError[this.campo]
      }
    },
    
  }
</script>

<style scoped>
