<template>
<div >
  <span class="titleslight--text font-weight-medium">{{titulo}}</span>
  <span v-if="obligatorio" class="titleslight--text font-weight-bold ml-1">*</span>
    <v-file-input 
      v-model="valor"
      :error="esError"
      filled
      small-chips 
      show-size 
      dense
      outlined
      :rules="noVacio"
      :disabled="bEditable">
    </v-file-input>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

  export default {
    name: 'FormFile',
    props: {
      value: {
      },
      titulo:{
        type:String,
        default:''
      },
      obligatorio:{
        type:Boolean,
        default: false
      },
     campo:{
        type:String,
        default : ''
      },
      editable: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        valor   : this.value,
        maxSize : 20000000,               // tamaño maximo de file a subir 
        esError : false,
        noVacio : [v => !!v],
        bEditable : (this.editable == "0") ? true : false,
      }
    },
    mounted(){
    },
    methods: {

    },
    computed: {
      ...mapGetters(['getoFormError'])
    },
    watch: {
      valor(NewValor){
        if(NewValor){  // si se borrar el archivo seleccionado da indefinido por lo cual de esta forma evito ese error
          if (NewValor.size > this.maxSize) {
            // check whether the size is greater than the size limit
            this.$store.dispatch('notificaciones/SetMensaje', "Su archivo es demasiado grande! Por favor seleccione uno menor a 20MB.");
            this.$store.dispatch('notificaciones/SetColor','error')
            this.$store.dispatch('notificaciones/ShowNotificacion', true);
            this.esError = true
          }else{
            this.esError = false
          }
        }  
        this.$emit('input',NewValor)
        if(this.esError && this.valor != ''){
          this.esError = false
        }
      },
      value(NewValue){
        this.valor = NewValue
      },
      getoFormError(){
        this.esError = this.getoFormError[this.campo]
      }
    },
    
  }
</script>

<style scoped>


</style>