<template>
  <div>
    <span :class="[color, color !='' ? 'white--text': '']">{{valor}}</span>
  </div>
</template>
<script>
import mixinGridComponenteCelda from "@/mixins/GridComponenteCelda.js"

export default {
  name: "CeldaDateRango",
  mixins: [mixinGridComponenteCelda],
};

</script>

<style scoped></style>