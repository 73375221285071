<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-dialog v-model="value" persistent max-width="600px">
        <v-card>
          <v-toolbar
            color="headformsdark"
            height="45"
            elevation="0"
            dense
            dark>
            <v-spacer></v-spacer>
            <span class="mdlHeadForms">Cambiar contraseña</span>
            <v-spacer></v-spacer>
            <v-btn 
              color="btncolor-dark"
              @click="CloseDialog()"
              icon
              small
              dark
              outlined>
              <v-icon size="14px">fa fa-times</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-title></v-card-title>
          <v-card-text>
            <v-form>
              <v-col sm="10" md="10" lg="10" xl="10" class="contenedor">
                <span class="titleslight--text font-weight-medium">Nueva contraseña</span>
                <span class="titleslight--text font-weight-bold ml-1">*</span>
                <v-text-field 
                  v-model="password"
                  :type="(passVisible) ? 'text' : 'password'"
                  :rules="passwordValidaciones"
                  :append-icon="(passVisible) ? 'fa-eye' : 'fa-eye-slash'"
                  @click:append="passVisible = !passVisible"
                  outlined
                  dense
                  required>
                </v-text-field>
              </v-col>
              <v-col sm="10" md="10" lg="10" xl="10" class="contenedor">
                <span class="titleslight--text font-weight-medium">Confirmar contraseña</span>
                <span class="titleslight--text font-weight-bold ml-1">*</span>
                <v-text-field 
                  v-model="chkpassword"
                  :type="(confirmPassVisible) ? 'text' : 'password'"
                  :rules="ConfirmarcionPWD"
                  :append-icon="(confirmPassVisible) ? 'fa-eye' : 'fa-eye-slash'"
                  @click:append="confirmPassVisible = !confirmPassVisible"
                  outlined
                  dense
                  required>
                </v-text-field>
              </v-col>
              <small class="d-flex justify-end"><span class="titleslight--text font-weight-medium">* Campo Obligatorio</span></small>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="btncolor"  
              @click="putPassword()"
              dark
              outlined>
              Cambiar
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import  axiosServices  from '@/services/axiosServices.js';
import { mapGetters } from 'vuex';
var md5 = require('md5');
export default {
  name:"changepass", 
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      password    : '',
      chkpassword : '',
      bValidate   : this.validateInputs(),
      passVisible : false,
      confirmPassVisible : false,
      ConfirmarcionPWD : [
        v => !!v || 'La confirmación es requerida',
        v => v==this.password || 'Las contraseñas no coinciden',
        v => v==this.chkpassword || 'Las contraseñas no coinciden'
      ],
      passwordValidaciones : [
        v => !!v || 'La contraseña es requerida',
        v => /^[a-zA-Z0-9!#-+/.*@·~%&¬()=?¿¡{}[\]]{5,}$/.test(v) || 'La contraseña contiene caracteres invalidos.'
      ],
    }
  },
  methods: {
    validateInputs() {
      this.bValidate = false;
      if (this.password !== "" && this.chkpassword !== "" &&
        /^[a-zA-Z0-9!#-+/.*@·~%&¬()=?¿¡{}[\]]{5,}$/.test(this.password) &&
        this.password == this.chkpassword) {
        this.bValidate = true;
      }
      return this.bValidate;
    },
    putPassword() {
      if (this.validateInputs()) {
        let oBody = {
          "nIdUser": this.getIdUser,
          "sNewPass": md5(this.password),
          "sChkNewPass": md5(this.chkpassword),
        };
        axiosServices.putConfiguration('/changepass', oBody, true)
        .then(aResponseUpdate => {
          this.$store.dispatch('notificaciones/SetMensaje', `${aResponseUpdate.sMessage}`);
          this.$store.dispatch('notificaciones/SetColor','success')
          this.$store.dispatch('notificaciones/ShowNotificacion', true);
          this.CloseDialog();
        });
      } else {
        this.$store.dispatch('notificaciones/SetMensaje', "Uno de los campos se encuentra vacio ó las contraseñas no coinciden.");
        this.$store.dispatch('notificaciones/SetColor','error')
        this.$store.dispatch('notificaciones/ShowNotificacion', true);
      }
    },
    CloseDialog() {
      this.password = '';
      this.chkpassword = '';
      this.dialog = false;
      this.$emit("input", this.dialog);
    },
  },
  computed: {
    ...mapGetters(['getIdUser'])
  },
}
</script>