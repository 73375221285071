<template>
  <v-container>
    <v-row class="elevation-3 rounded" justify="center" align="center">
      <v-col lg="6" md="5" sm="6" cols="12">
        <v-btn text icon color="primary" @click="remove">
          <v-icon dark>fa-times-circle</v-icon>
        </v-btn>
        <span class="subtitle-1" color="primary">{{title}}</span>
      </v-col>  
      <v-col lg="6" md="7" sm="6" cols="12" class="ColumnaResumida">
        <span class="titleslight--text">Seleccione Opción</span>
        <v-select
          :items="itemsSelect"
          item-text="titulo"
          item-value="valor"
          v-model="selectOpcion"
          height="30px"
          outlined
          dense>
        </v-select>
      </v-col>
      <v-col v-if="selectOpcion!='between'" lg="12" md="12" sm="12" cols="12" class="py-0 ma-0 ColumnaResumida">
        <span class="titleslight--text">Valor Filtrar</span>
        <v-text-field
          v-model="valorConsulta"
          type="number"
          outlined
          dense
          clearable>
        </v-text-field>
      </v-col>
      <v-col v-if="selectOpcion=='between'" lg="6" md="6" sm="6" cols="12" class="py-0 ma-0 ColumnaResumida">
        <span class="titleslight--text">Valor Inicial filtrar</span>
        <v-text-field
          v-model="valorConsulta"
          type="number"
          outlined
          dense
          clearable>
        </v-text-field>
      </v-col>
      <v-col v-if="selectOpcion=='between'" lg="6" md="6" sm="6" cols="12" class="py-0 ma-0 ColumnaResumida">
        <span class="titleslight--text">Valor Final filtrar</span>
        <v-text-field
          v-model="valorConsulta2"
          type="number"
          outlined
          dense
          clearable>
        </v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import mixinGridComponenteFiltros from "@/mixins/GridComponenteFiltros.js"

  export default {
    name:"Numero",
    mixins: [mixinGridComponenteFiltros],
    data() {
      return {
        itemsSelect: [
          {titulo:'Mayor Igual',valor: '>='},
          {titulo:'Menor Igual',valor: '<='},
          {titulo:'Igual',valor: '='},
          {titulo:'Entre',valor: 'between'},

        ],
        selectOpcion  : '=',
        valorConsulta : '',
        valorConsulta2: '',
      }
    },
    methods: {
      remove() {
        this.$emit('remove',this.indice)
      },
      stringConsulta (){
        let sConsulta = ''
        switch (this.selectOpcion) {
          case '':
            sConsulta = ''
          break
          case '>=':
            sConsulta = ">= '" + this.valorConsulta + "'"
          break
          case '<=':
            sConsulta = "<= '" + this.valorConsulta + "'"
          break
          case '=':
            sConsulta = "= '" + this.valorConsulta + "'"
          break
          case 'between':
            sConsulta = " between '" + this.valorConsulta + "' AND '" + this.valorConsulta2 +"'"
          break
        }
        this.$emit("input",sConsulta)
      }
    },
    watch: {
      valorConsulta() {
        this.stringConsulta();
      },
      valorConsulta2() {
        this.stringConsulta();
      },
      selectOpcion() {
        this.stringConsulta();
      },

    },
    
    
  }
</script>

<style scoped>

  .ColumnaResumida{
    max-height: 80px;
  }
  
 
</style>