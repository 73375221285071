<template v-slot:prepend>
	<div class="imprimir">
	<v-divider></v-divider>
    <div class="d-flex justify-space-between align-center row wrap cont-eg"> 
			<div v-for="(oInfo, i) in aInfo" :key="'id'+i" class="item-eg">
				<div v-if="oInfo.index == 'Fecha'" class="justify-center align-center d-flex">
					<v-icon left light>far fa-calendar-alt</v-icon>
					<span>{{oInfo.value}}</span>
				</div>
				<div v-else-if="oInfo.index == 'Label'">
					<span class="text-medium"></span><span> {{oInfo.value}}</span>
				</div>
				<div v-else-if="oInfo.index == 'Logo'">
					<div id="sImgReference" >
						<img class="aFotos" style="height: 90px;" :src="oInfo.value" contain />
					</div>
				</div>
				<div v-else-if="oInfo.index == 'Version'">
					<span class="text-medium"></span><span> {{oInfo.value}}</span>
				</div>
				<div v-else>
					<span class="text-medium">{{oInfo.index}}:</span><span> {{oInfo.value}}</span>
				</div>
				<v-divider vertical class="mx-4"></v-divider>
			</div>
		</div>
    <v-divider></v-divider>
	</div>
</template>
<script>
export default {
	name:"CabeceraVista",
	props:{
		aInfo: {
			type: Array
		},
		nIdParent: {
			type: String,
			default: ''
		},
		sNameDetails: {
			type: String,
			default: ''
		},
		bSubdominioTemporal:{ //Indica que se activa puente con subdominio temporal
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			
		}
	},
}
</script>
<style lang="scss">
.divisorVertical{
	width: 100%;
	border-right: solid gray 1px;
}
.cont-eg{
	min-height: 30px;
	height: auto;
	padding: 10px;
}
.item-eg{
	display: flex;
	// border: solid blue;
}
.divider-eg{
	border-right: solid green 1px;
	height: 100%;
	display: inline-block;
	vertical-align: middle;
}
</style>