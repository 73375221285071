<template>
<div >
  <span class="titleslight--text font-weight-medium">{{titulo}}</span>
  <span v-if="obligatorio" class="titleslight--text font-weight-bold ml-1">*</span>
  <v-radio-group  class="ma-0 pt-1"
    v-model="valor" 
    row 
    dense
    :disabled="bEditable"
    :error="esError"
    :rules="noVacio">
      <v-radio label="Activo" value="1"></v-radio>
      <v-radio label="Inactivo" value="0"></v-radio>
  </v-radio-group>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

  export default {
    name: 'FormActivoInactivo',
    props: {
      value: {
      },
      titulo:{
        type:String,
        default:''
      },
      obligatorio:{
        type:Boolean,
        default: false
      },
      campo:{
        type:String,
        default : ''
      },
      editable: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        valor   : this.value,
        esError : false,
        noVacio : [v => !!v],
        bEditable : (this.editable == "0") ? true : false,
      }
    },
    mounted(){
    },
    methods: {

    },
    computed: {
      ...mapGetters(['getoFormError'])
    },
    watch: {
      valor(NewValor){
        this.$emit('input',NewValor)
        if(this.esError && this.valor != ''){
          this.esError = false
        }  
      },
      value(NewValue){
        this.valor = NewValue
      },
      getoFormError(){
        this.esError = this.getoFormError[this.campo]
      }
    },
    
  }
</script>

<style scoped>


</style>