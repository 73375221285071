<template>
  <div>
    <span class="titleslight--text">{{titulo}}</span>
    <span v-if="obligatorio" class="titleslight--text font-weight-bold ml-1">*</span>
     <v-tooltip v-if="sTooltip!=''" top>
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="valor"
          single-line
          outlined
          clearable
          dense
          :disabled="bEditable"
          :error="esError"
          :placeholder="sPlaceHolder"
          :rules="noVacio"
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <span>{{sTooltip}}</span>
    </v-tooltip>
    <v-text-field
      class="mt-1"
      v-else
      v-model="valor"
      single-line
      outlined
      clearable
      dense
      :disabled="bEditable"
      :error="esError"
      :placeholder="sPlaceHolder"
      :rules="noVacio">
    </v-text-field>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FormTexto',
  props: {
    value: {
    },
    titulo:{
      type:String,
      default:''
    },
    obligatorio:{
      type:Boolean,
      default: false
    },
    campo:{
      type:String,
      default : ''
    },
    editable: {
      type: String,
      default: ''
    },
    sPlaceHolder: {
      type: String,
      default: ''
    },
    sTooltip: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      valor   : this.value,
      esError : false,
      noVacio : [v => !!v],
      bEditable : (this.editable == "0") ? true : false,
    }
  },
  mounted(){
  },
  methods: {

  },
  computed: {
    ...mapGetters(['getoFormError'])
  },
  watch: {
    valor(NewValor){
      this.$emit('input',NewValor)
      if(this.esError && this.valor != ''){
        this.esError = false
      }

    },
    value(NewValue){
      this.valor = NewValue
    },
    getoFormError(){
      this.esError = this.getoFormError[this.campo]
    }

  },
  
}
</script>

<style scoped>


</style>