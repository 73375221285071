<template>
  <v-container grid-list-xs class="pa-0 ma-0 pt-2" fluid>
    <v-row class="pa-0 ma-0 titlesdark--text" >
      <v-col md="8" sm="12" class="pa-0 ma-0">
        <span class="pl-1 text-light font-size-2">Minuta</span>
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0 mt-2 container-rounded-shadown">
      <v-col md="12" sm="12" class="pa-0 ma-0 rounded-basic">
        <grid
          v-if="this.$store.state.token!=''"
          :tabla="'Minutas'"
          :Detalles="'ReporteMinuta'"
          :bActiveFilter="true"
          :bActiveExport="true"
          :bSingleSelect="true"
          :bShowSelect="true"
          :bActiveNew="false"
          :sIdTable="'idMinuta'"
          :oTablesDependens="oTables"
          :bShowMapDetails="false"
          @saveOk="createDetaill"
          @idSelect="getIdSelect"
          @nameField="getNameFieldSelect"
          class="rounded-basic"/>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import grid from '@/components/Grid/Grid.vue'
import mxFunciones from '@/mixins/funciones.js'
// import axiosServices from '@/services/axiosServices.js'

export default {
  name:"Minuta",
  components: {
    grid
  },
  mixins: [mxFunciones],
  data() {
    return {
      idSelect  : '',                 // Id seleccionado
      nameField : '',                 // Nombre del campo foreignKey
      oTables: {
        "Primary": "Minutas",
        "Second": '',
        "Third": '',
        "Aux": ''
      }
    }
  },// fin data
  mounted() {
    this.onValidarTokenSinLogin()
  },
  methods: {
    getIdSelect(sIdSelected) {
      this.idSelect = sIdSelected;
    },
    getNameFieldSelect(sNameFiled) {
      this.nameField = sNameFiled;
    },
    createDetaill(){

    }
  },
}
</script>
<style lang="scss" scoped>

  .rounded-basic{
    border-radius: 15px!important;
  }
  .bdred{
    border: solid red!important;
  }
  .container-rounded-shadown{
    border-radius: 13px;
    box-shadow: 0px 0px 5px 2px #15223214;
  }
</style>